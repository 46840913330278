import React from 'react';
import { connect } from 'react-redux';
import Col from 'react-bootstrap/Col';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import ListGroup from 'react-bootstrap/ListGroup';
import BaseMap from '../components/Map.js';
import { handleCategorySelect } from '../actions';
import legend from '../assets/legend.png';
import { Translate, withLocalize } from 'react-localize-redux';

const mapStateToProps = (state) => {
  return {
    categories: state.categories,
    activeCategory: state.activeCategory,
  };
};

class Categories extends React.Component {
  constructor(props: Props) {
    super(props);
    this.handleCategorySelectDispatch = this.handleCategorySelectDispatch.bind(
      this
    );
  }

  handleCategorySelectDispatch(event) {
    let key = event.target.id;
    this.props.dispatch(handleCategorySelect(key));
  }

  render() {
    var selLang = this.props.activeLanguage
      ? this.props.activeLanguage.code
      : 'en';
    return (
      <>
        <Col lg="10" xs="9" style={{ padding: 0, height: 'inherit' }}>
          <Navbar
            variant="mapnav"
            style={{ zIndex: 1000, background: 'none' }}
            expand="sm"
          >
            <Nav variant="mapnav">
              <Nav.Link style={{ color: '#00467D' }} href="#categories">
                <h2>
                  <Translate id="categories">Categories</Translate>
                </h2>
              </Nav.Link>
              <Nav.Link
                className="navlink"
                href="#intro"
                style={{ color: '#2680C2' }}
              >
                <sub>
                  ↑&nbsp;<Translate id="intro">Introduction</Translate>
                </sub>
              </Nav.Link>
              <Nav.Link
                className="navlink"
                href="#persons"
                style={{ color: '#2680C2' }}
              >
                <sub>
                  ↓&nbsp;<Translate id="persons">Persons</Translate>
                </sub>
              </Nav.Link>
              <Nav.Link
                className="navlink"
                href="#stories"
                style={{ color: '#2680C2' }}
              >
                <sub>
                  ↓&nbsp;<Translate id="stories">Stories</Translate>
                </sub>
              </Nav.Link>
            </Nav>
          </Navbar>
          <BaseMap
            category={this.props.activeCategory}
            title={this.props.categories[this.props.activeCategory].title}
          />

          <div
            style={{
              width: '20%',
              position: 'absolute',
              zIndex: '1001',
              left: '20px',
              bottom: '60px',
            }}
          >
            <p style={{ color: '#2680C2' }}>
              {' '}
              <Translate id="pers-legend">
                Proportion of street names
              </Translate>{' '}
            </p>
            <img
              src={legend}
              alt="legend"
              style={{ width: '100%', position: 'absolute' }}
            />
            <br />
            <br />
            <p
              className="mapNote"
              style={{ color: '#2680C2', fontSize: '12px' }}
            >
              <Translate id="pers-warning">
                Categories do not add up to 100%, and street names can
                simultaneously be part of multiple categories
              </Translate>
            </p>
          </div>
        </Col>
        <Col
          lg="2"
          xs="3"
          style={{
            padding: 0,
            height: 'inherit',
            color: '#627D98',
          }}
        >
          <ListGroup variant="flush" style={{ marginTop: '20%' }}>
            {Object.keys(this.props.categories).map((key) => {
              var desc =
                selLang === 'en'
                  ? this.props.categories[key].desc
                  : this.props.categories[key].descHrv;
              var countLabel = selLang === 'en' ? ' Count: ' : ' Broj ulica: ';
              return (
                <ListGroup.Item
                  className="categoryItem"
                  id={key}
                  key={key}
                  title={desc + countLabel + this.props.categories[key].count}
                  active={key === this.props.activeCategory ? 1 : 0}
                  onClick={(e) => this.handleCategorySelectDispatch(e)}
                  variant="clean"
                >
                  {selLang === 'en'
                    ? this.props.categories[key].title
                    : this.props.categories[key].titleHrv}{' '}
                </ListGroup.Item>
              );
            })}
          </ListGroup>
        </Col>
      </>
    );
  }
}

Categories = connect(mapStateToProps)(Categories);
export default withLocalize(Categories);
