import React from 'react';
import ReactDOMServer from 'react-dom/server';
import {
  MapContainer,
  GeoJSON,
  ScaleControl,
  FeatureGroup,
} from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import store from '../enums/mapConfig.js';
import geojson from '../data/croatia-outline.json';
import geojsonUnder from '../data/croatia-outline.json';
import streets from '../data/streets-22.json';

class BaseMap extends React.Component {
  constructor(props: Props) {
    super(props);
    this.style = this.style.bind(this);
    this.getPerim = this.getPerim.bind(this);
  }

  style(feature) {
    return {
      fillColor: 'white',
      weight: 0.6,
      opacity: 1,
      color: '#9FB3C8',
      fillOpacity: 1,
    };
  }

  styleUnder(feature) {
    return {
      fillColor: 'white',
      weight: 8,
      opacity: 0.6,
      color: '#D9E2EC',
      fillOpacity: 1,
    };
  }

  getPerim(d) {
    let perim =
      d > 10000 ? 30 : d > 4000 ? 20 : d > 1000 ? 10 : d > 100 ? 5 : 3;
    let divider = window.innerWidth > 600 ? 1 : 3;
    return perim / divider;
  }

  highlightFeature(e) {
    var layer = e.target;
    layer.setStyle({
      weight: 3,
      color: 'yellow',
    });
    if (!L.Browser.ie && !L.Browser.opera && !L.Browser.edge) {
      layer.bringToFront();
    }
  }

  resetHighlight(e) {
    var layer = e.target;
    layer.setStyle({
      weight: 0.5,
      color: '#4098D7',
    });
    if (!L.Browser.ie && !L.Browser.opera && !L.Browser.edge) {
      layer.bringToFront();
    }
  }

  pointToLayer(feature, latlng) {
    const Popup = ({ feature }) => {
      return (
        <div style={{ fontFamily: 'Sora' }}>
          <p>
            <b>{feature.properties.UL_IME_OFFICIAL}</b> (
            {feature.properties.NA_IME})
          </p>
        </div>
      );
    };
    const popupOptions = {
      minWidth: 150,
      maxWidth: 200,
      className: 'popup-classname',
    };
    const popupContent = ReactDOMServer.renderToString(
      <Popup feature={feature} />
    );

    var style = {
      radius: this.getPerim(feature.properties.Shape_Leng),
      weight: 0.5,
      color: '#4098D7',
      fillColor: '#4098D7',
      fillOpacity: 0.5,
    };
    if (feature.properties.PERSONID === this.props.person) {
      var marker = L.circleMarker(latlng, style);
      marker.bindPopup(popupContent, popupOptions);
      marker.on({
        mouseover: this.highlightFeature,
        mouseout: this.resetHighlight,
      });
      marker.on('mouseover', function (e) {
        this.openPopup();
      });
      marker.on('mouseout', function (e) {
        this.closePopup();
      });
      return marker;
    }
  }

  render() {
    return (
      <div className="map-wrapper">
        <MapContainer
          style={{
            width: '100%',
            height: '100%',
            position: 'absolute',
            background: 'none',
          }}
          bounds={store.bounds}
          boundsOptions={{ padding: [60, 60] }}
          maxBounds={store.maxBounds}
          onViewportChanged={store.mapMoved}
          useFlyTo={true}
          attributionControl={false}
          zoomSnap={0}
          className="map2"
          id="map"
          doubleClickZoom={false}
          keyboard={false}
          touchZoom={false}
          scrollWheelZoom={false}
          dragging={false}
          zoomControl={false}
          preferCanvas={true}
        >
          <ScaleControl imperial={false} />
          <FeatureGroup>
            <GeoJSON
              data={geojsonUnder}
              style={this.styleUnder}
              smoothFactor={0}
            />
            <GeoJSON data={geojson} style={this.style} smoothFactor={0} />
            <GeoJSON
              key={this.props.person}
              data={streets}
              smoothFactor={0}
              pointToLayer={this.pointToLayer.bind(this)}
            />
          </FeatureGroup>
        </MapContainer>
      </div>
    );
  }
}

export default BaseMap;
