import React from 'react';
import { withLocalize } from 'react-localize-redux';
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';

const LanguageToggle = ({ languages, activeLanguage, setActiveLanguage }) => (
  <ToggleButtonGroup
    style={{ position: 'absolute', right: '50px', top: '18px' }}
    size="sm"
    type="radio"
    variant="outline-primary"
    name="options"
    defaultValue={'en'}
  >
    <ToggleButton
      value={'en'}
      variant="outline-primary"
      onChange={() => setActiveLanguage('en')}
    >
      en
    </ToggleButton>
    <ToggleButton
      value={'hr'}
      variant="outline-primary"
      checked={false}
      onChange={() => setActiveLanguage('hr')}
    >
      hr
    </ToggleButton>
  </ToggleButtonGroup>
);

export default withLocalize(LanguageToggle);
