import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Nav';
import Image from 'react-bootstrap/Image';
import Carousel from 'react-bootstrap/Carousel';
import { Translate, withLocalize } from 'react-localize-redux';
import st3m1 from '../assets/story3/st3m1.png';
import st3m2 from '../assets/story3/st3m2.png';
import st3m3 from '../assets/story3/st3m3.png';
import st3m4 from '../assets/story3/st3m4.png';

import legendSt1 from '../assets/legend-st1.png';
import legendSt1cro from '../assets/legend-st1-cro.png';

class CarouselStory3 extends React.Component {
  hw21(lang) {
    if (lang === 'en') {
      return (
        <>
          Majority of those streets (806 to be exact) carry the names of 518
          different individuals associated with the war. In most cases, these
          street names commemorate local fighters who died in combat. Only three
          men in this group have more than ten streets named after them:
          Croatia’s wartime President{' '}
          <span style={{ background: '#BEF8FD' }}>FRANJO TUĐMAN</span> (209
          streets), his minister of defense{' '}
          <span style={{ background: '#FFF3C4' }}>GOJKO ŠUŠAK</span> (17
          streets), and the police officer considered to be the first Croatian
          fatality in the Homeland War,{' '}
          <span style={{ background: '#DAC4FF' }}>JOSIP JOVIĆ</span> (14
          streets).
        </>
      );
    } else {
      return (
        <>
          Većina tih ulica (točnije njih 806) nosi imena 518 različitih osoba
          povezanih s ratom. U većini slučajeva, ovi nazivi ulica obilježavaju
          sjećanje na lokalne branitelje poginule u borbi. Samo tri osobe u ovoj
          skupini imaju više od deset ulica nazvanih po njima: ratni predsjednik{' '}
          <span style={{ background: '#BEF8FD' }}>FRANJO TUĐMAN</span> (209
          ulica), njegov ministar obrane{' '}
          <span style={{ background: '#FFF3C4' }}>GOJKO ŠUŠAK</span> (17 ulica)
          i policajac koji se smatra prvom hrvatskom žrtvom u Domovinskom ratu,{' '}
          <span style={{ background: '#DAC4FF' }}>JOSIP JOVIĆ</span> (14 ulica).
        </>
      );
    }
  }

  hw31(lang) {
    if (lang === 'en') {
      return (
        <>
          Many street names in this group also commemorate more general
          collectives, institutions, concepts, or events related to the war. The
          largest group in this category are the{' '}
          <span style={{ background: '#BEF8FD' }}>DEFENDERS</span> (308
          streets). Various{' '}
          <span style={{ background: '#FFF3C4' }}>MILITARY UNITS</span> can be
          found in the names of 231 streets. The{' '}
          <span style={{ background: '#FACDCD' }}>HOMELAND WAR</span> itself is
          commemorated in the names of 48 streets, and the{' '}
          <span style={{ background: '#DAC4FF' }}>VICTIMS</span> as a collective
          can be found in the names of 38 streets. Interestingly, the various
          aspects of Croatia’s{' '}
          <span style={{ background: '#C6F7E2' }}>VICTORY</span> (in the form of
          dates, name of military operation, etc.) can be found in the names of
          only 28 streets.
        </>
      );
    } else {
      return (
        <>
          Mnogi nazivi ulica u ovoj skupini također obilježavaju kolektive,
          institucije, koncepte ili događaje vezane uz rat. Najveća skupina u
          ovoj kategoriji su{' '}
          <span style={{ background: '#BEF8FD' }}>BRANITELJI</span> (308 ulica).
          U nazivima 231 ulice nalaze se razne{' '}
          <span style={{ background: '#FFF3C4' }}>VOJNE JEDINICE</span>. Sam{' '}
          <span style={{ background: '#FACDCD' }}>DOMOVINSKI RAT</span>{' '}
          obilježen je u nazivima 48 ulica, a{' '}
          <span style={{ background: '#DAC4FF' }}>ŽRTVE</span> kao kolektiv
          nalaze se u nazivima 38 ulica. Zanimljivo je da se različiti vidovi
          hrvatske <span style={{ background: '#C6F7E2' }}>POBJEDE</span> (u
          obliku datuma, naziva vojne operacije i sl.) mogu pronaći u nazivima
          samo 28 ulica.
        </>
      );
    }
  }

  hw41(lang) {
    if (lang === 'en') {
      return (
        <>
          It is important to note that the commemoration of the Homeland War
          through the names of streets, squares and public spaces can also take
          forms that are perhaps not immediately apparent. For example, since
          the war, there has been a dramatic increase in the number of streets
          named after the cities of{' '}
          <span style={{ background: '#BEF8FD' }}>VUKOVAR</span> and{' '}
          <span style={{ background: '#FFF3C4' }}>DUBROVNIK</span>, which were
          the sites of the largest and most known battles during the war. Today,
          138 streets are named after Vukovar (there were only 11 streets named
          after Vukovar before the war) and 54 after Dubrovnik (20 before the
          war). In fact, Vukovar is the second most popular toponym in Croatia’s
          street names, right after the capital of Zagreb, which is mentioned in
          the names of 262 streets and squares.
        </>
      );
    } else {
      return (
        <>
          Važno je napomenuti da obilježavanje Domovinskog rata nazivima ulica,
          trgova i javnih površina može imati i oblike koji možda nisu odmah
          vidljivi. Primjerice, od rata se dramatično povećao broj ulica koje
          nose imena gradova{' '}
          <span style={{ background: '#BEF8FD' }}>VUKOVAR</span> i{' '}
          <span style={{ background: '#FFF3C4' }}>DUBROVNIK</span>, koji su bili
          poprišta najvećih i najpoznatijih bitki tijekom rata. Danas po
          Vukovaru nosi ime 138 ulica (prije rata ih je bilo samo 11), a po
          Dubrovniku 54 (prije rata 20). Vukovar je zapravo drugi najpopularniji
          toponim u nazivima ulica u Hrvatskoj, odmah nakon glavnoga grada
          Zagreba koji se spominje u nazivima 262 ulice i trga.
        </>
      );
    }
  }

  legend(lang) {
    if (lang === 'en') {
      return <Image src={legendSt1} width="230px" />;
    } else {
      return <Image src={legendSt1cro} width="230px" />;
    }
  }

  render() {
    var lang = this.props.activeLanguage
      ? this.props.activeLanguage.code
      : 'en';
    return (
      <div className="lexicsCarousel">
        <Carousel interval={null}>
          <Carousel.Item>
            <Row>
              <Col lg="4">
                <h2
                  style={{
                    fontWeight: 200,
                    fontVariant: 'all-petite-caps',
                  }}
                >
                  <Translate id="hmwar">Homeland War</Translate>{' '}
                  <span
                    className="storyNumber"
                    style={{ fontSize: '22px', color: '#62B0E8' }}
                  >
                    (1/4)
                  </span>
                </h2>
                <p>
                  <Translate id="hw1">
                    Considering the continuing political relevance of the events
                    surrounding Croatia’s Homeland War (1991-1995), the number
                    of street names honoring or commemorating dates,
                    institutions, and persons directly related to the war and
                    Croatia’s struggle for independence is actually limited. In
                    total, 1534 streets and squares (or 2.9%) carry such names.
                    Their geographic distribution generally follows the pattern
                    of the conflict, with the areas more exposed to violence
                    also being more likely to commemorate it with street names.
                  </Translate>
                </p>
              </Col>
              <Col lg="8">
                <Image
                  src={st3m1}
                  alt="Map of Homeland war related street names in settlements"
                  style={{ maxHeight: window.innerHeight - 83 }}
                  fluid
                />
                <div
                  className="storyLegend"
                  style={{
                    position: 'absolute',
                    bottom: '9rem',
                    fontSize: '12px',
                  }}
                >
                  <Translate id="hw1Leg">
                    Number of street names <br />
                    related to Homeland war <br />
                    in settlements
                  </Translate>
                </div>
              </Col>
            </Row>
          </Carousel.Item>
          <Carousel.Item>
            <Row>
              <Col lg="4">
                <h2
                  style={{
                    fontWeight: 200,
                    fontVariant: 'all-petite-caps',
                  }}
                >
                  <Translate id="hmwar">Homeland War</Translate>{' '}
                  <span
                    className="storyNumber"
                    style={{ fontSize: '22px', color: '#62B0E8' }}
                  >
                    (2/4)
                  </span>
                </h2>
                <p>{this.hw21(lang)}</p>
              </Col>
              <Col lg="8">
                <Image
                  src={st3m2}
                  alt="Homeland war personalities map"
                  height="90%"
                  style={{ maxHeight: window.innerHeight - 83 }}
                  fluid
                />
                <div
                  className="storyLegend"
                  style={{ position: 'absolute', bottom: '9rem' }}
                >
                  <Translate id="cat-legend">Street length</Translate>
                </div>
              </Col>
            </Row>
          </Carousel.Item>
          <Carousel.Item>
            <Row>
              <Col lg="4">
                <h2
                  style={{
                    fontWeight: 200,
                    fontVariant: 'all-petite-caps',
                  }}
                >
                  <Translate id="hmwar">Homeland War</Translate>{' '}
                  <span
                    className="storyNumber"
                    style={{ fontSize: '22px', color: '#62B0E8' }}
                  >
                    (3/4)
                  </span>
                </h2>
                <p>{this.hw31(lang)}</p>
              </Col>
              <Col lg="8">
                <Image
                  src={st3m3}
                  alt="Homeland war related concepts map"
                  style={{ maxHeight: window.innerHeight - 83 }}
                  fluid
                />
                <div
                  className="storyLegend"
                  style={{ position: 'absolute', bottom: '9rem' }}
                >
                  <Translate id="cat-legend">Street length</Translate>
                </div>
              </Col>
            </Row>
          </Carousel.Item>
          <Carousel.Item>
            <Row>
              <Col lg="4">
                <h2
                  style={{
                    fontWeight: 200,
                    fontVariant: 'all-petite-caps',
                  }}
                >
                  <Translate id="hmwar">Homeland War</Translate>{' '}
                  <span
                    className="storyNumber"
                    style={{ fontSize: '22px', color: '#62B0E8' }}
                  >
                    (4/4)
                  </span>
                </h2>
                <p>
                  <p>{this.hw41(lang)}</p>
                </p>
              </Col>
              <Col lg="8">
                <Image
                  src={st3m4}
                  alt="Dubrovnik and Vukovar in steet names map"
                  style={{ maxHeight: window.innerHeight - 83 }}
                  fluid
                />
                <div
                  className="storyLegend"
                  style={{ position: 'absolute', bottom: '9rem' }}
                >
                  <Translate id="cat-legend">Street length</Translate>
                </div>
              </Col>
            </Row>
          </Carousel.Item>
        </Carousel>
      </div>
    );
  }
}

export default withLocalize(CarouselStory3);
