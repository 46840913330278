import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import Jumbotron from 'react-bootstrap/Jumbotron';
import './App.css';

//subsections
import Categories from './components/Categories.js';
import Persons from './components/Persons.js';
import Stories from './components/Stories.js';
import LanguageToggle from './components/LanguageToggle.js';

//logos
import elwarlogo from './assets/elwar-logo.png';
import unilogo from './assets/uni-logo.png';
import erclogo from './assets/erc-logo.png';

//localization
import { renderToStaticMarkup } from 'react-dom/server';
import { Translate, withLocalize } from 'react-localize-redux';
import globalTranslations from './translations/global.json';

class App extends React.Component {
  constructor(props) {
    super(props);

    this.props.initialize({
      languages: [
        { name: 'English', code: 'en' },
        { name: 'Croatian', code: 'hr' },
      ],
      translation: globalTranslations,
      options: {
        renderToStaticMarkup,
        renderInnerHtml: true,
        defaultLanguage: 'en',
      },
    });
  }

  render() {
    var globalPadding = window.innerWidth > 800 ? '10%' : '3%';
    return (
      <Container fluid>
        <Row
          id="intro"
          style={{
            paddingLeft: globalPadding,
            color: 'white',
            background: '#00467D',
            minHeight: '100vh',
          }}
        >
          <Col lg="8">
            <LanguageToggle />
            <Jumbotron id="mainJumbo">
              <h1>
                <Translate id="title">
                  Lexicon of <br />
                  Croatian Streets
                </Translate>
              </h1>
              <br />
              <p style={{ fontSize: '18px' }}>
                <Translate id="subtitle">
                  Constructing the present by commemorating the past
                </Translate>
              </p>
            </Jumbotron>
            <Jumbotron
              style={{
                background: 'none',
                color: '#6EC3F1',
                marginTop: '-50px',
              }}
            >
              <Row
                style={{
                  hyphens: 'auto',
                  textAlign: 'justify',
                  textJustify: 'inter-word',
                  fontWeight: 300,
                  fontSize: 16,
                }}
              >
                <Col style={{ minWidth: '320px' }}>
                  <p>
                    <span style={{ fontSize: 18, fontWeight: 600 }}>
                      LexiCS
                    </span>{' '}
                    <Translate id="impressum-1">
                      is an online platform for the visual presentation of
                      street names in the local communities in Croatia. The
                      platform aims to trace the process of public memory and
                      commemoration since the early 1990s in a country that went
                      through several painful social processes: economic and
                      political transition from one-party socialism to liberal
                      democracy, war, and dramatic demographic change. The
                      platform grew out of the project ELWar (Electoral Legacies
                      of War) which was funded by the ERC and was hosted by the
                      Institute of Political Science at the University of
                      Luxembourg.
                    </Translate>
                  </p>
                  <p>
                    <Translate id="impressum-2">
                      The platform is a work in progress which will continue to
                      be populated with new data and new functional
                      capabilities. All data shown reflects the situation at the
                      time of the 2021 census, unless otherwise noted. Users are
                      encouraged to send suggestions for improvements and
                      corrections to
                    </Translate>{' '}
                    <a href="mailto:josip.glaurdic@uni.lu">
                      josip.glaurdic@uni.lu
                    </a>
                    .
                  </p>
                </Col>
                <Col style={{ minWidth: '320px' }}>
                  <p>
                    <Translate id="impressum-3">
                      The data presented by LexiCS is organised into three
                      sections:{' '}
                      <span style={{ fontWeight: 600 }}>Categories</span> (
                      <a href="#categories">more↓</a>
                      ), <span style={{ fontWeight: 600 }}>Persons</span> (
                      <a href="#persons">more↓</a>), and{' '}
                      <span style={{ fontWeight: 600 }}>Stories</span> (
                      <a href="#stories">more↓</a>). The section Categories
                      presents the data in aggregate form on the level of
                      Croatia's 556 municipalities where street names have been
                      allocated into ten different categories. In the section
                      Persons, locations and lengths of streets are provided for
                      all persons who have at least ten streets named after
                      them. Persons can be listed alphabetically or according to
                      their ranking. Finally, the section Stories presents a
                      more in-depth look into the naming practices related to
                      several thematic areas with a special focus on their
                      geographical distribution and evolution since the advent
                      of democracy in 1990.
                    </Translate>
                  </p>
                  <p>
                    <Translate id="impressum-authors">
                      Authors: Josip Glaurdić, Ivana Budimir, Peter Ondrejka,
                      Tomislav Kaniški
                    </Translate>
                  </p>
                  <br />
                  <div style={{ float: 'right' }}>
                    <a href="https://elwar.uni.lu/">
                      <Image
                        src={elwarlogo}
                        alt="ELWar project logo"
                        height="55rem"
                        style={{ marginRight: '15px' }}
                      />
                    </a>

                    <a href="https://wwwen.uni.lu/">
                      <Image
                        src={unilogo}
                        alt="University logo"
                        height="55rem"
                      />
                    </a>
                    <a href="https://erc.europa.eu/">
                      <Image
                        src={erclogo}
                        alt="European Research Council logo"
                        height="55rem"
                      />
                    </a>
                  </div>
                </Col>
              </Row>
            </Jumbotron>
          </Col>
        </Row>
        <Row
          id="categories"
          style={{
            paddingLeft: globalPadding,
            paddingRight: globalPadding,
            background: '#F0F4F8',
          }}
        >
          <Categories />
        </Row>
        <Row
          id="persons"
          style={{
            height: window.innerHeight,
            paddingLeft: globalPadding,
            paddingRight: globalPadding,
            background: '#F0F4F8',
            borderTop: '1px solid #BCCCDC',
          }}
        >
          <Persons />
        </Row>
        <Row
          id="stories"
          style={{
            paddingLeft: globalPadding,
            paddingRight: globalPadding,
            background: '#F0F4F8',
            borderTop: '1px solid #BCCCDC',
          }}
        >
          <Stories />
        </Row>
        <Row
          id="footnote"
          style={{
            paddingLeft: globalPadding,
            color: '#6EC3F1',
            background: '#00467D',
            height: '50px',
          }}
        >
          <Col lg="12">
            {' '}
            <span style={{ fontSize: 12 }}>
              Site by <a href="https://pondrejk.eu/"> pondrejk.eu</a>
            </span>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default withLocalize(App);
