import React from 'react';
import { connect } from 'react-redux';
import Accordion from 'react-bootstrap/Accordion';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';
import legend from '../assets/persons-legend.png';
import BaseMap from '../components/Map2.js';
import {
  handlePersonSelect,
  handleGroupSelect,
  handleOrdering,
} from '../actions';
import { FaExternalLinkAlt } from 'react-icons/fa';
import { ImSortAmountDesc, ImSortAlphaAsc } from 'react-icons/im';
import { sortPersonsMemoized } from '../selectors';
import { Translate, withLocalize } from 'react-localize-redux';

const mapStateToProps = (state, ownProps) => {
  return {
    persons: sortPersonsMemoized(state, ownProps),
    activePerson: state.activePerson,
    groups: state.groups[state.ordering],
    selectedGroup: state.selectedGroup,
    ordering: state.ordering,
  };
};

class Persons extends React.Component {
  constructor(props: Props) {
    super(props);
    this.handlePersonSelectDispatch = this.handlePersonSelectDispatch.bind(
      this
    );
    this.handleGroupSelectDispatch = this.handleGroupSelectDispatch.bind(this);
    this.handleOrderingDispatch = this.handleOrderingDispatch.bind(this);
  }

  handlePersonSelectDispatch(event) {
    let key = event.target.id;
    this.props.dispatch(handlePersonSelect(key));
  }

  handleGroupSelectDispatch(event, key) {
    var groupList = [...this.props.selectedGroup];
    groupList[this.props.ordering] = key;
    this.props.dispatch(handleGroupSelect(groupList));
  }

  handleOrderingDispatch(val) {
    this.props.dispatch(handleOrdering(val));
  }

  render() {
    var selLang = this.props.activeLanguage
      ? this.props.activeLanguage.code
      : 'en';
    return (
      <>
        <Col xs="8" lg="9" style={{ padding: 0, height: 'inherit' }}>
          <ToggleButtonGroup
            size="sm"
            type="radio"
            name="options"
            value={this.props.ordering}
            onChange={this.handleOrderingDispatch}
            style={{
              position: 'absolute',
              top: '8px',
              right: '8px',
              zIndex: 1001,
            }}
          >
            <ToggleButton
              variant="clean"
              value={0}
              title={
                selLang === 'en'
                  ? 'Order persons alphabetically'
                  : 'Poredaj osobe abecednim redom'
              }
            >
              <ImSortAlphaAsc />
            </ToggleButton>
            <ToggleButton
              variant="clean"
              value={1}
              title={
                selLang === 'en'
                  ? 'Order persons by rank'
                  : 'Poredaj osobe po broju ulica'
              }
            >
              <ImSortAmountDesc />
            </ToggleButton>
          </ToggleButtonGroup>
          <Navbar
            variant="mapnav"
            style={{ zIndex: 1000, background: 'none' }}
            expand="sm"
          >
            <Nav variant="mapnav">
              <Nav.Link style={{ color: '#00467D' }} href="#persons">
                <h2>
                  <Translate id="persons">Persons</Translate>
                </h2>
              </Nav.Link>
              <Nav.Link
                className="navlink"
                href="#intro"
                style={{ color: '#2680C2' }}
              >
                <sub>
                  ↑&nbsp;<Translate id="intro">Introduction</Translate>
                </sub>
              </Nav.Link>
              <Nav.Link
                className="navlink"
                href="#categories"
                style={{ color: '#2680C2' }}
              >
                <sub>
                  ↑&nbsp;<Translate id="categories">Categories</Translate>
                </sub>
              </Nav.Link>
              <Nav.Link
                className="navlink"
                href="#stories"
                style={{ color: '#2680C2' }}
              >
                <sub>
                  ↓&nbsp;<Translate id="stories">Stories</Translate>
                </sub>
              </Nav.Link>
            </Nav>
          </Navbar>

          <BaseMap person={this.props.activePerson} />
          <div
            style={{
              width: '20%',
              position: 'absolute',
              zIndex: '1001',
              left: '20px',
              bottom: '70px',
            }}
          >
            <p style={{ color: '#2680C2' }}>
              <Translate id="cat-legend">Street length</Translate>
            </p>
            <Row>
              <img
                className="legendStripe"
                src={legend}
                alt="legend"
                style={{ width: '280%' }}
              />
            </Row>
            <p
              className="mapNote"
              style={{ color: '#2680C2', fontSize: '12px' }}
            >
              <Translate id="cat-warning">
                Only persons who have at least ten streets named after them are
                shown.  
              </Translate>
            </p>
          </div>
        </Col>
        <Col
          lg="2"
          xs="3"
          style={{
            height: 'inherit',
            color: '#627D98',
            padding: 0,
            overflowY: 'scroll',
          }}
        >
          <Accordion
            defaultActiveKey={this.props.activePerson}
            className="card-clean"
          >
            {Object.keys(this.props.persons).map((key) => {
              if (this.props.ordering === 0) {
                //select by alphabet group
                var alphLangGroup =
                  selLang === 'en'
                    ? this.props.persons[key].group
                    : this.props.persons[key].groupHrv;
                if (
                  this.props.selectedGroup[this.props.ordering].includes(
                    alphLangGroup
                  )
                ) {
                  return (
                    <Card className="card-clean">
                      <Accordion.Toggle
                        as={Card.Header}
                        eventKey={key}
                        key={key}
                        id={key}
                        style={{ cursor: 'pointer' }}
                        onClick={(e) => this.handlePersonSelectDispatch(e)}
                        className={
                          this.props.activePerson === key ? 'selected' : ''
                        }
                      >
                        {selLang === 'en'
                          ? this.props.persons[key].name
                          : this.props.persons[key].nameHrv}
                      </Accordion.Toggle>
                      <Accordion.Collapse eventKey={key}>
                        <Card.Body className="accSelected">
                          <p className="accText">
                            {selLang === 'en'
                              ? this.props.persons[key].cv
                              : this.props.persons[key].cvHrv}{' '}
                            <br />
                            <br />
                          </p>
                          <p>
                            <Translate id="count">count: </Translate>
                            <b>{this.props.persons[key].count}</b>
                            <Translate id="rank"> (rank: </Translate>
                            <b>{this.props.persons[key].rank}</b>
                            {')'}
                            <br />
                            <a
                              target="_blank"
                              rel="noreferrer"
                              href={
                                selLang === 'en'
                                  ? this.props.persons[key].link
                                  : this.props.persons[key].linkHrv
                              }
                            >
                              <Translate id="more">more</Translate>{' '}
                              <FaExternalLinkAlt />
                            </a>
                          </p>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  );
                }
              } else {
                //select by rank group
                var rankArray = this.props.selectedGroup[
                  this.props.ordering
                ].split('-');
                var rank = parseFloat(this.props.persons[key].rank);
                if (
                  parseFloat(rankArray[0]) <= rank &&
                  rank <= parseFloat(rankArray[1])
                ) {
                  return (
                    <Card className="card-clean">
                      <Accordion.Toggle
                        as={Card.Header}
                        eventKey={key}
                        key={key}
                        id={key}
                        style={{ cursor: 'pointer' }}
                        onClick={(e) => this.handlePersonSelectDispatch(e)}
                        className={
                          this.props.activePerson === key ? 'selected' : ''
                        }
                      >
                        {this.props.persons[key].rank}
                        {'. '}
                        {selLang === 'en'
                          ? this.props.persons[key].name
                          : this.props.persons[key].nameHrv}
                      </Accordion.Toggle>
                      <Accordion.Collapse eventKey={key}>
                        <Card.Body className="accSelected">
                          <p className="accText">
                            {selLang === 'en'
                              ? this.props.persons[key].cv
                              : this.props.persons[key].cvHrv}{' '}
                            <br />
                          </p>
                          <p className="accText">
                            <Translate id="count">count: </Translate>
                            <b>{this.props.persons[key].count}</b>
                            <Translate id="rank"> (rank: </Translate>
                            <b>{this.props.persons[key].rank}</b>
                            {')'}
                            <br />
                            <a
                              target="_blank"
                              rel="noreferrer"
                              href={
                                selLang === 'en'
                                  ? this.props.persons[key].link
                                  : this.props.persons[key].linkHrv
                              }
                            >
                              <Translate id="more">more</Translate>{' '}
                              <FaExternalLinkAlt />
                            </a>
                          </p>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  );
                }
              }
            })}
          </Accordion>
        </Col>
        <Col
          lg="1"
          xs="1"
          style={{
            height: 'inherit',
            color: '#2680C2',
            padding: 0,
          }}
        >
          <Nav
            variant="pills"
            className="flex-column"
            style={{ fontSize: '14px' }}
          >
            {this.props.groups.map((key) => {
              return (
                <Nav.Item>
                  <Nav.Link
                    style={{ color: '#627D98', borderRadius: 0 }}
                    className="alpha"
                    eventkey={key}
                    key={key}
                    onClick={(e) => this.handleGroupSelectDispatch(e, key)}
                    active={
                      key === this.props.selectedGroup[this.props.ordering]
                        ? 1
                        : 0
                    }
                  >
                    {selLang === 'en'
                      ? key
                      : key.replace('S', 'Š').replace('Z', 'Ž')}
                  </Nav.Link>
                </Nav.Item>
              );
            })}
          </Nav>
        </Col>
      </>
    );
  }
}

Persons = connect(mapStateToProps)(Persons);
export default withLocalize(Persons);
