import React from 'react';
import Col from 'react-bootstrap/Col';
import Navbar from 'react-bootstrap/Navbar';
import { Row, Nav, Tabs, Tab } from 'react-bootstrap';
import { Translate, withLocalize } from 'react-localize-redux';

import CarouselStory1 from '../components/CarouselStory1.js';
import CarouselStory2 from '../components/CarouselStory2.js';
import CarouselStory3 from '../components/CarouselStory3.js';
import CarouselStory4 from '../components/CarouselStory4.js';

class Stories extends React.Component {
  render() {
    return (
      <>
        <Row>
          <Col xs="8" lg="10" style={{ padding: 0, height: 'inherit' }}>
            <Navbar
              variant="mapnav"
              style={{ zIndex: 1000, background: 'none' }}
              expand="sm"
            >
              <Nav variant="mapnav">
                <Nav.Link style={{ color: '#00467D' }} href="#stories">
                  <h2>
                    <Translate id="stories">Stories</Translate>
                  </h2>
                </Nav.Link>
                <Nav.Link
                  href="#intro"
                  style={{ color: '#2680C2' }}
                  className="navStories"
                >
                  <sub>
                    ↑&nbsp;<Translate id="intro">Introduction</Translate>
                  </sub>
                </Nav.Link>
                <Nav.Link
                  href="#categories"
                  style={{ color: '#2680C2' }}
                  className="navStories"
                >
                  <sub>
                    ↑&nbsp;<Translate id="categories">Categories</Translate>
                  </sub>
                </Nav.Link>
                <Nav.Link
                  href="#persons"
                  style={{ color: '#2680C2' }}
                  className="navStories"
                >
                  <sub>
                    ↑&nbsp;<Translate id="persons">Persons</Translate>
                  </sub>
                </Nav.Link>
              </Nav>
            </Navbar>
          </Col>
        </Row>
        <Row>
          <Tabs
            defaultActiveKey="homeland_war"
            id="stories-tab"
            className="mb-3"
          >
            <Tab
              tabClassName="stories-tab"
              eventKey="homeland_war"
              title={<Translate id="hmwar">Homeland War</Translate>}
            >
              <Row
                id="homeland_war"
                style={{ paddingLeft: '1em', color: '#627D98' }}
              >
                <CarouselStory3 />
              </Row>
            </Tab>
            <Tab
              tabClassName="stories-tab"
              eventKey="wwar"
              title={<Translate id="wwii">World War II</Translate>}
            >
              <Row id="wwar" style={{ paddingLeft: '1em', color: '#627D98' }}>
                <CarouselStory4 />
              </Row>
            </Tab>
            <Tab
              tabClassName="stories-tab"
              eventKey="religion"
              title={<Translate id="religion">Religion</Translate>}
            >
              <Row
                id="religion"
                style={{ paddingLeft: '1em', color: '#627D98' }}
              >
                <CarouselStory2 />
              </Row>
            </Tab>
            <Tab
              tabClassName="stories-tab"
              eventKey="wom"
              title={<Translate id="women">Women</Translate>}
            >
              <Row id="wom" style={{ paddingLeft: '1em', color: '#627D98' }}>
                <CarouselStory1 />
              </Row>
            </Tab>
          </Tabs>
        </Row>
      </>
    );
  }
}

export default withLocalize(Stories);
