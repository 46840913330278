import React, { createRef } from 'react';
import ReactDOMServer from 'react-dom/server';
import {
  MapContainer,
  GeoJSON,
  ScaleControl,
  FeatureGroup,
} from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import store from '../enums/mapConfig.js';
import geojson from '../data/croatia-22.json';
import geojsonUnder from '../data/croatia-outline.json';

class BaseMap extends React.Component {
  constructor(props: Props) {
    super(props);
    this.getColor = this.getColor.bind(this);
    this.style = this.style.bind(this);
    this.higlight = this.higlight.bind(this);
    this.groupRef = createRef();
  }

  getColor(val) {
    let d = val / 100;
    return d > 0.5
      ? '#160076'
      : d > 0.4
      ? '#003e6b'
      : d > 0.3
      ? '#0A558C'
      : d > 0.25
      ? '#0F609B'
      : d > 0.2
      ? '#186faf'
      : d > 0.15
      ? '#2680c2'
      : d > 0.1
      ? '#4098d7'
      : d > 0.06
      ? '#62B0e7'
      : d > 0.03
      ? '#84c5f4'
      : d > 0.02
      ? '#b6e0fe'
      : d > 0.01
      ? '#dceefb'
      : 'white';
  }

  style(feature) {
    return {
      fillColor: this.getColor(feature.properties[this.props.category]),
      weight: 0.5,
      opacity: 1,
      color: '#9FB3C8',
      fillOpacity: 1,
    };
  }

  styleUnder(feature) {
    return {
      fillColor: 'white',
      weight: 8,
      opacity: 0.6,
      color: '#D9E2EC',
      fillOpacity: 1,
    };
  }

  higlight(feature, layer) {
    const Popup = ({ feature }) => {
      return (
        <div style={{ fontFamily: 'Sora' }}>
          <p>
            <b>{feature.properties.municipality}</b>
          </p>
        </div>
      );
    };
    const popupOptions = {
      minWidth: 150,
      maxWidth: 200,
      className: 'popup-classname',
    };
    const popupContent = ReactDOMServer.renderToString(
      <Popup feature={feature} />
    );
    layer.bindPopup(popupContent, popupOptions);

    layer.on({
      mouseover: this.highlightFeature,
      mouseout: this.resetHighlight,
    });
    layer.on('mouseover', function (e) {
      this.openPopup();
    });
    layer.on('mouseout', function (e) {
      this.closePopup();
    });
  }

  highlightFeature(e) {
    var layer = e.target;
    layer.setStyle({
      weight: 3,
      color: 'yellow',
    });
    if (!L.Browser.ie && !L.Browser.opera && !L.Browser.edge) {
      layer.bringToFront();
    }
  }

  resetHighlight(e) {
    var layer = e.target;
    layer.setStyle({
      weight: 0.5,
      color: '#CBD2D9',
    });
    if (!L.Browser.ie && !L.Browser.opera && !L.Browser.edge) {
      layer.bringToFront();
    }
  }

  render() {
    /*
    L.CRS.CustomZoom = L.extend({}, L.CRS.EPSG3857, {
      scale: function (zoom) {
        return 256 * Math.pow(1.2, zoom);
      },
    });
    */

    return (
      <div className="map-wrapper">
        <MapContainer
          style={{
            width: '100%',
            height: '100%',
            position: 'absolute',
            background: 'none',
          }}
          bounds={store.bounds}
          boundsOptions={{ padding: [60, 60] }}
          maxBounds={store.maxBounds}
          onViewportChanged={store.mapMoved}
          useFlyTo={true}
          attributionControl={false}
          //maxZoom={store.maxZoom * 3.85}
          //minZoom={store.minZoom * 3.85}
          zoomSnap={0}
          className="map"
          id="map"
          doubleClickZoom={false}
          touchZoom={false}
          scrollWheelZoom={false}
          dragging={false}
          zoomControl={false}
          keyboard={false}
          preferCanvas={true}
        >
          <ScaleControl imperial={false} />
          <FeatureGroup>
            <GeoJSON
              data={geojsonUnder}
              style={this.styleUnder}
              smoothFactor={0}
            />

            <GeoJSON
              key={this.props.category}
              data={geojson}
              style={this.style}
              smoothFactor={0}
              onEachFeature={this.higlight}
            />
          </FeatureGroup>
        </MapContainer>
      </div>
    );
  }
}

export default BaseMap;
