import { createSelector } from 'reselect';

const getPersons = (state) => state.persons;
const getOrdering = (state) => state.ordering;
const getLang = (state, ownProps) =>
  ownProps.activeLanguage ? ownProps.activeLanguage.code : 'en';

//sort by rank if rank ordering is selected
export const sortPersonsMemoized = createSelector(
  [getPersons, getOrdering, getLang],
  (persons, ordering, lang) => {
    switch (ordering) {
      case 0:
        if (lang === 'en') {
          const sortable = Object.fromEntries(
            Object.entries(persons).sort((a, b) => a[1].order - b[1].order)
          );
          return sortable;
        } else {
          const sortable = Object.fromEntries(
            Object.entries(persons).sort(
              (a, b) => a[1].orderHrv - b[1].orderHrv
            )
          );
          return sortable;
        }
      case 1:
        const sortable = Object.fromEntries(
          Object.entries(persons).sort((a, b) => a[1].order - b[1].order)
        );
        const resortable = Object.fromEntries(
          Object.entries(sortable).sort((a, b) => b[1].count - a[1].count)
        );
        return resortable;
      default:
        console.log('unknown ordering type');
    }
  }
);
