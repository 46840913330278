import {
  SELECT_CATEGORY,
  SELECT_PERSON,
  SELECT_GROUP,
  SELECT_ORDER,
} from '../actions';
import categories from '../enums/categories.js';
import persons from '../enums/persons.js';

const initialState = {
  categories: categories,
  activeCategory: 'hmwar',
  persons: persons,
  activePerson: 'R002',
  selectedGroup: ['A-B', '1-20'],
  groups: [
    ['A-B', 'C-D', 'F-G', 'H-I', 'J-K', 'L-M', 'N-P', 'R-S', 'T-U', 'V-Z'],
    [
      '1-20',
      '21-40',
      '41-60',
      '61-80',
      '81-100',
      '101-120',
      '121-140',
      '141-160',
      '161-180',
    ],
  ],
  ordering: 1,
};

export default function mysite(state = initialState, action) {
  switch (action.type) {
    case SELECT_CATEGORY:
      return {
        ...state,
        activeCategory: action.activeCategory,
      };
    case SELECT_PERSON:
      return {
        ...state,
        activePerson: action.activePerson,
      };
    case SELECT_GROUP:
      return {
        ...state,
        selectedGroup: action.selectedGroup,
      };
    case SELECT_ORDER:
      return {
        ...state,
        ordering: action.ordering,
      };
    default:
      return state;
  }
}
