export const SELECT_CATEGORY = 'SELECT_CATEGORY';
export const SELECT_PERSON = 'SELECT_PERSON';
export const SELECT_GROUP = 'SELECT_GROUP';
export const SELECT_ORDER = 'SELECT_ORDER';

export function handleCategorySelect(key) {
  return {
    activeCategory: key,
    type: SELECT_CATEGORY,
  };
}

export function handlePersonSelect(key) {
  return {
    activePerson: key,
    type: SELECT_PERSON,
  };
}

export function handleGroupSelect(key) {
  return {
    selectedGroup: key,
    type: SELECT_GROUP,
  };
}

export function handleOrdering(key) {
  return {
    ordering: key,
    type: SELECT_ORDER,
  };
}
