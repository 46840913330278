const store = {
  mapCenter: [44.775844, 16.570752],
  zoomControl: false,
  mapZoom: 8,
  maxZoom: 10,
  minZoom: 7,
  scrollwheel: false,
  legends: true,
  infoControl: false,
  attributionControl: true,
  bounds: [
    [46.5545994833623595, 13.4899287564001238],
    [42.3919521260062666, 19.4453817976932193],
  ],
  maxBounds: [[46.940414, 12.239436][(42.032779, 20.069351)]],
};

export default store;
