import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Nav';
import Image from 'react-bootstrap/Image';
import Carousel from 'react-bootstrap/Carousel';
import { Translate, withLocalize } from 'react-localize-redux';
import st2m1 from '../assets/story2/st2m1.png';
import st2m2 from '../assets/story2/st2m2.png';
import st2m3 from '../assets/story2/st2m3.png';
import st2m4 from '../assets/story2/st2m4.png';
import legendSt2 from '../assets/legend-st2.png';
import legendSt2cro from '../assets/legend-st2-cro.png';

class CarouselStory2 extends React.Component {
  rel21(lang) {
    if (lang === 'en') {
      return (
        <>
          Among the individuals belonging to this category who have not been
          canonized, by far the most popular is the{' '}
          <span style={{ background: '#BEF8FD' }}>
            Cardinal ALOJZIJE STEPINAC
          </span>{' '}
          (1898-1960) with 153 streets carrying his name. Immediately following
          are the bishop and politician{' '}
          <span style={{ background: '#FFF3C4' }}>
            JOSIP JURAJ STROSSMAYER (1815-1905)
          </span>{' '}
          with 111 streets, Jesuit scholar{' '}
          <span style={{ background: '#FACDCD' }}>RUĐER JOSIP BOŠKOVIĆ</span>{' '}
          (1711-1787) with 83 streets, writer and Franciscan friar{' '}
          <span style={{ background: '#DAC4FF' }}>ANDRIJA KAČIĆ MIOŠIĆ</span>{' '}
          (1704-1760) with 55 streets, and the Jesuit writer and linguist{' '}
          <span style={{ background: '#C6F7E2' }}>BARTOL KAŠIĆ</span>{' '}
          (1575-1650) with 41 streets.
        </>
      );
    } else {
      return (
        <>
          Među pojedincima koji pripadaju ovoj kategoriji, a koji nisu
          kanonizirani, daleko je najpopularniji{' '}
          <span style={{ background: '#BEF8FD' }}>
            kardinal ALOJZIJE STEPINAC
          </span>{' '}
          (1898.-1960.) sa 153 ulice koje nose njegovo ime. Odmah slijede biskup
          i političar{' '}
          <span style={{ background: '#FFF3C4' }}>JOSIP JURAJ STROSSMAYER</span>{' '}
          (1815.-1905.) sa 111 ulica, isusovac i znanstvenik{' '}
          <span style={{ background: '#FACDCD' }}>RUĐER JOSIP BOŠKOVIĆ</span>{' '}
          (1711.-1787.) s 83 ulice, književnik i franjevac{' '}
          <span style={{ background: '#DAC4FF' }}>ANDRIJA KAČIĆ MIOŠIĆ</span>{' '}
          (1704.-1760.) s 55 ulica, te isusovački književnik i jezikoslovac{' '}
          <span style={{ background: '#C6F7E2' }}>BARTOL KAŠIĆ</span>{' '}
          (1575.-1650.) s 41 ulicom.
        </>
      );
    }
  }

  rel31(lang) {
    if (lang === 'en') {
      return (
        <>
          The most popular Roman Catholic saints, on the other hand, are{' '}
          <span style={{ background: '#BEF8FD' }}>SAINT MARY</span> with 117
          streets and squares named after her,{' '}
          <span style={{ background: '#FFF3C4' }}>POPE SAINT JOHN PAUL II</span>{' '}
          (60), <span style={{ background: '#FACDCD' }}>SAINT ROCH</span> (60),{' '}
          <span style={{ background: '#DAC4FF' }}>SAINT ANTHONY</span> (60), and{' '}
          <span style={{ background: '#C6F7E2' }}>SAINT GEORGE</span> (55). As
          the map reveals, the naming of public spaces after saints is
          particularly prevalent in Dalmatia.
        </>
      );
    } else {
      return (
        <>
          Najpopularniji rimokatolički sveci su, pak,{' '}
          <span style={{ background: '#BEF8FD' }}>SVETA MARIJA</span> sa 117
          ulica i trgova nazvanih po njoj,{' '}
          <span style={{ background: '#FFF3C4' }}>
            PAPA SVETI IVAN PAVAO II
          </span>{' '}
          (60), <span style={{ background: '#FACDCD' }}>SVETI ROK</span> (60),{' '}
          <span style={{ background: '#DAC4FF' }}>SVETI ANTUN</span> (60) i{' '}
          <span style={{ background: '#C6F7E2' }}>SVETI JURAJ</span> (55). Kako
          karta pokazuje, imenovanje javnih prostora po svecima osobito je
          rasprostranjeno u Dalmaciji.
        </>
      );
    }
  }

  legend(lang) {
    if (lang === 'en') {
      return <Image className="legendStripe" src={legendSt2} width="230px" />;
    } else {
      return (
        <Image className="legendStripe" src={legendSt2cro} width="230px" />
      );
    }
  }

  render() {
    var lang = this.props.activeLanguage
      ? this.props.activeLanguage.code
      : 'en';
    return (
      <div className="lexicsCarousel">
        <Carousel interval={null}>
          <Carousel.Item>
            <Row>
              <Col lg="4">
                <h2
                  style={{
                    fontWeight: 200,
                    fontVariant: 'all-petite-caps',
                  }}
                >
                  <Translate id="religion">Religion</Translate>{' '}
                  <span
                    className="storyNumber"
                    style={{ fontSize: '22px', color: '#62B0E8' }}
                  >
                    (1/4)
                  </span>
                </h2>
                <p>
                  <Translate id="rel1">
                    The names of more than 3300 streets (6.2% of the total) in
                    Croatia can be considered as commemorating people,
                    institutions, organizations, or concepts directly or
                    indirectly related to religious beliefs. Most often, these
                    names refer to individual persons who were priests,
                    religious leaders or saints – almost universally of the
                    Roman Catholic Church. Alternatively, these names can also
                    refer to religious orders (Franciscans, Benedictines,
                    Jesuits, etc.), religious symbols or concepts (Holy Cross,
                    Holy Trinity, Holy Family, All Saints, Holy Sunday, etc.) or
                    local religious buildings (Church, Monastery, Priory, etc.).
                    While there is little geographic variation in the prevalence
                    of such street names, we can observe their higher frequency
                    in Slavonia and Dalmatia than in parts of Central Croatia.
                  </Translate>
                </p>
              </Col>
              <Col lg="8">
                <Image
                  src={st2m1}
                  alt="Map of religion based names in settlements"
                  style={{ maxHeight: window.innerHeight - 83 }}
                  fluid
                />
                <div
                  className="storyLegend"
                  style={{
                    position: 'absolute',
                    bottom: '9rem',
                    fontSize: '12px',
                  }}
                >
                  <Translate id="rel1Leg">
                    Number of streets <br />
                    names related to religion <br />
                    in settlements
                  </Translate>
                </div>
              </Col>
            </Row>
          </Carousel.Item>
          <Carousel.Item>
            <Row>
              <Col lg="4">
                <h2
                  style={{
                    fontWeight: 200,
                    fontVariant: 'all-petite-caps',
                  }}
                >
                  <Translate id="religion">Religion</Translate>{' '}
                  <span
                    className="storyNumber"
                    style={{ fontSize: '22px', color: '#62B0E8' }}
                  >
                    (2/4)
                  </span>
                </h2>
                <p>{this.rel21(lang)}</p>
              </Col>
              <Col lg="8">
                <Image
                  src={st2m2}
                  alt="religion persons map"
                  height="90%"
                  style={{ maxHeight: window.innerHeight - 83 }}
                  fluid
                />
                <div
                  className="storyLegend"
                  style={{ position: 'absolute', bottom: '9rem' }}
                >
                  <Translate id="cat-legend">Street length</Translate>
                </div>
              </Col>
            </Row>
          </Carousel.Item>
          <Carousel.Item>
            <Row>
              <Col lg="4">
                <h2
                  style={{
                    fontWeight: 200,
                    fontVariant: 'all-petite-caps',
                  }}
                >
                  <Translate id="religion">Religion</Translate>{' '}
                  <span
                    className="storyNumber"
                    style={{ fontSize: '22px', color: '#62B0E8' }}
                  >
                    (3/4)
                  </span>
                </h2>
                <p>{this.rel31(lang)}</p>
              </Col>
              <Col lg="8">
                <Image
                  src={st2m3}
                  alt="saints map"
                  style={{ maxHeight: window.innerHeight - 83 }}
                  fluid
                />
                <div
                  className="storyLegend"
                  style={{ position: 'absolute', bottom: '9rem' }}
                >
                  <Translate id="cat-legend">Street length</Translate>
                </div>
              </Col>
            </Row>
          </Carousel.Item>
          <Carousel.Item>
            <Row>
              <Col lg="4">
                <h2
                  style={{
                    fontWeight: 200,
                    fontVariant: 'all-petite-caps',
                  }}
                >
                  <Translate id="religion">Religion</Translate>{' '}
                  <span
                    className="storyNumber"
                    style={{ fontSize: '22px', color: '#62B0E8' }}
                  >
                    (4/4)
                  </span>
                </h2>
                <p>
                  <Translate id="rel4">
                    Although the socialist system prior to the first democratic
                    elections in 1990 did not particularly approve of
                    commemorating people or institutions that could be
                    (in)directly tied to religion, sometimes that was
                    unavoidable since many prominent members of Croatian society
                    in the pre-socialist period came from the ranks of the
                    Catholic Church. Nevertheless, the vast majority of street
                    names related to religion in contemporary Croatia – in fact,
                    nearly 80% of them – have been put in place in the period
                    after 1990. This relatively recent explosion of
                    commemoration of people, institutions, and concepts related
                    to religion has been particularly pronounced in Dalmatia
                    where right-wing parties have dominated local governments
                    since the advent of democracy.
                  </Translate>
                </p>
              </Col>
              <Col lg="8">
                <Image
                  src={st2m4}
                  alt="comparison map"
                  style={{ maxHeight: window.innerHeight - 83 }}
                  fluid
                />
                <div
                  className="storyStripeLegend"
                  style={{
                    position: 'absolute',
                    bottom: '3rem',
                    fontSize: '12px',
                  }}
                >
                  <Translate id="rel4Leg">
                    Comparison of proportion
                    <br /> of street names related to religion
                    <br /> by municipality, 2021 versus 1990
                  </Translate>
                  <br />
                  <br />
                  {this.legend(lang)}
                </div>
              </Col>
            </Row>
          </Carousel.Item>
        </Carousel>
      </div>
    );
  }
}

export default withLocalize(CarouselStory2);
