import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Nav';
import Image from 'react-bootstrap/Image';
import Carousel from 'react-bootstrap/Carousel';
import { Translate, withLocalize } from 'react-localize-redux';
import st4m1 from '../assets/story4/st4m1.png';
import st4m2 from '../assets/story4/st4m2.png';
import st4m3 from '../assets/story4/st4m3.png';
import st4m4 from '../assets/story4/st4m4.png';
import st4m5 from '../assets/story4/st4m5.png';
import legendSt4 from '../assets/legend-st4.png';
import legendSt4cro from '../assets/legend-st4-cro.png';

class CarouselStory4 extends React.Component {
  wwii31(lang) {
    if (lang === 'en') {
      return (
        <>
          The change that took place, however, was not only in the number of
          street names. The composition was altered as well. Today, the five
          persons who actively participated in partisan resistance with the
          largest number of streets named after them are: the writer{' '}
          <span style={{ background: '#BEF8FD' }}>VLADIMIR NAZOR</span> (322
          streets), Croatia’s first president{' '}
          <span style={{ background: '#FFF3C4' }}>FRANJO TUĐMAN</span> (209),
          the writers{' '}
          <span style={{ background: '#FACDCD' }}>IVAN GORAN KOVAČIĆ</span>{' '}
          (116) and{' '}
          <span style={{ background: '#DAC4FF' }}>AUGUST CESAREC</span> (53),
          and the communist revolutionary{' '}
          <span style={{ background: '#C6F7E2' }}>ANDRIJA HEBRANG</span> (50).
          Prior to the advent of democracy, Nazor (339 streets in 1990) and
          Kovačić (108 streets in 1990) were also on this list, but so were the
          military and political leaders of the resistance Josip Broz Tito (281
          streets in 1990 – today 44), Ivo Lola Ribar (217 streets in 1990 –
          today 41), and Rade Končar (125 streets in 1990 – today 19). Several
          things are notable here: today Croatian communities also commemorate
          resistance fighters who later became dissidents and victims of the
          communist regime like Tuđman and Hebrang; artists who supported the
          resistance like Nazor, Kovačić, Cesarec and a number of others
          continue to be remembered; and there has been a precipitous drop in
          the commemoration of military and ideological leaders of the
          resistance, especially those who were not Croats like the
          aforementioned Rade Končar, or Moša Pijade (99 streets in 1990, 3
          today), Veljko Vlahović (78 streets in 1990, none today), Edvard
          Kardelj (68 streets in 1990, 2 today), Boris Kidrič (64 streets in
          1990, 6 today), and many others
        </>
      );
    } else {
      return (
        <>
          Promjena koja se dogodila nakon izbora 1990. nije bila samo u broju
          naziva ulica. Promijenjena je i njihova distribucija, odnosno sastav.
          Od osoba koje su aktivno sudjelovale u partizanskom otporu, njih pet s
          najvećim brojem ulica danas su: književnik{' '}
          <span style={{ background: '#BEF8FD' }}>VLADIMIR NAZOR</span> (322
          ulice), prvi hrvatski predsjednik{' '}
          <span style={{ background: '#FFF3C4' }}>FRANJO TUĐMAN</span> (209),
          književnici{' '}
          <span style={{ background: '#FACDCD' }}>IVAN GORAN KOVAČIĆ</span>{' '}
          (116) i <span style={{ background: '#DAC4FF' }}>AUGUST CESAREC</span>{' '}
          (53), te komunistički revolucionar{' '}
          <span style={{ background: '#C6F7E2' }}>ANDRIJA HEBRANG</span> (50).
          Prije 1990. na ovom popisu bili su i Nazor (tada je 339 ulica bilo
          nazvano po njemu) i Kovačić (108 ulica 1990.), ali i vojni i politički
          čelnici otpora Josip Broz Tito (1990. 281 ulica – danas 44), Ivo Lola
          Ribar (217 ulica 1990. – danas 41), te Rade Končar (1990. 125 ulica –
          danas 19). Ovdje je vrijedno istaknuti nekoliko stvari: danas se
          obilježava sjećanje i na borce koji su kasnije postali disidenti i
          žrtve komunističkog režima poput Tuđmana i Hebranga; u velikoj mjeri
          nastavljeno je sjećanje na umjetnike koji su podržavali partizanski
          pokret poput Nazora, Kovačića, Cesarca i niza drugih; naglo je opala
          komemoracija vojnih i ideoloških vođa otpora, posebno onih koji nisu
          bili Hrvati poput spomenutog Rade Končara ili poput Moše Pijade (99
          ulica 1990., danas 3), Veljka Vlahovića (1990. 78 ulica, danas
          nijedna), Edvarda Kardelja (1990. 68 ulica, danas 2), Borisa Kidriča
          (1990. 64 ulice, danas 6), i mnogih drugih.
        </>
      );
    }
  }

  wwii41(lang) {
    if (lang === 'en') {
      return (
        <>
          Change is even more obvious if we look at the names of streets that
          are not devoted to individuals. Indeed, street names commemorating
          institutions, events, concepts, and military units related to partisan
          resistance and victory in World War II – about 1.9 thousand of them in
          1990 – were the most likely to be changed after the advent of
          democracy. Today, only 229 of these names remain, with the largest
          number devoted to{' '}
          <span style={{ background: '#BEF8FD' }}>FIGHTERS</span> (79 streets –
          405 in 1990), various{' '}
          <span style={{ background: '#FFF3C4' }}>MILITARY UNITS</span> (58
          streets – 735 in 1990), and{' '}
          <span style={{ background: '#C6F7E2' }}>VICTIMS</span> (52 streets –
          171 in 1990). Only 19 streets commemorate{' '}
          <span style={{ background: '#DAC4FF' }}>LIBERATION AND VICTORY</span>{' '}
          (196 in 1990).
        </>
      );
    } else {
      return (
        <>
          Promjena nastala nakon 1990. postaje još očitija ako pogledamo nazive
          ulica koji nisu posvećeni pojedincima. Doista, nazivi ulica koji
          obilježavaju spomen na institucije, događaje, koncepte i vojne
          postrojbe vezane za partizanski otpor i pobjedu u Drugom svjetskom
          ratu – a njih je bilo oko 1,9 tisuća 1990. godine – pretrpjeli su
          najviše promjena nakon dolaska demokracije. Danas ih je ostalo samo
          229, od kojih je najveći broj posvećen{' '}
          <span style={{ background: '#BEF8FD' }}>BORCIMA</span> (79 ulica danas
          – 405 ulica 1990.), raznim{' '}
          <span style={{ background: '#FFF3C4' }}>VOJNIM JEDNICAMA</span> (58
          ulica danas – 735 ulica 1990.) i{' '}
          <span style={{ background: '#C6F7E2' }}>ŽRTVAMA</span> (52 ulice danas
          – 171 ulica 1990.). Samo 19 ulica danas obilježava{' '}
          <span style={{ background: '#DAC4FF' }}>OSLOBOĐENJE I POBJEDU</span>{' '}
          (1990. ih je bilo 196).
        </>
      );
    }
  }

  legend(lang) {
    if (lang === 'en') {
      return <Image className="legendStripe" src={legendSt4} width="230px" />;
    } else {
      return (
        <Image className="legendStripe" src={legendSt4cro} width="230px" />
      );
    }
  }

  render() {
    var lang = this.props.activeLanguage
      ? this.props.activeLanguage.code
      : 'en';
    return (
      <div className="lexicsCarousel">
        <Carousel interval={null}>
          <Carousel.Item>
            <Row>
              <Col lg="4">
                <h2
                  style={{
                    fontWeight: 200,
                    fontVariant: 'all-petite-caps',
                  }}
                >
                  <Translate id="wwii">World War II</Translate>{' '}
                  <span
                    className="storyNumber"
                    style={{ fontSize: '22px', color: '#62B0E8' }}
                  >
                    (1/5)
                  </span>
                </h2>
                <p>
                  <Translate id="wwii1">
                    Commemoration of World War II, or National Liberation War as
                    it was known in former Yugoslavia, dominated streetscapes in
                    Croatia prior to the first democratic elections in 1990. The
                    partisan victory in the war was the defining feature of the
                    founding myth of socialist Yugoslavia. On the eve of the
                    1990 elections that ended communist rule, more than 7.7
                    thousand streets (or 18.6% of the total) were named after
                    people, institutions, events, concepts, and military units
                    directly related to partisan resistance and victory in World
                    War II.
                  </Translate>
                </p>
              </Col>
              <Col lg="8">
                <Image
                  src={st4m1}
                  alt="Map of WWII related street names in settlements before 1990"
                  style={{ maxHeight: window.innerHeight - 83 }}
                  fluid
                />
                <div
                  className="storyLegend"
                  style={{
                    position: 'absolute',
                    bottom: '9rem',
                    fontSize: '12px',
                  }}
                >
                  <Translate id="wwii1Leg">
                    Number of street names
                    <br /> related to World War II
                    <br /> prior to 1990 election
                  </Translate>
                </div>
              </Col>
            </Row>
          </Carousel.Item>
          <Carousel.Item>
            <Row>
              <Col lg="4">
                <h2
                  style={{
                    fontWeight: 200,
                    fontVariant: 'all-petite-caps',
                  }}
                >
                  <Translate id="wwii">World War II</Translate>{' '}
                  <span
                    className="storyNumber"
                    style={{ fontSize: '22px', color: '#62B0E8' }}
                  >
                    (2/5)
                  </span>
                </h2>
                <p>
                  <Translate id="wwii2">
                    Things today look quite different. Less than a quarter of
                    those 7.7 thousand street names related to the memory of
                    World War II in 1990 survived until today. While more than
                    one thousand of these street names were changed within
                    months of the 1990 election, an overwhelming majority of
                    them were altered during Croatia’s 1991-1995 War for
                    Independence. With more recent additions to this group, we
                    can say that today close to 2.5 thousand street names (or
                    4.7% of the total) in Croatia are related to the memory of
                    partisan resistance and victory in World War II.
                  </Translate>
                </p>
              </Col>
              <Col lg="8">
                <Image
                  src={st4m2}
                  alt="Map of WWII related street names in settlements after 1990"
                  height="90%"
                  style={{ maxHeight: window.innerHeight - 83 }}
                  fluid
                />
                <div
                  className="storyLegend"
                  style={{
                    position: 'absolute',
                    bottom: '9rem',
                    fontSize: '12px',
                  }}
                >
                  <Translate id="wwii2Leg">
                    Number of street names <br />
                    related to World War II in 2021
                  </Translate>
                </div>
              </Col>
            </Row>
          </Carousel.Item>
          <Carousel.Item>
            <Row>
              <Col lg="4">
                <h2
                  style={{
                    fontWeight: 200,
                    fontVariant: 'all-petite-caps',
                  }}
                >
                  <Translate id="wwii">World War II</Translate>{' '}
                  <span
                    className="storyNumber"
                    style={{ fontSize: '22px', color: '#62B0E8' }}
                  >
                    (3/5)
                  </span>
                </h2>
                <p>{this.wwii31(lang)}</p>
              </Col>
              <Col lg="8">
                <Image
                  src={st4m3}
                  alt="WWII related personalities map"
                  style={{ maxHeight: window.innerHeight - 83 }}
                  fluid
                />
                <div
                  className="storyLegend"
                  style={{ position: 'absolute', bottom: '10rem' }}
                >
                  <Translate id="cat-legend">Street length</Translate>
                </div>
              </Col>
            </Row>
          </Carousel.Item>
          <Carousel.Item>
            <Row>
              <Col lg="4">
                <h2
                  style={{
                    fontWeight: 200,
                    fontVariant: 'all-petite-caps',
                  }}
                >
                  <Translate id="wwii">World War II</Translate>{' '}
                  <span
                    className="storyNumber"
                    style={{ fontSize: '22px', color: '#62B0E8' }}
                  >
                    (4/5)
                  </span>
                </h2>
                <p>{this.wwii41(lang)}</p>
              </Col>
              <Col lg="8">
                <Image
                  src={st4m4}
                  alt="WWII related concepts map"
                  style={{ maxHeight: window.innerHeight - 83 }}
                  fluid
                />
                <div
                  className="storyLegend"
                  style={{ position: 'absolute', bottom: '9rem' }}
                >
                  <Translate id="cat-legend">Street length</Translate>
                </div>
              </Col>
            </Row>
          </Carousel.Item>
          <Carousel.Item>
            <Row>
              <Col lg="4">
                <h2
                  style={{
                    fontWeight: 200,
                    fontVariant: 'all-petite-caps',
                  }}
                >
                  <Translate id="wwii">World War II</Translate>{' '}
                  <span
                    className="storyNumber"
                    style={{ fontSize: '22px', color: '#62B0E8' }}
                  >
                    (5/5)
                  </span>
                </h2>
                <p>
                  <Translate id="wwii5">
                    If we look at the geographic determinants of the decline in
                    representation of WWII memory in street names, we can see
                    that they are primarily defined by the pattern of
                    distribution of such street names prior to the advent of
                    democracy and the distribution of violence in Croatia’s War
                    for Independence. The commemoration of interethnic
                    cooperation between the Croats and the Serbs characteristic
                    of the partisan struggle in World War II was, perhaps
                    unsurprisingly, most likely to be abandoned in areas most
                    exposed to violence between the Croats and the Serbs in the
                    1991-1995 war.
                  </Translate>
                </p>
              </Col>
              <Col lg="8">
                <Image
                  src={st4m5}
                  alt="Change in WWII related street names after 1990"
                  style={{ maxHeight: window.innerHeight - 83 }}
                  fluid
                />
                <div
                  className="storyStripeLegend"
                  style={{
                    position: 'absolute',
                    bottom: '3rem',
                    fontSize: '12px',
                  }}
                >
                  <Translate id="wwii4Leg">
                    Comparison of proportion of street names
                    <br /> related to Homeland War by municipality,
                    <br /> 2021 versus 1990
                  </Translate>
                  <br />
                  <br />
                  {this.legend(lang)}
                </div>
              </Col>
            </Row>
          </Carousel.Item>
        </Carousel>
      </div>
    );
  }
}

export default withLocalize(CarouselStory4);
