const categories = {
  hmwar: {
    id: 'hmwar',
    title: 'Homeland War',
    desc:
      "Proportion of street names which could be considered as honoring or commemorating events, institutions, and persons directly related to the Homeland War and Croatia's struggle for independence.",
    count: 1534,
    titleHrv: 'Domovinski rat',
    descHrv:
      'Udio naziva ulica za koje bi se moglo smatrati da obilježavaju događaje, institucije ili osobe izravno povezane s Domovinskim ratom i hrvatskom borbom za neovisnost.',
  },
  wwii: {
    id: 'wwii',
    title: 'World War II Resistance',
    desc:
      'Proportion of street names which could be considered as honoring or commemorating events, institutions, and persons directly related to the partisan resistance during World War II. Persons included in this category had to actively take part in partisan forces during the war.',
    count: 2469,
    titleHrv: 'Drugi svjetski rat, partizani',
    descHrv:
      'Udio naziva ulica za koje bi se moglo smatrati da obilježavaju događaje, institucije ili osobe izravno povezane s partizanskim pokretom tijekom Drugog svjetskog rata. Osobe uključene u ovu kategoriju morale su aktivno sudjelovati u partizanskim jedinicama tijekom rata.',
  },
  wwii_oth: {
    id: 'wwii_oth',
    title: 'World War II Collaborators and Victims of Resistance',
    desc:
      'Proportion of street names which could be considered as honoring or commemorating events, institutions, and persons directly related either to Axis forces or to victims of partisans or the communist regime in the period immediately after World War II.',
    count: 109,
    titleHrv: 'Drugi svjetski rat, kolaboracionisti i partizanske žrtve ',
    descHrv:
      'Udio naziva ulica za koje bi se moglo smatrati da obilježavaju događaje, institucije ili osobe izravno povezane s kolaboracionistima Sila Osovine ili s žrtvama partizana ili komunističkog režima u periodu poraća. ',
  },
  rel: {
    id: 'rel',
    title: 'Religion',
    desc:
      'Proportion of street names which could be considered as honoring or commemorating events, institutions, and persons directly related to religion. This includes streets named after saints, clergy, religious concepts and imagery (e.g. Holy Trinity, Holy Cross, Holy Spirit etc.), or institutions (e.g. Franciscans, Friars, Templars, etc.).',
    count: 3322,
    titleHrv: 'Religija',
    descHrv:
      'Udio naziva ulica za koje bi se moglo smatrati da obilježavaju događaje, institucije ili osobe izravno povezane s religijom. To uključuje ulice nazvane po svecima, svećenstvu, vjerskim konceptima i imaginarijem (npr. Sveto Trojstvo, Sveti Križ, Sveti Duh itd.) ili institucijama (npr. Franjevci, fratri, Templari itd.).',
  },
  women: {
    id: 'women',
    title: 'Women',
    desc: 'Proportion of streets named after women.',
    count: 804,
    titleHrv: 'Žene',
    descHrv: 'Udio ulica koje su nazvane po ženama.',
  },
  men: {
    id: 'men',
    title: 'Men',
    desc: 'Proportion of streets named after men.',
    count: 17282,
    titleHrv: 'Muškarci',
    descHrv: 'Udio ulica koje su nazvane po muškarcima.',
  },
  poli: {
    id: 'poli',
    title: 'Politicians',
    desc: 'Proportion of streets named after politicians.',
    count: 5935,
    titleHrv: 'Političari',
    descHrv: 'Udio ulica koje su nazvane po političarima.',
  },
  art: {
    id: 'art',
    title: 'Artists',
    desc:
      'Proportion of streets named after artists (writers, painters, sculptors, actors, etc.).',
    count: 5954,
    titleHrv: 'Umjetnici',
    descHrv:
      'Udio ulica koje su nazvane po umjetnicima (piscima, slikarima, kiparima, glumcima, itd.).',
  },
  scho: {
    id: 'scho',
    title: 'Scholars',
    desc:
      'Proportion of streets named after scholars of any discipline (natural sciences, social sciences, humanities, etc.).',
    count: 1751,
    titleHrv: 'Znanstvenici',
    descHrv:
      'Udio ulica koje su nazvane po znanstvenicima iz bilo koje discipline (prirodnih, društvenih, humanističkih znanosti, itd.).',
  },
  nob: {
    id: 'nob',
    title: 'Nobility',
    desc:
      'Proportion of streets named after nobility (kings, queens, princes, princesses, counts, barrons, etc.).',
    count: 2075,
    titleHrv: 'Plemstvo',
    descHrv:
      'Udio ulica koje su nazvane po plemstvu (kraljevi, kraljice, grofovi, grofice, baruni, itd.).',
  },
};

export default categories;
