import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Nav';
import Image from 'react-bootstrap/Image';
import Carousel from 'react-bootstrap/Carousel';
import { Translate, withLocalize } from 'react-localize-redux';
import st1m0 from '../assets/story1/st1m0.png';
import st1m16 from '../assets/story1/st1m16.png';
import st1m25 from '../assets/story1/st1m25.png';
import st1m3 from '../assets/story1/st1m3.png';
import legendSt1 from '../assets/legend-st1.png';
import legendSt1cro from '../assets/legend-st1-cro.png';

class CarouselStory1 extends React.Component {
  wom21(lang) {
    if (lang === 'en') {
      return (
        <>
          The writers{' '}
          <span style={{ background: '#BEF8FD' }}>IVANA BRLIĆ-MAŽURANIĆ</span>{' '}
          with 36 streets (61<sup>nd</sup> place on the list of people with most
          streets named after them) and{' '}
        </>
      );
    } else {
      return (
        <>
          Spisateljice{' '}
          <span style={{ background: '#BEF8FD' }}>IVANU BRLIĆ-MAŽURANIĆ</span> s
          36 ulica (61. mjesto na rang listi osoba s najvećim brojem ulica) i
        </>
      );
    }
  }

  wom22(lang) {
    if (lang === 'en') {
      return (
        <>
          <span style={{ background: '#FFF3C4' }}>MARIJA JURIĆ – ZAGORKA</span>{' '}
          with 32 streets (65<sup>th</sup> place).
        </>
      );
    } else {
      return (
        <>
          <span style={{ background: '#FFF3C4' }}>MARIJU JURIĆ – ZAGORKU</span>{' '}
          s 32 ulicom (65. mjesto).
        </>
      );
    }
  }

  wom23(lang) {
    if (lang === 'en') {
      return (
        <>
          Countess, writer and diplomat{' '}
          <span style={{ background: '#DAC4FF' }}>KATARINA ZRINSKI</span> with
          26 streets (81<sup>th</sup> place),
        </>
      );
    } else {
      return (
        <>
          Groficu, spisateljicu i diplomatkinju{' '}
          <span style={{ background: '#DAC4FF' }}>KATARINU ZRINSKI</span> s 26
          ulice (81. mjesto),
        </>
      );
    }
  }

  wom24(lang) {
    if (lang === 'en') {
      return (
        <>
          singer <span style={{ background: '#C6F7E2' }}>MILKA TRNINA</span>{' '}
          with 22 streets (94<sup>th</sup> place),
        </>
      );
    } else {
      return (
        <>
          sopranisticu{' '}
          <span style={{ background: '#C6F7E2' }}>MILKU TRNINU</span> s 22
          ulicom (94. mjesto),
        </>
      );
    }
  }

  wom25(lang) {
    if (lang === 'en') {
      return (
        <>
          composer <span style={{ background: '#FACDCD' }}>DORA PEJAČEVIĆ</span>{' '}
          with 20 streets (103<sup>th</sup> place),
        </>
      );
    } else {
      return (
        <>
          skladateljicu{' '}
          <span style={{ background: '#FACDCD' }}>DORU PEJAČEVIĆ</span> s 20
          ulica (103. mjesto),
        </>
      );
    }
  }

  wom26(lang) {
    if (lang === 'en') {
      return (
        <>
          and painter{' '}
          <span style={{ background: '#CFCFCF' }}>SLAVA RAŠKAJ</span> with 17
          streets (118<sup>th</sup> place).
        </>
      );
    } else {
      return (
        <>
          te slikaricu{' '}
          <span style={{ background: '#CFCFCF' }}>SLAVU RAŠKAJ</span> sa 17
          ulica (118. mjesto).
        </>
      );
    }
  }

  wom31(lang) {
    if (lang === 'en') {
      return (
        <>
          By far the most popular among them is{' '}
          <span style={{ background: '#BEF8FD' }}>SAINT MARY</span>, who is also
          the highest ranked woman with 117 streets and squares named after her
          (17<sup>th</sup> place).
        </>
      );
    } else {
      return (
        <>
          Daleko najpopularnija među njima je{' '}
          <span style={{ background: '#BEF8FD' }}>SVETA MARIJA</span>, koja je
          ujedno i najviše rangirana žena sa 117 ulica i trgova nazvanih po njoj
          (17. mjesto).
        </>
      );
    }
  }

  wom32(lang) {
    if (lang === 'en') {
      return (
        <>
          She is followed by{' '}
          <span style={{ background: '#FFF3C4' }}>SAINT ANNE</span> with 33
          streets (64<sup>th</sup> place),
        </>
      );
    } else {
      return (
        <>
          Slijede ju <span style={{ background: '#FFF3C4' }}>SVETA ANA</span> s
          33 ulice (64. mjesto),
        </>
      );
    }
  }

  wom33(lang) {
    if (lang === 'en') {
      return (
        <>
          <span style={{ background: '#DAC4FF' }}>SAINT HELENA</span> with 16
          streets (124<sup>nd</sup> place),
        </>
      );
    } else {
      return (
        <>
          <span style={{ background: '#DAC4FF' }}>SVETA HELENA</span> sa 16
          ulica (124. mjesto),
        </>
      );
    }
  }

  wom34(lang) {
    if (lang === 'en') {
      return (
        <>
          <span style={{ background: '#C6F7E2' }}>SAINT BARBARA</span>
          ulica (141. mjesto).
        </>
      );
    } else {
      return (
        <>
          i <span style={{ background: '#C6F7E2' }}>SVETA BARBARA</span>
        </>
      );
    }
  }

  wom35(lang) {
    if (lang === 'en') {
      return (
        <>
          and <span style={{ background: '#FACDCD' }}>SAINT CATHERINE</span>{' '}
          with 13 streets each (141<sup>th</sup> place).
        </>
      );
    } else {
      return (
        <>
          ,i <span style={{ background: '#FACDCD' }}>SVETA KATARINA</span> sa po
          13 ulica (141. mjesto).
        </>
      );
    }
  }

  legend(lang) {
    if (lang === 'en') {
      return <Image className="legendStripe" src={legendSt1} width="230px" />;
    } else {
      return (
        <Image className="legendStripe" src={legendSt1cro} width="230px" />
      );
    }
  }

  render() {
    var lang = this.props.activeLanguage
      ? this.props.activeLanguage.code
      : 'en';
    var wom2 =
      'Among the 256 women who had at least one street named after them, the largest category consists of artists (83 women). Walking through those rare streets, we most often encounter:';
    var wom3 =
      'The second most significant category consists of 255 streets named after female Catholic saints.';
    return (
      <div className="lexicsCarousel">
        <Carousel interval={null}>
          <Carousel.Item>
            <Row>
              <Col lg="4">
                <h2
                  style={{
                    fontWeight: 200,
                    fontVariant: 'all-petite-caps',
                  }}
                >
                  <Translate id="women">Women</Translate>{' '}
                  <span
                    className="storyNumber"
                    style={{ fontSize: '22px', color: '#62B0E8' }}
                  >
                    (1/4)
                  </span>
                </h2>
                <p>
                  <Translate id="wom1">
                    Out of over 53 thousand streets and squares in Croatia, only
                    805 (1.5%) of them are named after women. An overwhelming
                    majority of almost 7 thousand Croatian settlements do not
                    have any public spaces named after women.
                  </Translate>
                </p>
              </Col>
              <Col lg="8">
                <Image
                  src={st1m0}
                  alt="Map of women in settlements"
                  style={{ maxHeight: window.innerHeight - 83 }}
                  fluid
                />
                <div
                  className="storyLegend"
                  style={{
                    position: 'absolute',
                    bottom: '7rem',
                    fontSize: '12px',
                  }}
                >
                  <Translate id="wom1Leg">
                    Number of streets <br />
                    named after women <br />
                    in settlements
                  </Translate>
                </div>
              </Col>
            </Row>
          </Carousel.Item>
          <Carousel.Item>
            <Row>
              <Col lg="4">
                <h2
                  style={{
                    fontWeight: 200,
                    fontVariant: 'all-petite-caps',
                  }}
                >
                  <Translate id="women">Women</Translate>{' '}
                  <span
                    className="storyNumber"
                    style={{ fontSize: '22px', color: '#62B0E8' }}
                  >
                    (2/4)
                  </span>
                </h2>
                <p>
                  <Translate id="wom2">{wom2}</Translate>
                </p>
                <p>
                  {this.wom21(lang)} {this.wom22(lang)} {this.wom23(lang)}{' '}
                  {this.wom24(lang)} {this.wom25(lang)} {this.wom26(lang)}{' '}
                </p>
              </Col>
              <Col lg="8">
                <Image
                  src={st1m16}
                  alt="artists map"
                  height="90%"
                  style={{ maxHeight: window.innerHeight - 83 }}
                  fluid
                />
                <div
                  className="storyLStripeegend"
                  style={{ position: 'absolute', bottom: '9rem' }}
                >
                  <Translate id="cat-legend">Street length</Translate>
                </div>
              </Col>
            </Row>
          </Carousel.Item>
          <Carousel.Item>
            <Row>
              <Col lg="4">
                <h2
                  style={{
                    fontWeight: 200,
                    fontVariant: 'all-petite-caps',
                  }}
                >
                  <Translate id="women">Women</Translate>{' '}
                  <span
                    className="storyNumber"
                    style={{ fontSize: '22px', color: '#62B0E8' }}
                  >
                    (3/4)
                  </span>
                </h2>
                <p>
                  <Translate id="wom3">{wom3}</Translate>
                </p>
                <p>
                  {this.wom31(lang)} {this.wom32(lang)} {this.wom33(lang)}{' '}
                  {this.wom34(lang)} {this.wom35(lang)}{' '}
                </p>
                <p>
                  <Translate id="wom36">
                    They are followed by female resistance fighters and victims
                    of World War II, politicians, teachers, noblewomen and
                    scholars.
                  </Translate>
                </p>
              </Col>
              <Col lg="8">
                <Image
                  src={st1m25}
                  alt="saints map"
                  style={{ maxHeight: window.innerHeight - 83 }}
                  fluid
                />
                <div
                  className="storyLegend"
                  style={{ position: 'absolute', bottom: '9rem' }}
                >
                  <Translate id="cat-legend">Street length</Translate>
                </div>
              </Col>
            </Row>
          </Carousel.Item>
          <Carousel.Item>
            <Row>
              <Col lg="4">
                <h2
                  style={{
                    fontWeight: 200,
                    fontVariant: 'all-petite-caps',
                  }}
                >
                  <Translate id="women">Women</Translate>{' '}
                  <span
                    className="storyNumber"
                    style={{ fontSize: '22px', color: '#62B0E8' }}
                  >
                    (4/4)
                  </span>
                </h2>
                <p>
                  <Translate id="wom4">
                    In comparison to 805 (1.5%) streets named after women, 17282
                    streets (32.2%) are named after men in Croatia. In only
                    three out of 556 municipalities are there more streets named
                    after women than after men.
                  </Translate>
                </p>
              </Col>
              <Col lg="8">
                <Image
                  src={st1m3}
                  alt="men-women proportion map"
                  style={{ maxHeight: window.innerHeight - 83 }}
                  fluid
                />
                <div
                  className="storyLegend"
                  style={{
                    position: 'absolute',
                    bottom: '3rem',
                    fontSize: '12px',
                  }}
                >
                  <Translate id="wom4Leg">
                    Proportion of streets <br />
                    named after women <br />
                    compared to men
                  </Translate>
                  <br />
                  <br />
                  {this.legend(lang)}
                </div>
              </Col>
            </Row>
          </Carousel.Item>
        </Carousel>
      </div>
    );
  }
}

export default withLocalize(CarouselStory1);
