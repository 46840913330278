const persons = {
  A003: {
    id: 'A003',
    count: 19,
    order: 1,
    orderHrv: 2,
    nameHrv: 'Andrić, Ivo',
    linkHrv: 'https://enciklopedija.hr/Natuknica.aspx?ID=2618',
    cvHrv:
      'Književnik, dobitnik Nobelove nagrade za književnost 1961. (Travnik, 9. X. 1892 – Beograd, 13. III. 1975).',
    name: 'Andrić, Ivo',
    rank: 108,
    link: 'https://en.wikipedia.org/wiki/Ivo_Andri%C4%87',
    group: 'A',
    groupHrv: 'A',
    cv:
      'Writer, winner of the 1961 Nobel Prize in Literature (Travnik, 9 October 1892 - Belgrade, 13 March 1975).',
  },
  A001: {
    id: 'A001',
    count: 19,
    order: 4,
    orderHrv: 4,
    nameHrv: 'Augustinčić, Antun',
    linkHrv: 'https://enciklopedija.hr/Natuknica.aspx?ID=4619',
    cvHrv: 'Hrvatski kipar (Klanjec, 4. V. 1900 – Zagreb, 10. V. 1979).',
    name: 'Augustinčić, Antun',
    rank: 108,
    link: 'https://en.wikipedia.org/wiki/Antun_Augustin%C4%8Di%C4%87',
    group: 'A',
    groupHrv: 'A',
    cv:
      'Croatian sculptor (Klanjec, Klanjec, 4 May 1900 - Zagreb, 10 May 1979).',
  },
  B022: {
    id: 'B022',
    count: 12,
    order: 5,
    orderHrv: 5,
    nameHrv: 'Babić, Ljubo',
    linkHrv: 'https://enciklopedija.hr/Natuknica.aspx?ID=5013',
    cvHrv:
      'Hrvatski slikar i povjesničar umjetnosti (Jastrebarsko, 14. VI. 1890 – Zagreb, 14. V. 1974).',
    name: 'Babić, Ljubo',
    rank: 152,
    link: 'https://en.wikipedia.org/wiki/Ljubo_Babi%C4%87',
    group: 'B',
    groupHrv: 'B',
    cv:
      'Croatian painter and art historian (Jastrebarsko, 14 June 1890 - Zagreb, 14 May 1974).',
  },
  B009: {
    id: 'B009',
    count: 24,
    order: 6,
    orderHrv: 6,
    nameHrv: 'Balota, Mate',
    linkHrv: 'https://enciklopedija.hr/Natuknica.aspx?ID=41145',
    cvHrv:
      'Pravo ime Mirković, Mijo, hrvatski ekonomist i književnik (Rakalj, 28. IX. 1898 – Zagreb, 17. II. 1963).',
    name: 'Balota, Mate',
    rank: 89,
    link: 'https://en.wikipedia.org/wiki/Mate_Balota',
    group: 'B',
    groupHrv: 'B',
    cv:
      'Real name Mirković, Mijo, Croatian economist and writer (Rakalj, 28 September 1898 - Zagreb, 17 February 1963).',
  },
  B020: {
    id: 'B020',
    count: 10,
    order: 7,
    orderHrv: 7,
    nameHrv: 'Baraković, Juraj',
    linkHrv: 'https://enciklopedija.hr/Natuknica.aspx?ID=5810',
    cvHrv: 'Hrvatski pjesnik (Plemići kraj Zadra, 1548 – Rim, 1. VIII. 1628).',
    name: 'Baraković, Juraj',
    rank: 178,
    link: 'https://en.wikipedia.org/wiki/Juraj_Barakovi%C4%87',
    group: 'B',
    groupHrv: 'B',
    cv: 'Croatian poet (Plemići near Zadar, 1548 - Rome, 1 August 1628).',
  },
  B012: {
    id: 'B012',
    count: 26,
    order: 8.5,
    orderHrv: 8.5,
    nameHrv: 'Basariček, Đuro',
    linkHrv: 'https://enciklopedija.hr/Natuknica.aspx?ID=6125',
    cvHrv:
      'Hrvatski političar i socijalni radnik (Zagreb, 13. III. 1884 – Beograd, 20. VI. 1928).',
    name: 'Basariček, Đuro',
    rank: 81,
    link: 'https://en.wikipedia.org/wiki/%C4%90uro_Basari%C4%8Dek',
    group: 'B',
    groupHrv: 'B',
    cv:
      'Croatian politician and social worker (Zagreb, 13 March 1884 - Belgrade, 20 June 1928).',
  },
  B011: {
    id: 'B011',
    count: 23,
    order: 11,
    orderHrv: 11,
    nameHrv: 'Berislavić, Petar',
    linkHrv: 'https://enciklopedija.hr/Natuknica.aspx?ID=7094',
    cvHrv:
      'Hrvatski ban i diplomat (Trogir, 1475 – Vražji vrtal ili Vražja gora kraj Korenice, 20. V. 1520).',
    name: 'Berislavić, Petar',
    rank: 92,
    link: 'https://en.wikipedia.org/wiki/Petar_Berislavi%C4%87',
    group: 'B',
    groupHrv: 'B',
    cv:
      'Croatian ban (viceroy) and diplomat (Trogir, 1475 - Vražji vrtal or Vražja gora near Korenica, 20 May 1520).',
  },
  B021: {
    id: 'B021',
    count: 13,
    order: 12,
    orderHrv: 12,
    nameHrv: 'Bogović, Mirko',
    linkHrv: 'https://enciklopedija.hr/Natuknica.aspx?ID=8407',
    cvHrv: 'Hrvatski književnik (Varaždin, 2. II. 1816 – Zagreb, 4. V. 1893).',
    name: 'Bogović, Mirko',
    rank: 141,
    link: 'https://en.wikipedia.org/wiki/Mirko_Bogovi%C4%87',
    group: 'B',
    groupHrv: 'B',
    cv: 'Croatian writer (Varaždin, 2 February 1816 - Zagreb, 4 May 1893).',
  },
  B001: {
    id: 'B001',
    count: 83,
    order: 13,
    orderHrv: 13,
    nameHrv: 'Bošković, Ruđer Josip',
    linkHrv: 'https://enciklopedija.hr/natuknica.aspx?id=8948',
    cvHrv:
      'Hrvatski znanstvenik i filozof (Dubrovnik, 18. V. 1711 – Milano, 13. II. 1787).',
    name: 'Bošković, Ruđer Josip',
    rank: 27,
    link: 'https://en.wikipedia.org/wiki/Roger_Joseph_Boscovich',
    group: 'B',
    groupHrv: 'B',
    cv:
      'Croatian scientist and philosopher (Dubrovnik, 18 May 1711 - Milan, 13 February 1787).',
  },
  B019: {
    id: 'B019',
    count: 12,
    order: 14,
    orderHrv: 14,
    nameHrv: 'Botić, Luka',
    linkHrv: 'https://enciklopedija.hr/natuknica.aspx?id=8980',
    cvHrv:
      'Hrvatski književnik (Split, 22. I. 1830 – Đakovo, 22. VIII. 1863). ',
    name: 'Botić, Luka',
    rank: 152,
    link: 'https://hr.wikipedia.org/wiki/Luka_Boti%C4%87',
    group: 'B',
    groupHrv: 'B',
    cv: 'Croatian writer (Split, 22 January 1830 - Đakovo, 22 August 1863).',
  },
  B003: {
    id: 'B003',
    count: 84,
    order: 15,
    orderHrv: 15,
    nameHrv: 'Branimir',
    linkHrv: 'https://enciklopedija.hr/Natuknica.aspx?ID=9269',
    cvHrv:
      'Branimerus, Branimirus, Brannimerus, Breanimir; hrvatski knez (?, prva polovica IX. st. – ?, nakon 892).',
    name: 'Branimir',
    rank: 26,
    link: 'https://en.wikipedia.org/wiki/Branimir_of_Croatia',
    group: 'B',
    groupHrv: 'B',
    cv:
      'Branimerus, Branimirus, Brannimerus, Breanimir; Croatian duke (?, first half of the 9th century - ?, after 892).',
  },
  B043: {
    id: 'B043',
    count: 10,
    order: 16,
    orderHrv: 16,
    nameHrv: 'Brezovački, Tito',
    linkHrv: 'https://enciklopedija.hr/Natuknica.aspx?ID=9464',
    cvHrv: 'Hrvatski književnik (Zagreb, 4. I. 1757 – Zagreb, 29. X. 1805).',
    name: 'Brezovački, Tito',
    rank: 178,
    link: 'https://en.wikipedia.org/wiki/Titu%C5%A1_Brezova%C4%8Dki',
    group: 'B',
    groupHrv: 'B',
    cv: 'Croatian writer (Zagreb, 4 January 1757 - Zagreb, 29 October 1805).',
  },
  B005: {
    id: 'B005',
    count: 36,
    order: 17,
    orderHrv: 17,
    nameHrv: 'Brlić-Mažuranić, Ivana',
    linkHrv: 'https://enciklopedija.hr/Natuknica.aspx?ID=9601',
    cvHrv:
      'Hrvatska književnica (Ogulin, 18. IV. 1874 – Zagreb, 21. IX. 1938). ',
    name: 'Brlić-Mažuranić, Ivana',
    rank: 61,
    link: 'https://en.wikipedia.org/wiki/Ivana_Brli%C4%87-Ma%C5%BEurani%C4%87',
    group: 'B',
    groupHrv: 'B',
    cv: 'Croatian writer (Ogulin, 18 April 1874 - Zagreb, 21 September 1938).',
  },
  B006: {
    id: 'B006',
    count: 44,
    order: 18,
    orderHrv: 18,
    nameHrv: 'Broz, Josip - Tito',
    linkHrv: 'https://enciklopedija.hr/natuknica.aspx?id=9758',
    cvHrv:
      'Hrvatski političar, komunistički revolucionar i državnik, narodni heroj (Kumrovec, 7. V. 1892 – Ljubljana, 4. V. 1980).',
    name: 'Broz, Josip - Tito',
    rank: 53,
    link: 'https://en.wikipedia.org/wiki/Josip_Broz_Tito',
    group: 'B',
    groupHrv: 'B',
    cv:
      'Croatian politician, communist revolutionary and statesman, National Hero (Kumrovec, 7 May 1892 - Ljubljana, 4 May 1980).',
  },
  B018: {
    id: 'B018',
    count: 12,
    order: 19,
    orderHrv: 19,
    nameHrv: 'Budak, Mile',
    linkHrv: 'https://enciklopedija.hr/natuknica.aspx?id=9958',
    cvHrv:
      'Hrvatski književnik i političar, jedan od ideologa ustaškog pokreta i ministar u vladi NDH za vrijeme Drugog svjetskog rata (Sveti Rok kraj Gračaca, 30. VIII. 1889 – Zagreb, 7. VI. 1945).',
    name: 'Budak, Mile',
    rank: 152,
    link: 'https://en.wikipedia.org/wiki/Mile_Budak',
    group: 'B',
    groupHrv: 'B',
    cv:
      'Croatian writer and politician, ideologue of the Ustasha movement and minister in the collaborationist government of the Independent State of Croatia during World War II (Sveti Rok near Gračac, 30 August 1889 - Zagreb, 7 June 1945).',
  },
  B044: {
    id: 'B044',
    count: 12,
    order: 20,
    orderHrv: 20,
    nameHrv: 'Budicin, Giuseppe - Pino ',
    linkHrv: 'https://istrapedia.hr/hr/natuknice/394/budicin-giuseppe-pino',
    cvHrv:
      'Istarski partizan, antifašist, narodni heroj (Rovinj, 27. IV. 1911 - Valdibora kraj Rovinja, 8. II. 1944).',
    name: 'Budicin, Giuseppe - Pino ',
    rank: 152,
    link: 'https://www.tracesofwar.com/sights/52685/Bust-Pino-Budicin.htm',
    group: 'B',
    groupHrv: 'B',
    cv:
      'Istrian partisan, anti-fascist, National Hero (Rovinj, 27 April 1911 - Valdibora near Rovinj, 8 February 1944).',
  },
  B008: {
    id: 'B008',
    count: 27,
    order: 21,
    orderHrv: 21,
    nameHrv: 'Bukovac, Vlaho',
    linkHrv: 'https://enciklopedija.hr/Natuknica.aspx?ID=10081',
    cvHrv:
      'Rođen kao Biagio Faggioni, hrvatski slikar (Cavtat, 4. VII. 1855 – Prag, 23. IV. 1922).',
    name: 'Bukovac, Vlaho',
    rank: 73,
    link: 'https://en.wikipedia.org/wiki/Vlaho_Bukovac',
    group: 'B',
    groupHrv: 'B',
    cv:
      'Born as Biagio Faggioni, Croatian painter (Cavtat, 4 July 1855 - Prague, 23 April 1922).',
  },
  B016: {
    id: 'B016',
    count: 20,
    order: 22,
    orderHrv: 22,
    nameHrv: 'Bulić, Frane',
    linkHrv: 'https://enciklopedija.hr/Natuknica.aspx?ID=10126',
    cvHrv:
      'Hrvatski arheolog, epigrafičar, povjesničar i konzervator starina (Vranjic kraj Splita, 4. X. 1846 – Zagreb, 29. VII. 1934).',
    name: 'Bulić, Frane',
    rank: 103,
    link: 'https://en.wikipedia.org/wiki/Frane_Buli%C4%87',
    group: 'B',
    groupHrv: 'B',
    cv:
      'Croatian archaeologist, epigraphist, historian and conservator of antiquities (Vranjic near Split, 4 October 1846 - Zagreb, 29 July 1934).  ',
  },
  B002: {
    id: 'B002',
    count: 45,
    order: 23,
    orderHrv: 23,
    nameHrv: 'Bušić, Bruno',
    linkHrv: 'https://enciklopedija.hr/natuknica.aspx?id=10351',
    cvHrv:
      'Hrvatski političar i publicist; disident, ubijen u atentatu jugoslavenske tajne policije (Donji Vinjani kraj Imotskog, 6. X. 1939 – Pariz, 16. X. 1978).',
    name: 'Bušić, Bruno',
    rank: 51,
    link: 'https://en.wikipedia.org/wiki/Bruno_Bu%C5%A1i%C4%87',
    group: 'B',
    groupHrv: 'B',
    cv:
      'Croatian politician and writer; dissident, assassinated by the Yugoslav Secret Police (Donji Vinjani near Imotski, 6 October 1939 - Paris, 16 October 1978).',
  },
  C003: {
    id: 'C003',
    count: 15,
    order: 24,
    orderHrv: 24,
    nameHrv: 'Car Emin, Viktor',
    linkHrv: 'https://enciklopedija.hr/Natuknica.aspx?ID=10775',
    cvHrv:
      'Hrvatski književnik (Kraj kraj Lovrana, 1. XI. 1870 – Opatija, 17. IV. 1963).',
    name: 'Car Emin, Viktor',
    rank: 128,
    link: 'https://en.wikipedia.org/wiki/Viktor_Car_Emin',
    group: 'C',
    groupHrv: 'C',
    cv:
      'Croatian writer (Kraj near Lovran, 1 November 1870 - Opatija, 17 April 1963).',
  },
  C001: {
    id: 'C001',
    count: 53,
    order: 26,
    orderHrv: 25,
    nameHrv: 'Cesarec, August',
    linkHrv: 'https://enciklopedija.hr/natuknica.aspx?id=11323',
    cvHrv:
      'Hrvatski književnik, komunistički intelektualac i aktivist (Zagreb, 4. XII. 1893 – Zagreb, 17. ili 18. VII. 1941)',
    name: 'Cesarec, August',
    rank: 41,
    link: 'https://en.wikipedia.org/wiki/August_Cesarec',
    group: 'C',
    groupHrv: 'C',
    cv:
      'Croatian writer, communist intellectual and activist (Zagreb, 4 December 1893 - Zagreb, 17 or 18 July 1941)',
  },
  C002: {
    id: 'C002',
    count: 43,
    order: 27,
    orderHrv: 26,
    nameHrv: 'Cesarić, Dobriša',
    linkHrv: 'https://enciklopedija.hr/natuknica.aspx?id=11327',
    cvHrv:
      'Hrvatski pjesnik i prevoditelj (Požega, 10. I. 1902 – Zagreb, 18. XII. 1980).',
    name: 'Cesarić, Dobriša',
    rank: 55,
    link: 'https://en.wikipedia.org/wiki/Dobri%C5%A1a_Cesari%C4%87',
    group: 'C',
    groupHrv: 'C',
    cv:
      'Croatian poet and translator (Požega, 10 January 1902 - Zagreb, 18 December 1980).',
  },
  D002: {
    id: 'D002',
    count: 17,
    order: 28,
    orderHrv: 27,
    nameHrv: 'Dobrila, Juraj',
    linkHrv: 'https://enciklopedija.hr/Natuknica.aspx?ID=15663',
    cvHrv:
      'Biskup i hrvatski narodni preporoditelj (Veli Ježenj, Pazin, 16. IV. 1812 – Trst, 13. I. 1882).',
    name: 'Dobrila, Juraj',
    rank: 118,
    link: 'https://en.wikipedia.org/wiki/Juraj_Dobrila',
    group: 'D',
    groupHrv: 'D',
    cv:
      'Bishop and Croatian national revivalist (Veli Ježenj, Pazin, 16 April 1812 - Trieste, 13 January 1882).',
  },
  D001: {
    id: 'D001',
    count: 75,
    order: 29,
    orderHrv: 28,
    nameHrv: 'Domagoj',
    linkHrv: 'https://enciklopedija.hr/Natuknica.aspx?ID=15823',
    cvHrv: 'Domagous, Domogous; hrvatski knez (vladao oko 864. do 876.).',
    name: 'Domagoj',
    rank: 28,
    link: 'https://en.wikipedia.org/wiki/Domagoj_of_Croatia',
    group: 'D',
    groupHrv: 'D',
    cv: 'Domagous, Domogous; Croatian duke (ruled around 864 to 876).',
  },
  D003: {
    id: 'D003',
    count: 44,
    order: 30,
    orderHrv: 29,
    nameHrv: 'Domjanić, Dragutin Milivoj',
    linkHrv: 'https://enciklopedija.hr/natuknica.aspx?id=15876',
    cvHrv:
      'Hrvatski pjesnik (Krči kraj Adamovca, 12. IX. 1875 – Zagreb, 7. VI. 1933).',
    name: 'Domjanić, Dragutin Milivoj',
    rank: 53,
    link: 'https://en.wikipedia.org/wiki/Dragutin_Domjani%C4%87',
    group: 'D',
    groupHrv: 'D',
    cv:
      'Croatian poet (Krči near Adamovac, 12 September 1875 - Zagreb, 7 June 1933)',
  },
  D004: {
    id: 'D004',
    count: 22,
    order: 31,
    orderHrv: 30,
    nameHrv: 'Drašković, Janko',
    linkHrv: 'https://enciklopedija.hr/Natuknica.aspx?ID=16192',
    cvHrv:
      'Grof, hrvatski preporoditelj i političar (Zagreb, 20. X. 1770 – Radkersburg, Austrija, 14. I. 1856).',
    name: 'Drašković, Janko',
    rank: 94,
    link: 'https://en.wikipedia.org/wiki/Janko_Dra%C5%A1kovi%C4%87',
    group: 'D',
    groupHrv: 'D',
    cv:
      'Count, Croatian revivalist and politician (Zagreb, 20 October 1770 - Radkersburg, Austria, 14 January 1856).',
  },
  D006: {
    id: 'D006',
    count: 48,
    order: 32,
    orderHrv: 31,
    nameHrv: 'Držić, Marin',
    linkHrv: 'https://enciklopedija.hr/Natuknica.aspx?ID=16386',
    cvHrv:
      'Hrvatski pjesnik, dramski pisac, autor političkih tekstova i glumac (Dubrovnik, 1508 – Venecija, 2. V. 1567). ',
    name: 'Držić, Marin',
    rank: 48,
    link: 'https://en.wikipedia.org/wiki/Marin_Dr%C5%BEi%C4%87',
    group: 'D',
    groupHrv: 'D',
    cv:
      'Croatian poet, playwright, author of political texts and actor (Dubrovnik, 1508 - Venice, 2 May 1567).',
  },
  F001: {
    id: 'F001',
    count: 26,
    order: 35,
    orderHrv: 34,
    nameHrv: 'Frankopan, Fran II. Krsto',
    linkHrv: 'https://enciklopedija.hr/natuknica.aspx?id=20427',
    cvHrv:
      'Hrvatski plemić i pjesnik (Bosiljevo, 4. III. 1643 – Bečko Novo Mjesto, 30. IV. 1671).',
    name: 'Frankopan, Fran II. Krsto',
    rank: 81,
    link: 'https://en.wikipedia.org/wiki/Fran_Krsto_Frankopan',
    group: 'F',
    groupHrv: 'F',
    cv:
      'Croatian nobleman and poet (Bosiljevo, 4 March 1643 - Wiener Neustadt, 30 April 1671).',
  },
  G003: {
    id: 'G003',
    count: 247,
    order: 36,
    orderHrv: 35,
    nameHrv: 'Gaj, Ljudevit',
    linkHrv: 'https://enciklopedija.hr/natuknica.aspx?id=21007',
    cvHrv:
      'Vođa hrvatskoga narodnoga preporoda i pisac (Krapina, 8. VII. 1809 – Zagreb, 20. IV. 1872).',
    name: 'Gaj, Ljudevit',
    rank: 6,
    link: 'https://en.wikipedia.org/wiki/Ljudevit_Gaj',
    group: 'G',
    groupHrv: 'G',
    cv:
      'Leader of the Croatian national revival and writer (Krapina, 8 July 1809 - Zagreb, 20 April 1872).',
  },
  G011: {
    id: 'G011',
    count: 19,
    order: 37,
    orderHrv: 36,
    nameHrv: 'Galović, Fran',
    linkHrv: 'https://enciklopedija.hr/Natuknica.aspx?ID=21131',
    cvHrv:
      'Hrvatski književnik (Peteranec, 20. VII. 1887 – Radenkovići, Srbija, 26. X. 1914). ',
    name: 'Galović, Fran',
    rank: 108,
    link: 'https://en.wikipedia.org/wiki/Fran_Galovi%C4%87',
    group: 'G',
    groupHrv: 'G',
    cv:
      'Croatian writer (Peteranec, 20 July 1887 - Radenkovići, Serbia, 26 October 1914).',
  },
  G010: {
    id: 'G010',
    count: 12,
    order: 39,
    orderHrv: 37,
    nameHrv: 'Gervais, Drago',
    linkHrv: 'https://enciklopedija.hr/Natuknica.aspx?ID=21828',
    cvHrv:
      'Hrvatski književnik (Opatija, 18. IV. 1904 – Opatija, 1. VII. 1957). ',
    name: 'Gervais, Drago',
    rank: 152,
    link: 'https://en.wikipedia.org/wiki/Drago_Gervais',
    group: 'G',
    groupHrv: 'G',
    cv: 'Croatian writer (Opatija, 18 April 1904 - Opatija, 1 July 1957).',
  },
  G014: {
    id: 'G014',
    count: 10,
    order: 40,
    orderHrv: 38,
    nameHrv: 'Getaldić, Marin',
    linkHrv: 'https://enciklopedija.hr/Natuknica.aspx?ID=21855',
    cvHrv:
      'Latinizirano Marinus Ghetaldus, hrvatski matematičar (Dubrovnik, 1568 – Dubrovnik, 7. ili 8. IV. 1626).',
    name: 'Getaldić, Marin',
    rank: 178,
    link: 'https://en.wikipedia.org/wiki/Marino_Ghetaldi',
    group: 'G',
    groupHrv: 'G',
    cv:
      'Latin: Marinus Ghetaldus, Italian: Marino Ghetaldi; Croatian mathematician (Dubrovnik, 1568 - Dubrovnik, 7 or 8 April 1626).',
  },
  G009: {
    id: 'G009',
    count: 28,
    order: 41,
    orderHrv: 39,
    nameHrv: 'Gjalski, Ksaver Šandor',
    linkHrv: 'https://enciklopedija.hr/Natuknica.aspx?ID=22117',
    cvHrv:
      'Pravo ime Ljubomil Tito Josip Franjo Babić, hrvatski književnik (Gredice kraj Zaboka, 26. X. 1854 – Gredice, 6. II. 1935).',
    name: 'Gjalski, Ksaver Šandor',
    rank: 71,
    link: 'https://en.wikipedia.org/wiki/Ksaver_%C5%A0andor_Gjalski',
    group: 'G',
    groupHrv: 'G',
    cv:
      'Real name Ljubomil Tito Josip Franjo Babić, Croatian writer (Gredice near Zabok, 26 October 1854 - Gredice, 6 February 1935).',
  },
  G006: {
    id: 'G006',
    count: 28,
    order: 42,
    orderHrv: 40,
    nameHrv: 'Gortan, Vladimir',
    linkHrv: 'https://enciklopedija.hr/Natuknica.aspx?ID=22788',
    cvHrv: 'Hrvatski antifašist (Beram, 7. VI. 1904 – Pula, 17. X. 1929). ',
    name: 'Gortan, Vladimir',
    rank: 71,
    link: 'https://hr.wikipedia.org/wiki/Vladimir_Gortan',
    group: 'G',
    groupHrv: 'G',
    cv: 'Croatian anti-fascist (Beram, 7 June 1904 - Pula, 17 October 1929).',
  },
  G008: {
    id: 'G008',
    count: 27,
    order: 43,
    orderHrv: 41,
    nameHrv: 'Gotovac, Jakov',
    linkHrv: 'https://enciklopedija.hr/Natuknica.aspx?ID=22846',
    cvHrv: 'Hrvatski skladatelj (Split, 11. X. 1895 – Zagreb, 16. X. 1982).',
    name: 'Gotovac, Jakov',
    rank: 73,
    link: 'https://en.wikipedia.org/wiki/Jakov_Gotovac',
    group: 'G',
    groupHrv: 'G',
    cv: 'Croatian composer (Split, 11 October 1895 - Zagreb, 16 October 1982).',
  },
  G007: {
    id: 'G007',
    count: 11,
    order: 44,
    orderHrv: 42,
    nameHrv: 'Gotovac, Vlado',
    linkHrv: 'https://enciklopedija.hr/Natuknica.aspx?ID=22850',
    cvHrv:
      'Hrvatski književnik, disident i političar (Imotski, 18. IX. 1930 – Rim, 7. XII. 2000). ',
    name: 'Gotovac, Vlado',
    rank: 169,
    link: 'https://en.wikipedia.org/wiki/Vlado_Gotovac',
    group: 'G',
    groupHrv: 'G',
    cv:
      'Croatian writer, dissident and politician (Imotski, 18 September 1930 - Rome, 7 December 2000).',
  },
  G012: {
    id: 'G012',
    count: 30,
    order: 45,
    orderHrv: 43,
    nameHrv: 'Grgur Ninski',
    linkHrv: 'https://enciklopedija.hr/Natuknica.aspx?ID=23369',
    cvHrv: 'Biskup (?, druga polovica IX. st. – ?, nakon 928.).',
    name: 'Grgur of Nin',
    rank: 68,
    link: 'https://en.wikipedia.org/wiki/Gregory_of_Nin',
    group: 'G',
    groupHrv: 'G',
    cv: 'Bishop (?, second half of the 9th century - ?, after 928).',
  },
  G004: {
    id: 'G004',
    count: 385,
    order: 46,
    orderHrv: 44,
    nameHrv: 'Gubec, Matija',
    linkHrv: 'https://enciklopedija.hr/Natuknica.aspx?ID=23650',
    cvHrv:
      'Pravo ime Ambroz, vođa hrvatsko-slovenske seljačke bune 1573. (Hižakovec u Hrvatskom zagorju, oko 1548 – Zagreb, 15. II. ? 1573).',
    name: 'Gubec, Matija',
    rank: 2,
    link: 'https://en.wikipedia.org/wiki/Matija_Gubec',
    group: 'G',
    groupHrv: 'G',
    cv:
      'Real name Ambroz, leader of the Croatian-Slovenian peasant revolt 1573 (Hižakovec in Hrvatsko zagorje, around 1548 - Zagreb, 15 February ? 1573).',
  },
  G005: {
    id: 'G005',
    count: 117,
    order: 47,
    orderHrv: 45,
    nameHrv: 'Gundulić, Ivan',
    linkHrv: 'https://enciklopedija.hr/Natuknica.aspx?ID=23793',
    cvHrv:
      'Hrvatski književnik (Dubrovnik, 9. I. 1589 – Dubrovnik, 8. XII. 1638).',
    name: 'Gundulić, Ivan',
    rank: 17,
    link: 'https://en.wikipedia.org/wiki/Ivan_Gunduli%C4%87',
    group: 'G',
    groupHrv: 'G',
    cv:
      'Croatian writer (Dubrovnik, 9 January 1589 - Dubrovnik, 8 December 1638).',
  },
  H002: {
    id: 'H002',
    count: 50,
    order: 48,
    orderHrv: 46,
    nameHrv: 'Hebrang, Andrija',
    linkHrv: 'https://enciklopedija.hr/Natuknica.aspx?ID=24698',
    cvHrv:
      'Hrvatski političar, komunist i kasniji disident ubijen od strane komunističkoga režima (Bačevac kraj Virovitice, 21. XI. 1899 – Beograd, ? 11. VI. 1949).',
    name: 'Hebrang, Andrija',
    rank: 44,
    link: 'https://en.wikipedia.org/wiki/Andrija_Hebrang_(father)',
    group: 'H',
    groupHrv: 'H',
    cv:
      'Croatian communist politician and later dissident, killed by the communist regime (Bačevac near Virovitica, 21 November 1899 - Belgrade, 11 June 1949).',
  },
  H005: {
    id: 'H005',
    count: 11,
    order: 49,
    orderHrv: 47,
    nameHrv: 'Hegedušić, Krsto',
    linkHrv: 'https://enciklopedija.hr/Natuknica.aspx?ID=24743',
    cvHrv: 'Hrvatski slikar (Petrinja, 26. XI. 1901 – Zagreb, 7. IV. 1975). ',
    name: 'Hegedušić, Krsto',
    rank: 169,
    link: 'https://en.wikipedia.org/wiki/Krsto_Hegedu%C5%A1i%C4%87',
    group: 'H',
    groupHrv: 'H',
    cv: 'Croatian painter (Petrinja, 26 November 1901 - Zagreb, 7 April 1975).',
  },
  H003: {
    id: 'H003',
    count: 24,
    order: 50,
    orderHrv: 48,
    nameHrv: 'Hektorović, Petar',
    linkHrv: 'https://enciklopedija.hr/Natuknica.aspx?ID=24829',
    cvHrv:
      'Hrvatski pjesnik (Hvar ili Stari Grad, između 18. II. i 1. VII. 1487 – Stari Grad, 13. III. 1572).',
    name: 'Hektorović, Petar',
    rank: 89,
    link: 'https://en.wikipedia.org/wiki/Petar_Hektorovi%C4%87',
    group: 'H',
    groupHrv: 'H',
    cv:
      'Croatian poet (Hvar or Stari Grad, between 18 February and 1 July 1487 - Stari Grad, 13 March 1572).',
  },
  H004: {
    id: 'H004',
    count: 10,
    order: 52,
    orderHrv: 50,
    nameHrv: 'Holjevac, Većeslav',
    linkHrv: 'https://enciklopedija.hr/Natuknica.aspx?ID=25994',
    cvHrv:
      'Hrvatski general, političar i narodni heroj (Karlovac, 22. VIII. 1917 – Zagreb, 11. VII. 1970).',
    name: 'Holjevac, Većeslav',
    rank: 178,
    link: 'https://en.wikipedia.org/wiki/Ve%C4%87eslav_Holjevac',
    group: 'H',
    groupHrv: 'H',
    cv:
      'Croatian general, politician, National Hero (Karlovac, 22 August 1917 - Zagreb, 11 July 1970).',
  },
  V012: {
    id: 'V012',
    count: 14,
    order: 53,
    orderHrv: 51,
    nameHrv: 'Hrvatinić, Hrvoje Vukčić',
    linkHrv: 'https://enciklopedija.hr/Natuknica.aspx?ID=26387',
    cvHrv:
      'Veliki vojvoda bosanski i herceg splitski (? Kotor, danas Kotor Varoš, oko 1350 – ?, 1416).',
    name: 'Hrvatinić, Hrvoje Vukčić',
    rank: 133,
    link:
      'https://en.wikipedia.org/wiki/Hrvoje_Vuk%C4%8Di%C4%87_Hrvatini%C4%87',
    group: 'H',
    groupHrv: 'H',
    cv:
      'Grand Duke of Bosnia and Duke of Split (? Kotor, today Kotor Varoš, around 1350 - ?, 1416).',
  },
  I002: {
    id: 'I002',
    count: 10,
    order: 54,
    orderHrv: 52,
    nameHrv: 'Ivakić, Joza',
    linkHrv: 'https://enciklopedija.hr/Natuknica.aspx?ID=28068',
    cvHrv:
      'Hrvatski književnik i kazališni redatelj (Vinkovci, 18. III. 1879 – Zagreb, 6. VIII. 1932).',
    name: 'Ivakić, Joza',
    rank: 178,
    link: 'https://enciklopedija.hr/Natuknica.aspx?ID=28068',
    group: 'I',
    groupHrv: 'I',
    cv:
      'Croatian writer and theater director (Vinkovci, 18 March 1879 - Zagreb, 6 August 1932).',
  },
  I003: {
    id: 'I003',
    count: 10,
    order: 54,
    orderHrv: 52,
    nameHrv: 'Ivanić, Matija',
    linkHrv: 'https://enciklopedija.hr/Natuknica.aspx?ID=28160',
    cvHrv:
      'Vođa hvarskih pučana u ustanku 1510–1514 protiv Mletačke Republike. (Vrbanj, oko 1465 – Rim, 1522/1523).',
    name: 'Ivanić, Matija',
    rank: 178,
    link: 'https://en.wikipedia.org/wiki/Matija_Ivani%C4%87',
    group: 'I',
    groupHrv: 'I',
    cv:
      'Leader of the Hvar Rebellion (1510-1514) against the Venetian Republic. (Vrbanj, around 1465 – Rome, 1522/1523).',
  },
  W001: {
    id: 'W001',
    count: 60,
    order: 62,
    orderHrv: 54.5,
    nameHrv: 'Ivan Pavao II., sv.',
    linkHrv: 'https://enciklopedija.hr/natuknica.aspx?ID=28202',
    cvHrv:
      'Pravo ime Karol Wojtyła, papa od 1978. do 2005. (Wadovice kraj Krakova, 18. V. 1920 – Vatikan, 2. IV. 2005).',
    name: 'John Paul II., St',
    rank: 34,
    link: 'https://en.wikipedia.org/wiki/Pope_John_Paul_II',
    group: 'J',
    groupHrv: 'I',
    cv:
      'Real name Karol Wojtyła, Pope from 1978 to 2005 (Wadovice near Kraków, 18 May 1920 - Vatican, 2 April 2005).',
  },
  I001: {
    id: 'I001',
    count: 12,
    order: 55,
    orderHrv: 55,
    nameHrv: 'Iveković, Oton',
    linkHrv: 'https://enciklopedija.hr/Natuknica.aspx?ID=28225',
    cvHrv: 'Hrvatski slikar (Klanjec, 17. IV. 1869 – Zagreb, 4. VII. 1939).',
    name: 'Iveković, Oton',
    rank: 152,
    link: 'https://en.wikipedia.org/wiki/Oton_Ivekovi%C4%87',
    group: 'I',
    groupHrv: 'I',
    cv: 'Croatian painter (Klanjec, 17 April 1869 - Zagreb, 4 July 1939).',
  },
  J002: {
    id: 'J002',
    count: 13,
    order: 57,
    orderHrv: 56,
    nameHrv: 'Jagić, Vatroslav',
    linkHrv: 'https://enciklopedija.hr/Natuknica.aspx?ID=28503',
    cvHrv: 'Hrvatski slavist (Varaždin, 6. VII. 1838 – Beč, 5. VIII. 1923). ',
    name: 'Jagić, Vatroslav',
    rank: 141,
    link: 'https://en.wikipedia.org/wiki/Vatroslav_Jagi%C4%87',
    group: 'J',
    groupHrv: 'J',
    cv: 'Croatian Slavist (Varaždin, 6 July 1838 - Vienna, 5 August 1923).',
  },
  J001: {
    id: 'J001',
    count: 237,
    order: 58,
    orderHrv: 58,
    nameHrv: 'Jelačić, Josip',
    linkHrv: 'https://enciklopedija.hr/natuknica.aspx?ID=28950',
    cvHrv:
      'Grof, hrvatsko-slavonsko-dalmatinski ban (Petrovaradin, 16. X. 1801 – Zagreb, 20. V. 1859).',
    name: 'Jelačić, Josip',
    rank: 8,
    link: 'https://en.wikipedia.org/wiki/Josip_Jela%C4%8Di%C4%87',
    group: 'J',
    groupHrv: 'J',
    cv:
      'Count, Croatian-Slavonian-Dalmatian ban (viceroy) (Petrovaradin, 16 October 1801 - Zagreb, 20 May 1859).',
  },
  J003: {
    id: 'J003',
    name: 'Jelena',
    count: 27,
    order: 59,
    orderHrv: 59,
    nameHrv: 'Jelena',
    linkHrv: 'https://enciklopedija.hr/Natuknica.aspx?ID=28973',
    cvHrv: 'Hrvatska kraljica (? – ?, 8. X. 976).',
    link: 'https://en.wikipedia.org/wiki/Helen_of_Zadar',
    rank: 73,
    group: 'J',
    groupHrv: 'J',
    cv: 'Croatian queen (? - ?, 8 October 976).',
  },
  J014: {
    id: 'J014',
    count: 10,
    order: 64,
    orderHrv: 62,
    nameHrv: 'Jovanović, Jovan - Zmaj',
    linkHrv: 'https://enciklopedija.hr/natuknica.aspx?id=29387',
    cvHrv:
      'Srpski književnik (Novi Sad, 24. XI. 1833 – Srijemska Kamenica, 3. VI. 1904).',
    name: 'Jovanović, Jovan - Zmaj',
    rank: 178,
    link: 'https://en.wikipedia.org/wiki/Jovan_Jovanovi%C4%87_Zmaj',
    group: 'J',
    groupHrv: 'J',
    cv:
      'Serbian writer (Novi Sad, 24 November 1833 - Srijemska Kamenica, 3 June 1904).',
  },
  J005: {
    id: 'J005',
    count: 14,
    order: 65,
    orderHrv: 63,
    nameHrv: 'Jović, Josip',
    linkHrv: 'https://hr.wikipedia.org/wiki/Josip_Jovi%C4%87_(redarstvenik)',
    cvHrv:
      'Hrvatski redarstvenik, smatran prvom hrvatskom žrtvom u Domovinskom ratu. (Aržano kod Imotskog, 21. studenog 1969. - Plitvice, 31. ožujka 1991.)',
    name: 'Jović, Josip',
    rank: 133,
    link: 'https://en.wikipedia.org/wiki/Josip_Jovi%C4%87',
    group: 'J',
    groupHrv: 'J',
    cv:
      'Police officer considered the first Croatian fatality of the War of Independence. (Aržano near Imotski, 21 November 1969 - Plitvice, 31 March 1991)',
  },
  D007: {
    id: 'D007',
    count: 26,
    order: 66,
    orderHrv: 65,
    nameHrv: 'Juraj Dalmatinac',
    linkHrv: 'https://enciklopedija.hr/Natuknica.aspx?ID=29536',
    cvHrv:
      'Latinizirano Georgius Dalmaticus i Georgius quondam Mathei de Jader, hrvatski kipar i graditelj (Zadar, početak XV. st. – Šibenik, između 1473. i 1475.).',
    name: 'Juraj Dalmatinac',
    rank: 81,
    link: 'https://en.wikipedia.org/wiki/Giorgio_da_Sebenico',
    group: 'J',
    groupHrv: 'J',
    cv:
      'Latin: Georgius Dalmaticus and Georgius quondam Mathei de Jader; Croatian sculptor and builder (Zadar, beginning of the 15th century - Šibenik, between 1473 and 1475).',
  },
  J004: {
    id: 'J004',
    count: 32,
    order: 67,
    orderHrv: 66,
    nameHrv: 'Jurić, Marija - Zagorka',
    linkHrv: 'https://enciklopedija.hr/natuknica.aspx?id=29566',
    cvHrv:
      'Hrvatska novinarka i književnica (Negovec kraj Vrbovca, 2. III. 1873 – Zagreb, 30. XI. 1957).',
    name: 'Jurić, Marija - Zagorka',
    rank: 65,
    link: 'https://en.wikipedia.org/wiki/Marija_Juri%C4%87_Zagorka',
    group: 'J',
    groupHrv: 'J',
    cv:
      'Croatian journalist and writer (Negovec near Vrbovec, 2 March 1873 - Zagreb, 30 November 1957).',
  },
  J007: {
    id: 'J007',
    count: 12,
    order: 68,
    orderHrv: 67,
    nameHrv: 'Jurišić, Nikola',
    linkHrv: 'https://enciklopedija.hr/Natuknica.aspx?ID=29583',
    cvHrv: 'Hrvatski vojskovođa i diplomat (Senj, 1490 – Beč, 1545).',
    name: 'Jurišić, Nikola',
    rank: 152,
    link: 'https://en.wikipedia.org/wiki/Nikola_Juri%C5%A1i%C4%87',
    group: 'J',
    groupHrv: 'J',
    cv: 'Croatian military leader and diplomat (Senj, 1490 - Vienna, 1545).',
  },
  K002: {
    id: 'K002',
    count: 55,
    order: 69,
    orderHrv: 68,
    nameHrv: 'Kačić Miošić, Andrija',
    linkHrv: 'https://enciklopedija.hr/natuknica.aspx?ID=29721',
    cvHrv:
      'Hrvatski pisac i franjevac (Brist kraj Makarske, 1704 – Zaostrog, 15. XII. 1760). ',
    name: 'Kačić Miošić, Andrija',
    rank: 39,
    link:
      'https://en.wikipedia.org/wiki/Andrija_Ka%C4%8Di%C4%87_Mio%C5%A1i%C4%87',
    group: 'K',
    groupHrv: 'K',
    cv:
      'Croatian writer and Franciscan friar (Brist near Makarska, 1704 - Zaostrog, 15 December 1760).',
  },
  K006: {
    id: 'K006',
    count: 41,
    order: 70,
    orderHrv: 69,
    nameHrv: 'Kašić, Bartol',
    linkHrv: 'https://enciklopedija.hr/Natuknica.aspx?ID=30775',
    cvHrv:
      'Hrvatski pisac i jezikoslovac (Pag, 15. VIII. 1575 – Rim, 28. XII. 1650).',
    name: 'Kašić, Bartol',
    rank: 57,
    link: 'https://en.wikipedia.org/wiki/Bartol_Ka%C5%A1i%C4%87',
    group: 'K',
    groupHrv: 'K',
    cv:
      'Croatian writer and linguist (Pag, 15 August 1575 - Rome, 28 December 1650).',
  },
  K025: {
    id: 'K025',
    count: 19,
    order: 71,
    orderHrv: 70,
    nameHrv: 'Kaštelan, Jure',
    linkHrv: 'https://enciklopedija.hr/natuknica.aspx?id=30788',
    cvHrv:
      'Hrvatski književnik (Zakučac kraj Omiša, 18. XII. 1919 – Zagreb, 24. II. 1990). ',
    name: 'Kaštelan, Jure',
    rank: 108,
    link: 'https://en.wikipedia.org/wiki/Jure_Ka%C5%A1telan',
    group: 'K',
    groupHrv: 'K',
    cv:
      'Croatian writer (Zakučac near Omiš, 18 December 1919 - Zagreb, 24 February 1990).',
  },
  K022: {
    id: 'K022',
    count: 13,
    order: 72,
    orderHrv: 71,
    nameHrv: 'Katančić, Matija Petar',
    linkHrv: 'https://enciklopedija.hr/natuknica.aspx?id=30831',
    cvHrv:
      'Hrvatski pjesnik, učenjak i franjevački svećenik (Valpovo, 12. VIII. 1750 – Budim, 23. V. 1825).',
    name: 'Katančić, Matija Petar',
    rank: 141,
    link: 'https://en.wikipedia.org/wiki/Matija_Petar_Katan%C4%8Di%C4%87',
    group: 'K',
    groupHrv: 'K',
    cv:
      'Croatian poet, scholar, and Franciscan priest (Valpovo, 12 August 1750 - Budim, 23 May 1825).',
  },
  K039: {
    id: 'K039',
    count: 10,
    order: 73,
    orderHrv: 73,
    nameHrv: 'Kažotić, Augustin',
    linkHrv: 'https://enciklopedija.hr/natuknica.aspx?id=31057',
    cvHrv:
      'Zagrebački biskup i teolog (Trogir, oko 1260/65 – Lucera, Italija, 3. VIII. 1323).',
    name: 'Kažotić, Augustin',
    rank: 178,
    link: 'https://en.wikipedia.org/wiki/Augustin_Ka%C5%BEoti%C4%87',
    group: 'K',
    groupHrv: 'K',
    cv:
      'Theologian and bishop of Zagreb (Trogir, around 1260/65 - Lucera, Italy, 3 August 1323).',
  },
  K011: {
    id: 'K011',
    count: 12,
    order: 74,
    orderHrv: 74,
    nameHrv: 'Keršovani, Otokar',
    linkHrv: 'https://enciklopedija.hr/Natuknica.aspx?ID=31287',
    cvHrv:
      'Hrvatski novinar, publicist, komunistički intelektualac i aktivist (Trst, 23. II. 1902 – Zagreb, 9. VII. 1941).',
    name: 'Keršovani, Otokar',
    rank: 152,
    link: 'https://en.wikipedia.org/wiki/Otokar_Ker%C5%A1ovani',
    group: 'K',
    groupHrv: 'K',
    cv:
      'Croatian journalist, writer, communist intellectual and activist (Trieste, 23 February 1902 - Zagreb, 9 July 1941).',
  },
  K023: {
    id: 'K023',
    count: 14,
    order: 75,
    orderHrv: 75,
    nameHrv: 'Klaić, Vjekoslav',
    linkHrv: 'https://enciklopedija.hr/Natuknica.aspx?ID=31728',
    cvHrv:
      'Hrvatski povjesničar, književnik i muzikolog (Garčin kraj Slavonskoga Broda, 21. VI. 1849 – Zagreb, 1. VII. 1928).',
    name: 'Klaić, Vjekoslav',
    rank: 133,
    link: 'https://en.wikipedia.org/wiki/Vjekoslav_Klai%C4%87',
    group: 'K',
    groupHrv: 'K',
    cv:
      'Croatian historian, writer and musicologist (Garčin near Slavonski Brod, 21 June 1849 - Zagreb, 1 July 1928).',
  },
  K037: {
    id: 'K037',
    count: 13,
    order: 76,
    orderHrv: 76,
    nameHrv: 'Klović, Julije',
    linkHrv: 'https://enciklopedija.hr/Natuknica.aspx?ID=32010',
    cvHrv:
      'Latinizirano Georgius Julius Croata, hrvatski slikar i minijaturist (Grižane u Vinodolu, 1498 – Rim, 3. ili 5. I. 1578). ',
    name: 'Klović, Julije',
    rank: 141,
    link: 'https://en.wikipedia.org/wiki/Giulio_Clovio',
    group: 'K',
    groupHrv: 'K',
    cv:
      'Latin: Georgius Julius Croata; Croatian painter and miniaturist (Grižane in Vinodol, 1498 - Rome, 3 or 5 January 1578).',
  },
  K014: {
    id: 'K014',
    count: 32,
    order: 77,
    orderHrv: 77,
    nameHrv: 'Kolar, Slavko',
    linkHrv: 'https://enciklopedija.hr/Natuknica.aspx?ID=32350',
    cvHrv:
      'Hrvatski književnik (Palešnik kraj Garešnice, 1. XII. 1891 – Zagreb, 15. IX. 1963).',
    name: 'Kolar, Slavko',
    rank: 65,
    link: 'https://en.wikipedia.org/wiki/Slavko_Kolar',
    group: 'K',
    groupHrv: 'K',
    cv:
      'Croatian writer (Palešnik near Garešnica, 1 December 1891 - Zagreb, 15 September 1963).',
  },
  K012: {
    id: 'K012',
    count: 19,
    order: 78,
    orderHrv: 78,
    nameHrv: 'Končar, Rade',
    linkHrv: 'https://enciklopedija.hr/Natuknica.aspx?ID=32725',
    cvHrv:
      'Hrvatski političar, komunistički aktivist, jedan od vođa antifašističkog pokreta i narodni heroj (Končarev Kraj kraj Plitvičkog Ljeskovca, 28. X. 1911 – Šibenik, 22. V. 1942).',
    name: 'Končar, Rade',
    rank: 108,
    link: 'https://en.wikipedia.org/wiki/Rade_Kon%C4%8Dar',
    group: 'K',
    groupHrv: 'K',
    cv:
      'Croatian politician, communist activist, one of the leaders of anti-fascist resistance, and National Hero (Končarev Kraj near Plitvički Ljeskovac, 28 October 1911 - Šibenik, 22 May 1942).',
  },
  K032: {
    id: 'K032',
    count: 10,
    order: 79,
    orderHrv: 79,
    nameHrv: 'Kosor, Josip',
    linkHrv: 'https://enciklopedija.hr/natuknica.aspx?id=33339',
    cvHrv:
      'Hrvatski književnik (Trbounje kraj Drniša, 27. I. 1879 – Dubrovnik, 23. I. 1961).',
    name: 'Kosor, Josip',
    rank: 178,
    link: 'https://en.wikipedia.org/wiki/Josip_Kosor',
    group: 'K',
    groupHrv: 'K',
    cv:
      'Croatian writer (Trbounje near Drniš, 27 January 1879 - Dubrovnik, 23 January 1961).',
  },
  K028: {
    id: 'K028',
    count: 12,
    order: 80,
    orderHrv: 80,
    nameHrv: 'Kovač, Marko',
    linkHrv:
      'https://sr.wikipedia.org/sr-el/%D0%9C%D0%B0%D1%80%D0%BA%D0%BE_%D0%9A%D0%BE%D0%B2%D0%B0%D1%87_%D0%A8%D0%BD%D0%B0%D1%98%D0%B4%D0%B5%D1%80',
    cvHrv:
      'Komunistički aktivist i narodni heroj (Turčišće kod Čakovca 6. IX. 1910 - Otok kod Preloga 16. VI. 1944).',
    name: 'Kovač, Marko',
    rank: 152,
    link:
      'https://sr.wikipedia.org/sr-el/%D0%9C%D0%B0%D1%80%D0%BA%D0%BE_%D0%9A%D0%BE%D0%B2%D0%B0%D1%87_%D0%A8%D0%BD%D0%B0%D1%98%D0%B4%D0%B5%D1%80',
    group: 'K',
    groupHrv: 'K',
    cv:
      'Communist activist and National Hero (Turčišće near Čakovec, 6 September 1910 - Otok near Prelog, 16 June 1944).',
  },
  K004: {
    id: 'K004',
    count: 50,
    order: 81,
    orderHrv: 81,
    nameHrv: 'Kovačić, Ante',
    linkHrv: 'https://enciklopedija.hr/Natuknica.aspx?ID=33522',
    cvHrv:
      'Hrvatski književnik (Celine kraj Marije Gorice, 6. VI. 1854 – Stenjevec, Zagreb, 10. XII. 1889).',
    name: 'Kovačić, Ante',
    rank: 44,
    link: 'https://en.wikipedia.org/wiki/Ante_Kova%C4%8Di%C4%87',
    group: 'K',
    groupHrv: 'K',
    cv:
      'Croatian writer (Celine near Marija Gorica, 6 June 1854 - Stenjevec, Zagreb, 10 December 1889).',
  },
  K005: {
    id: 'K005',
    count: 116,
    order: 82,
    orderHrv: 82,
    nameHrv: 'Kovačić, Ivan Goran',
    linkHrv: 'https://enciklopedija.hr/Natuknica.aspx?ID=33524',
    cvHrv:
      'Hrvatski književnik (Lukovdol, 21. III. 1913 – okolica Foče, Bosna i Hercegovina, ? 12. VII. 1943).',
    name: 'Kovačić, Ivan Goran',
    rank: 19,
    link: 'https://en.wikipedia.org/wiki/Ivan_Goran_Kova%C4%8Di%C4%87',
    group: 'K',
    groupHrv: 'K',
    cv:
      'Croatian writer (Lukovdol, 21 March 1913 - Foča area, Bosnia and Herzegovina, 12 July 1943).',
  },
  K029: {
    id: 'K029',
    count: 12,
    order: 83,
    orderHrv: 83,
    nameHrv: 'Kozarac, Ivan',
    linkHrv: 'https://enciklopedija.hr/Natuknica.aspx?ID=33564',
    cvHrv:
      'Hrvatski književnik (Vinkovci, 8. II. 1885 – Vinkovci, 16. XI. 1910). ',
    name: 'Kozarac, Ivan',
    rank: 152,
    link: 'https://en.wikipedia.org/wiki/Ivan_Kozarac',
    group: 'K',
    groupHrv: 'K',
    cv:
      'Croatian writer (Vinkovci, 8 February 1885 - Vinkovci, 16 November 1910).',
  },
  K007: {
    id: 'K007',
    count: 112,
    order: 84,
    orderHrv: 84,
    nameHrv: 'Kozarac, Josip',
    linkHrv: 'https://enciklopedija.hr/Natuknica.aspx?ID=33565',
    cvHrv:
      'Hrvatski književnik (Vinkovci, 18. III. 1858 – Koprivnica, 21. VIII. 1906).',
    name: 'Kozarac, Josip',
    rank: 20,
    link: 'https://en.wikipedia.org/wiki/Josip_Kozarac',
    group: 'K',
    groupHrv: 'K',
    cv:
      'Croatian writer (Vinkovci, 18 March 1858 - Koprivnica, 21 August 1906).',
  },
  K015: {
    id: 'K015',
    count: 15,
    order: 85,
    orderHrv: 85,
    nameHrv: 'Kraljević, Miroslav',
    linkHrv: 'https://enciklopedija.hr/Natuknica.aspx?ID=33693',
    cvHrv:
      'Hrvatski slikar i grafičar (Gospić, 14. XII. 1885 – Zagreb, 16. IV. 1913).',
    name: 'Kraljević, Miroslav',
    rank: 128,
    link: 'https://en.wikipedia.org/wiki/Miroslav_Kraljevi%C4%87',
    group: 'K',
    groupHrv: 'K',
    cv:
      'Croatian painter and graphic artist (Gospić, 14 December 1885 - Zagreb, 16 April 1913).',
  },
  K013: {
    id: 'K013',
    count: 62,
    order: 86,
    orderHrv: 86,
    nameHrv: 'Kranjčević, Silvije Strahimir',
    linkHrv: 'https://enciklopedija.hr/Natuknica.aspx?ID=33731',
    cvHrv: 'Hrvatski pjesnik (Senj, 17. II. 1865 – Sarajevo, 29. X. 1908).',
    name: 'Kranjčević, Silvije Strahimir',
    rank: 33,
    link:
      'https://en.wikipedia.org/wiki/Silvije_Strahimir_Kranj%C4%8Devi%C4%87',
    group: 'K',
    groupHrv: 'K',
    cv: 'Croatian poet (Senj, 17 February 1865 - Sarajevo, 29 October 1908).',
  },
  K030: {
    id: 'K030',
    count: 10,
    order: 87,
    orderHrv: 87,
    nameHrv: 'Kraš, Josip',
    linkHrv: 'https://enciklopedija.hr/Natuknica.aspx?ID=33775',
    cvHrv:
      'Hrvatski političar, komunistički aktivist, jedan od vođa antifašističkog pokreta i narodni heroj (Vuglovec kraj Ivanca, 26. III. 1900 – Karlovac, 18. X. 1941). ',
    name: 'Kraš, Josip',
    rank: 178,
    link: 'https://en.wikipedia.org/wiki/Josip_Kra%C5%A1',
    group: 'K',
    groupHrv: 'K',
    cv:
      'Croatian politician, communist activist, one of the leaders of anti-fascist resistance, and National Hero (Vuglovec near Ivanec, March 26, 1900 - Karlovac, October 18, 1941).',
  },
  K031: {
    id: 'K031',
    count: 15,
    order: 88,
    orderHrv: 88,
    nameHrv: 'Križanić, Juraj',
    linkHrv: 'https://enciklopedija.hr/natuknica.aspx?id=34087',
    cvHrv:
      'Hrvatski svećenik, filozof, teolog, jezikoslovac, politički pisac, glazbeni teoretik, polihistor (Obrh kraj Ozlja, 1617. ili 1618 – Beč, 12. IX. 1683).',
    name: 'Križanić, Juraj',
    rank: 128,
    link: 'https://en.wikipedia.org/wiki/Juraj_Kri%C5%BEani%C4%87',
    group: 'K',
    groupHrv: 'K',
    cv:
      'Croatian priest, philosopher, theologian, linguist, political writer, music theorist, polymath (Obrh near Ozalj, 1617 or 1618 - Vienna, 12 September 1683).',
  },
  K024: {
    id: 'K024',
    count: 21,
    order: 89,
    orderHrv: 89,
    nameHrv: 'Krklec, Gustav',
    linkHrv: 'https://enciklopedija.hr/natuknica.aspx?id=34106',
    cvHrv:
      'Hrvatski književnik (Udbinja kraj Karlovca, 23. VI. 1899 – Zagreb, 30. X. 1977). ',
    name: 'Krklec, Gustav',
    rank: 99,
    link: 'https://en.wikipedia.org/wiki/Gustav_Krklec',
    group: 'K',
    groupHrv: 'K',
    cv:
      'Croatian writer (Udbinja near Karlovac, 23 June 1899 - Zagreb, 30 October 1977).',
  },
  K008: {
    id: 'K008',
    count: 97,
    order: 90,
    orderHrv: 90,
    nameHrv: 'Krleža, Miroslav',
    linkHrv: 'https://enciklopedija.hr/Natuknica.aspx?ID=34113',
    cvHrv:
      'Hrvatski književnik (Zagreb, 7. VII. 1893 – Zagreb, 29. XII. 1981). ',
    name: 'Krleža, Miroslav',
    rank: 25,
    link: 'https://en.wikipedia.org/wiki/Miroslav_Krle%C5%BEa',
    group: 'K',
    groupHrv: 'K',
    cv: 'Croatian writer (Zagreb, 7 July 1893 - Zagreb, 29 December 1981).',
  },
  K033: {
    id: 'K033',
    count: 10,
    order: 91,
    orderHrv: 91,
    nameHrv: 'Kršinić, Frano',
    linkHrv: 'https://enciklopedija.hr/natuknica.aspx?id=34237',
    cvHrv: 'Hrvatski kipar (Lumbarda, 24. VII. 1897 – Zagreb, 1. I. 1982)',
    name: 'Kršinić, Frano',
    rank: 178,
    link: 'https://en.wikipedia.org/wiki/Frano_Kr%C5%A1ini%C4%87',
    group: 'K',
    groupHrv: 'K',
    cv: 'Croatian sculptor (Lumbarda, 24 July 1897 - Zagreb, 1 January 1982).',
  },
  K026: {
    id: 'K026',
    count: 13,
    order: 92,
    orderHrv: 92,
    nameHrv: 'Kuhač, Franjo Ksaver',
    linkHrv: 'https://enciklopedija.hr/natuknica.aspx?id=34445',
    cvHrv:
      'Rođen kao Franz Xaver Koch, hrvatski glazbeni pisac i povjesničar, etnomuzikolog i skladatelj (Osijek, 20. XI. 1834 – Zagreb, 18. VI. 1911).',
    name: 'Kuhač, Franjo Ksaver',
    rank: 141,
    link: 'https://en.wikipedia.org/wiki/Franjo_Kuha%C4%8D',
    group: 'K',
    groupHrv: 'K',
    cv:
      'Born as Franz Xaver Koch, Croatian music writer and historian, ethnomusicologist and composer (Osijek, 20 November 1834 - Zagreb, 18 June 1911).',
  },
  K027: {
    id: 'K027',
    count: 19,
    order: 93,
    orderHrv: 93,
    nameHrv: 'Kuharić, Franjo',
    linkHrv: 'https://enciklopedija.hr/Natuknica.aspx?ID=34448',
    cvHrv:
      'Zagrebački nadbiskup i kardinal (Pribić, 15. IV. 1919 – Zagreb, 11. III. 2002).',
    name: 'Kuharić, Franjo',
    rank: 108,
    link: 'https://en.wikipedia.org/wiki/Franjo_Kuhari%C4%87',
    group: 'K',
    groupHrv: 'K',
    cv:
      'Cardinal and Archbishop of Zagreb (Pribić, 15 April 1919 - Zagreb, 11 March 2002).',
  },
  K019: {
    id: 'K019',
    count: 27,
    order: 94,
    orderHrv: 94,
    nameHrv: 'Kukuljević Sakcinski, Ivan',
    linkHrv: 'https://enciklopedija.hr/natuknica.aspx?id=34496i',
    cvHrv:
      'Hrvatski povjesničar, književnik, bibliograf i političar (Varaždin, 29. V. 1816 – Puhakovec, Hrvatsko zagorje, 1. VIII. 1889).',
    name: 'Kukuljević Sakcinski, Ivan',
    rank: 73,
    link: 'https://en.wikipedia.org/wiki/Ivan_Kukuljevi%C4%87_Sakcinski',
    group: 'K',
    groupHrv: 'K',
    cv:
      'Croatian historian, writer, bibliographer and politician (Varaždin, 29 May 1816 - Puhakovec, Hrvatsko zagorje, 1 August 1889).',
  },
  K021: {
    id: 'K021',
    count: 40,
    order: 95,
    orderHrv: 95,
    nameHrv: 'Kumičić, Eugen',
    linkHrv: 'https://enciklopedija.hr/natuknica.aspx?id=34604',
    cvHrv: 'Hrvatski književnik (Brseč, 11. I. 1850 – Zagreb, 13. V. 1904).',
    name: 'Kumičić, Eugen',
    rank: 59,
    link: 'https://en.wikipedia.org/wiki/Eugen_Kumi%C4%8Di%C4%87',
    group: 'K',
    groupHrv: 'K',
    cv: 'Croatian writer (Brseč, 11 January 1850 - Zagreb, 13 May 1904).',
  },
  K009: {
    id: 'K009',
    count: 65,
    order: 96,
    orderHrv: 96,
    nameHrv: 'Kvaternik, Eugen',
    linkHrv: 'https://enciklopedija.hr/Natuknica.aspx?ID=34898',
    cvHrv: 'Hrvatski političar (Zagreb, 31. X. 1825 – Rakovica, 11. X. 1871). ',
    name: 'Kvaternik, Eugen',
    rank: 32,
    link: 'https://en.wikipedia.org/wiki/Eugen_Kvaternik',
    group: 'K',
    groupHrv: 'K',
    cv:
      'Croatian politician (Zagreb, 31 October 1825 - Rakovica, 11 October 1871).',
  },
  L006: {
    id: 'L006',
    count: 23,
    order: 97,
    orderHrv: 97,
    nameHrv: 'Laginja, Matko',
    linkHrv: 'https://enciklopedija.hr/natuknica.aspx?id=35110',
    cvHrv:
      'Hrvatski političar (Klana kraj Kastva, 10. VIII. 1852 – Zagreb, 18. III. 1930).',
    name: 'Laginja, Matko',
    rank: 92,
    link: 'https://en.wikipedia.org/wiki/Matko_Laginja',
    group: 'L',
    groupHrv: 'L',
    cv:
      'Croatian politician (Klana near Kastav, 10 August 1852 - Zagreb, 18 March 1930).',
  },
  L002: {
    id: 'L002',
    count: 51,
    order: 99,
    orderHrv: 98,
    nameHrv: 'Lisinski, Vatroslav',
    linkHrv: 'https://enciklopedija.hr/natuknica.aspx?id=36770',
    cvHrv: 'Hrvatski skladatelj (Zagreb, 8. VII. 1819 – Zagreb, 31. V. 1854).',
    name: 'Lisinski, Vatroslav',
    rank: 42,
    link: 'https://en.wikipedia.org/wiki/Vatroslav_Lisinski',
    group: 'L',
    groupHrv: 'L',
    cv: 'Croatian composer (Zagreb, 8 July 1819 - Zagreb, 31 May 1854).',
  },
  L003: {
    id: 'L003',
    count: 14,
    order: 100,
    orderHrv: 99,
    nameHrv: 'Livadić, Ferdo',
    linkHrv: 'https://enciklopedija.hr/Natuknica.aspx?ID=36870',
    cvHrv:
      'Rođen kao Ferdinand Wiesner, hrvatski skladatelj slovensko-austrijskoga podrijetla (Celje, Slovenija, 30. V. 1799 – Samobor, 8. I. 1879).',
    name: 'Livadić, Ferdo',
    rank: 133,
    link: 'https://en.wikipedia.org/wiki/Ferdo_Livadi%C4%87',
    group: 'L',
    groupHrv: 'L',
    cv:
      'Born as Ferdinand Wiesner, Croatian composer of Slovene-Austrian origin (Celje, Slovenia, 30 May 1799 - Samobor, 8 January 1879).',
  },
  L005: {
    id: 'L005',
    count: 30,
    order: 101,
    orderHrv: 104,
    nameHrv: 'Ljudevit Posavski',
    linkHrv: 'https://enciklopedija.hr/natuknica.aspx?id=37767',
    cvHrv: 'Knez Panonske Hrvatske (? – ?, 823).',
    name: 'Ljudevit',
    rank: 68,
    link: 'https://en.wikipedia.org/wiki/Ljudevit',
    group: 'L',
    groupHrv: 'L',
    cv: 'Duke of Pannonian Croatia (? - ?, 823).',
  },
  L004: {
    id: 'L004',
    count: 25,
    order: 102,
    orderHrv: 100,
    nameHrv: 'Lovrak, Mato',
    linkHrv: 'https://enciklopedija.hr/Natuknica.aspx?ID=37266',
    cvHrv:
      'Hrvatski književnik (Veliki Grđevac, 8. III. 1899 – Zagreb, 13. III. 1974).',
    name: 'Lovrak, Mato',
    rank: 86,
    link: 'https://en.wikipedia.org/wiki/Mato_Lovrak',
    group: 'L',
    groupHrv: 'L',
    cv:
      'Croatian writer (Veliki Grđevac, 8 March 1899 - Zagreb, 13 March 1974).',
  },
  L007: {
    id: 'L007',
    count: 14,
    order: 103,
    orderHrv: 102,
    nameHrv: 'Lucić, Hanibal',
    linkHrv: 'https://enciklopedija.hr/Natuknica.aspx?ID=37369',
    cvHrv: 'Hrvatski književnik (Hvar, 1485 – Hvar, 1553).',
    name: 'Lucić, Hanibal',
    rank: 133,
    link: 'https://en.wikipedia.org/wiki/Hanibal_Luci%C4%87',
    group: 'L',
    groupHrv: 'L',
    cv: 'Croatian writer (Hvar, 1485 - Hvar, 1553).',
  },
  M011: {
    id: 'M011',
    count: 27,
    order: 105,
    orderHrv: 105,
    nameHrv: 'Maček, Vladko',
    linkHrv: 'https://enciklopedija.hr/Natuknica.aspx?ID=37893',
    cvHrv:
      'Hrvatski političar, jedan od vođa Hrvatske seljačke stranke (Jastrebarsko, 20. VII. 1879 – Washington, SAD 15. V. 1964).',
    name: 'Maček, Vladko',
    rank: 73,
    link: 'https://en.wikipedia.org/wiki/Vladko_Ma%C4%8Dek',
    group: 'M',
    groupHrv: 'M',
    cv:
      'Croatian politician, one of the leaders of the Croatian Peasant Party (Jastrebarsko, 20 July 1879 - Washington, DC, 15 May 1964).',
  },
  M009: {
    id: 'M009',
    count: 21,
    order: 106,
    orderHrv: 106,
    nameHrv: 'Mandić, Leopold, sv.',
    linkHrv: 'https://enciklopedija.hr/natuknica.aspx?id=38573',
    cvHrv:
      'Svećenik, kapucin i svetac (Herceg Novi, 12. V. 1866 – Padova, 30. VII. 1942).',
    name: 'Mandić, Leopold, St.',
    rank: 99,
    link: 'https://en.wikipedia.org/wiki/Leopold_Mandi%C4%87',
    group: 'M',
    groupHrv: 'M',
    cv:
      'Priest, Capuchin friar, and saint (Herceg Novi, 12 May 1866 - Padua, 30 July 1942).',
  },
  M010: {
    id: 'M010',
    count: 10,
    order: 108,
    orderHrv: 109,
    nameHrv: 'Martić, Grgo',
    linkHrv: 'https://enciklopedija.hr/natuknica.aspx?id=39137',
    cvHrv:
      'Hrvatski književnik i franjevački svećenik (Rastovača kraj Posušja, BiH, 24. I. 1822 – Kreševo, BiH, 30. VIII. 1905). ',
    name: 'Martić, Grgo',
    rank: 178,
    link: 'https://en.wikipedia.org/wiki/Grgo_Marti%C4%87',
    group: 'M',
    groupHrv: 'M',
    cv:
      'Croatian writer and Franciscan friar (Rastovača near Posušje, Bosnia and Herzegovina, 24 January 1822 - Kreševo, Bosnia and Herzegovina, 30 August 1905).',
  },
  M008: {
    id: 'M008',
    count: 72,
    order: 110,
    orderHrv: 111,
    nameHrv: 'Marulić, Marko',
    linkHrv: 'https://enciklopedija.hr/Natuknica.aspx?ID=39221',
    cvHrv: 'Hrvatski književnik (Split, 18. VIII. 1450 – Split, 5. I. 1524).',
    name: 'Marulić, Marko',
    rank: 31,
    link: 'https://en.wikipedia.org/wiki/Marko_Maruli%C4%87',
    group: 'M',
    groupHrv: 'M',
    cv: 'Croatian writer (Split, 18 August 1450 - Split, 5 January 1524).',
  },
  M013: {
    id: 'M013',
    count: 12,
    order: 112,
    orderHrv: 112,
    nameHrv: 'Matačić, Lovro',
    linkHrv: 'https://enciklopedija.hr/natuknica.aspx?id=39362',
    cvHrv:
      'Hrvatski dirigent i skladatelj (Sušak, 14. II. 1899 – Zagreb, 4. I. 1985).',
    name: 'Matačić, Lovro',
    rank: 152,
    link: 'https://en.wikipedia.org/wiki/Lovro_von_Mata%C4%8Di%C4%87',
    group: 'M',
    groupHrv: 'M',
    cv:
      'Croatian conductor and composer (Sušak, 14. II. 1899 – Zagreb, 4. I. 1985).',
  },
  M018: {
    id: 'M018',
    count: 11,
    order: 113,
    orderHrv: 113,
    nameHrv: 'Matetić Ronjgov, Ivan',
    linkHrv: 'https://enciklopedija.hr/natuknica.aspx?id=39419',
    cvHrv:
      'Hrvatski skladatelj i melograf (Ronjgi kraj Viškova, 10. IV. 1880 – Lovran, 27. VI. 1960).',
    name: 'Matetić Ronjgov, Ivan',
    rank: 169,
    link: 'https://en.wikipedia.org/wiki/Ivan_Mateti%C4%87_Ronjgov',
    group: 'M',
    groupHrv: 'M',
    cv:
      'Croatian composer and melographer (Ronjgi near Viškovo, 10 April 1880 - Lovran, 27 June 1960).',
  },
  M001: {
    id: 'M001',
    count: 125,
    order: 114,
    orderHrv: 114,
    nameHrv: 'Matoš, Antun Gustav',
    linkHrv: 'https://enciklopedija.hr/Natuknica.aspx?ID=39480',
    cvHrv:
      'Hrvatski književnik (Tovarnik, 13. VI. 1873 – Zagreb, 17. III. 1914).',
    name: 'Matoš, Antun Gustav',
    rank: 16,
    link: 'https://en.wikipedia.org/wiki/Antun_Gustav_Mato%C5%A1',
    group: 'M',
    groupHrv: 'M',
    cv: 'Croatian writer (Tovarnik, 13 June 1873 - Zagreb, 17 March 1914).',
  },
  M006: {
    id: 'M006',
    count: 101,
    order: 115,
    orderHrv: 115,
    nameHrv: 'Mažuranić, Ivan',
    linkHrv: 'https://enciklopedija.hr/Natuknica.aspx?ID=39660',
    cvHrv:
      'Hrvatski književnik, političar i pravnik (Novi Vinodolski, 11. VIII. 1814 – Zagreb, 4. VIII. 1890).',
    name: 'Mažuranić, Ivan',
    rank: 24,
    link: 'https://en.wikipedia.org/wiki/Ivan_Ma%C5%BEurani%C4%87',
    group: 'M',
    groupHrv: 'M',
    cv:
      'Croatian writer, politician and lawyer (Novi Vinodolski, 11 August 1814 - Zagreb, 4 August 1890).',
  },
  M015: {
    id: 'M015',
    count: 12,
    order: 116,
    orderHrv: 116,
    nameHrv: 'Medović, Celestin Mato',
    linkHrv: 'https://enciklopedija.hr/natuknica.aspx?id=39782',
    cvHrv:
      'Hrvatski slikar (Kuna Pelješka, 17. XI. 1857 – Sarajevo, 20. I. 1920).',
    name: 'Medović, Celestin Mato',
    rank: 152,
    link: 'https://enciklopedija.hr/natuknica.aspx?id=39782',
    group: 'M',
    groupHrv: 'M',
    cv:
      'Croatian painter (Kuna Pelješka, 17 November 1857 - Sarajevo, 20 January 1920).',
  },
  M007: {
    id: 'M007',
    count: 75,
    order: 117,
    orderHrv: 117,
    nameHrv: 'Meštrović, Ivan',
    linkHrv: 'https://enciklopedija.hr/Natuknica.aspx?ID=40326',
    cvHrv:
      'Hrvatski kipar i arhitekt (Vrpolje u Slavoniji, 15. VIII. 1883 – South Bend, SAD, 16. I. 1962).',
    name: 'Meštrović, Ivan',
    rank: 28,
    link: 'https://en.wikipedia.org/wiki/Ivan_Me%C5%A1trovi%C4%87',
    group: 'M',
    groupHrv: 'M',
    cv:
      'Croatian sculptor and architect (Vrpolje in Slavonia, 15 August 1883 - South Bend, USA, 16 January 1962).',
  },
  M004: {
    id: 'M004',
    count: 131,
    order: 118,
    orderHrv: 118,
    nameHrv: 'Mihanović, Antun',
    linkHrv: 'https://enciklopedija.hr/Natuknica.aspx?ID=40662',
    cvHrv:
      'Hrvatski pisac i političar (Zagreb, 10. VI. 1796 – Novi Dvori kraj Klanjca, 14. XI. 1861).',
    name: 'Mihanović, Antun',
    rank: 15,
    link: 'https://en.wikipedia.org/wiki/Antun_Mihanovi%C4%87',
    group: 'M',
    groupHrv: 'M',
    cv:
      'Croatian writer and politician (Zagreb, 10 June 1796 - Novi Dvori near Klanjec, 14 November 1861).',
  },
  M014: {
    id: 'M014',
    count: 11,
    order: 119,
    orderHrv: 119,
    nameHrv: 'Milanović, Božo',
    linkHrv: 'https://enciklopedija.hr/natuknica.aspx?id=40818',
    cvHrv:
      'Hrvatski svećenik, teolog i političar (Kringa kraj Pazina, 10. X. 1890 – Pazin, 28. XII. 1980).',
    name: 'Milanović, Božo',
    rank: 169,
    link: 'https://hr.wikipedia.org/wiki/Bo%C5%BEo_Milanovi%C4%87',
    group: 'M',
    groupHrv: 'M',
    cv:
      'Croatian priest, theologian and politician (Kringa near Pazin, 10 October 1890 - Pazin, 28 December 1980).',
  },
  M012: {
    id: 'M012',
    count: 21,
    order: 120,
    orderHrv: 120,
    nameHrv: 'Mislav',
    linkHrv: 'https://enciklopedija.hr/Natuknica.aspx?ID=41191	Mislav',
    cvHrv:
      'Muisclavus, Mislauus; hrvatski knez od približno 835. do približno 845.',
    name: 'Mislav',
    rank: 99,
    link: 'https://en.wikipedia.org/wiki/Mislav_of_Croatia',
    group: 'M',
    groupHrv: 'M',
    cv: 'Muisclavus, Mislauus; Croatian duke from about 835 to about 845.',
  },
  M005: {
    id: 'M005',
    count: 12,
    order: 121,
    orderHrv: 121,
    nameHrv: 'Mohorovičić, Andrija',
    linkHrv: 'https://enciklopedija.hr/Natuknica.aspx?ID=41535',
    cvHrv:
      'Hrvatski geofizičar (Volosko, 23. I. 1857 – Zagreb, 18. XII. 1936).',
    name: 'Mohorovičić, Andrija',
    rank: 152,
    link: 'https://en.wikipedia.org/wiki/Andrija_Mohorovi%C4%8Di%C4%87',
    group: 'M',
    groupHrv: 'M',
    cv:
      'Croatian geophysicist (Volosko, 23 January 1857 - Zagreb, 18 December 1936).',
  },
  N001: {
    id: 'N001',
    count: 322,
    order: 122,
    orderHrv: 122,
    nameHrv: 'Nazor, Vladimir',
    linkHrv: 'https://enciklopedija.hr/natuknica.aspx?id=43168',
    cvHrv:
      'Hrvatski književnik (Postira na Braču, 30. V. 1876 – Zagreb, 19. VI. 1949).',
    name: 'Nazor, Vladimir',
    rank: 3,
    link: 'https://en.wikipedia.org/wiki/Vladimir_Nazor',
    group: 'N',
    groupHrv: 'N',
    cv:
      'Croatian writer (Postira on Brač, 30 May 1876 - Zagreb, 19 June 1949).',
  },
  N002: {
    id: 'N002',
    count: 24,
    order: 124,
    orderHrv: 124,
    nameHrv: 'Novak, Vjenceslav',
    linkHrv: 'https://enciklopedija.hr/natuknica.aspx?id=44224',
    cvHrv:
      'Hrvatski književnik, glazbeni pisac i skladatelj (Senj, 11. IX. 1859 – Zagreb, 20. IX. 1905).',
    name: 'Novak, Vjenceslav',
    rank: 89,
    link: 'https://en.wikipedia.org/wiki/Vjenceslav_Novak',
    group: 'N',
    groupHrv: 'N',
    cv:
      'Croatian writer, musicologist, and composer (Senj, 11 September 1859 - Zagreb, 20 September 1905).',
  },
  P014: {
    id: 'P014',
    count: 10,
    order: 125,
    orderHrv: 125,
    nameHrv: 'Palmotić, Junije',
    linkHrv: 'https://enciklopedija.hr/Natuknica.aspx?ID=46329',
    cvHrv:
      'Hrvatski književnik (Dubrovnik, 7. XI. 1607 – Dubrovnik, između 29. VI. i 6. VII. 1657).',
    name: 'Palmotić, Junije',
    rank: 178,
    link: 'https://en.wikipedia.org/wiki/Mihovil_Pavlinovi%C4%87',
    group: 'P',
    groupHrv: 'P',
    cv:
      'Croatian writer (Dubrovnik, 7 November 1607 - Dubrovnik, between 29 June and 6 July 1657).',
  },
  P010: {
    id: 'P010',
    count: 43,
    order: 126,
    orderHrv: 126,
    nameHrv: 'Pavlek, Mihovil - Miškina',
    linkHrv: 'https://enciklopedija.hr/natuknica.aspx?id=47139',
    cvHrv:
      'Hrvatski književnik i političar Hrvatske seljačke stranke (Đelekovec, 24. IX. 1887 – Stara Gradiška, 11. VI. 1942).',
    name: 'Pavlek, Mihovil - Miškina',
    rank: 55,
    link: 'https://en.wikipedia.org/wiki/Mihovil_Pavlek_Mi%C5%A1kina',
    group: 'P',
    groupHrv: 'P',
    cv:
      'Croatian writer and politician of the Croatian Peasant Party (Đelekovec, 24 September 1887 - Stara Gradiška, 11 June 1942).',
  },
  P016: {
    id: 'P016',
    count: 17,
    order: 127,
    orderHrv: 127,
    nameHrv: 'Pavlinović, Mihovil',
    linkHrv: 'https://enciklopedija.hr/Natuknica.aspx?ID=47155',
    cvHrv:
      'Hrvatski političar, književnik i svećenik (Podgora, 28. I. 1831 – Podgora, 18. V. 1887).',
    name: 'Pavlinović, Mihovil',
    rank: 118,
    link: 'https://en.wikipedia.org/wiki/Mihovil_Pavlinovi%C4%87',
    group: 'P',
    groupHrv: 'P',
    cv:
      'Croatian politician, writer, and priest (Podgora, 28 January 1831 - Podgora, 18 May 1887).',
  },
  P001: {
    id: 'P001',
    count: 20,
    order: 128,
    orderHrv: 128,
    nameHrv: 'Pejačević, Dora',
    linkHrv: 'https://enciklopedija.hr/natuknica.aspx?id=47324',
    cvHrv:
      'Hrvatska skladateljica (Budimpešta, 10. IX. 1885 – München, 5. III. 1923).',
    name: 'Pejačević, Dora',
    rank: 103,
    link: 'https://en.wikipedia.org/wiki/Dora_Peja%C4%8Devi%C4%87',
    group: 'P',
    groupHrv: 'P',
    cv:
      'Croatian composer (Budapest, 10 September 1885 - Munich, 5 March 1923).',
  },
  P004: {
    id: 'P004',
    count: 109,
    order: 129,
    orderHrv: 129,
    nameHrv: 'Petar Krešimir IV.',
    linkHrv: 'https://enciklopedija.hr/Natuknica.aspx?ID=47856',
    cvHrv: 'Hrvatski kralj približno od 1058. do 1074.',
    name: 'Petar Krešimir IV.',
    rank: 22,
    link: 'https://en.wikipedia.org/wiki/Peter_Kre%C5%A1imir_IV_of_Croatia',
    group: 'P',
    groupHrv: 'P',
    cv: 'Croatian king from about 1058 to 1074.',
  },
  P005: {
    id: 'P005',
    count: 73,
    order: 130,
    orderHrv: 130,
    nameHrv: 'Petar Svačić',
    linkHrv: 'https://enciklopedija.hr/Natuknica.aspx?ID=47840',
    cvHrv: 'Hrvatski vladar (? – ?, 1097).',
    name: 'Petar Svačić',
    rank: 30,
    link: 'https://en.wikipedia.org/wiki/Petar_Sna%C4%8Di%C4%87',
    group: 'P',
    groupHrv: 'P',
    cv: 'Croatian ruler (? - ?, 1097).',
  },
  P008: {
    id: 'P008',
    count: 22,
    order: 132,
    orderHrv: 132,
    nameHrv: 'Petőfi, Sándor',
    linkHrv: 'https://enciklopedija.hr/Natuknica.aspx?ID=47901',
    cvHrv:
      'Rođen kao Alexander Petrovics, mađarski pjesnik (Kiskőrös, 1. I. 1823 – Fehéregyháza, 31. VII. 1849). ',
    name: 'Petőfi, Sándor',
    rank: 94,
    link: 'https://en.wikipedia.org/wiki/S%C3%A1ndor_Pet%C5%91fi',
    group: 'P',
    groupHrv: 'P',
    cv:
      'Born as Alexander Petrovics, Hungarian poet (Kiskőrös, 1 January 1823 - Fehéregyháza, 31 July 1849).',
  },
  P002: {
    id: 'P002',
    count: 103,
    order: 133,
    orderHrv: 133,
    nameHrv: 'Preradović, Petar',
    linkHrv: 'https://enciklopedija.hr/Natuknica.aspx?ID=50197',
    cvHrv:
      'Hrvatski pjesnik, prevoditelj i general (Grabrovnica kraj Pitomače, 19. III. 1818 – Fahrafeld, Austrija, 18. VIII. 1872).',
    name: 'Preradović, Petar',
    rank: 23,
    link: 'https://en.wikipedia.org/wiki/Petar_Preradovi%C4%87',
    group: 'P',
    groupHrv: 'P',
    cv:
      'Croatian poet, translator, and military general (Grabrovnica near Pitomača, 19 March 1818 - Fahrafeld, Austria, 18 August 1872).',
  },
  P013: {
    id: 'P013',
    count: 11,
    order: 134,
    orderHrv: 134,
    nameHrv: 'Prešeren, France',
    linkHrv: 'https://enciklopedija.hr/natuknica.aspx?id=50228',
    cvHrv:
      'Slovenski pjesnik (Vrba kraj Bleda, 3. XII. 1800 – Kranj, 8. II. 1849).',
    name: 'Prešeren, France',
    rank: 169,
    link: 'https://en.wikipedia.org/wiki/France_Pre%C5%A1eren',
    group: 'P',
    groupHrv: 'P',
    cv:
      'Slovenian poet (Vrba near Bled, 3 December 1800 - Kranj, 8 February 1849).',
  },
  P007: {
    id: 'P007',
    count: 14,
    order: 135,
    orderHrv: 135,
    nameHrv: 'Pupačić, Josip',
    linkHrv: 'https://enciklopedija.hr/Natuknica.aspx?ID=51117',
    cvHrv:
      'Hrvatski pjesnik (Slime kraj Omiša, 19. IX. 1928 – otok Krk, 23. V. 1971).',
    name: 'Pupačić, Josip',
    rank: 133,
    link: 'https://enciklopedija.hr/Natuknica.aspx?ID=51117',
    group: 'P',
    groupHrv: 'P',
    cv:
      'Croatian poet (Slime near Omiš, 19 September 1928 - the island of Krk, 23 May 1971).',
  },
  R014: {
    id: 'R014',
    count: 10,
    order: 136,
    orderHrv: 136,
    nameHrv: 'Račić, Josip',
    linkHrv: 'https://enciklopedija.hr/Natuknica.aspx?ID=51383',
    cvHrv: 'Hrvatski slikar (Zagreb, 22. III. 1885 – Pariz, 19. VI. 1908).',
    name: 'Račić, Josip',
    rank: 178,
    link: 'https://en.wikipedia.org/wiki/Josip_Ra%C4%8Di%C4%87',
    group: 'R',
    groupHrv: 'R',
    cv: 'Croatian painter (Zagreb, 22 March 1885 - Paris, 19 June 1908).',
  },
  R012: {
    id: 'R012',
    count: 20,
    order: 137,
    orderHrv: 137,
    nameHrv: 'Rački, Franjo',
    linkHrv: 'https://enciklopedija.hr/Natuknica.aspx?ID=51388',
    cvHrv:
      'Hrvatski povjesničar, jezikoslovac, publicist i političar (Fužine, 25. XI. 1828 – Zagreb, 13. II. 1894). ',
    name: 'Rački, Franjo',
    rank: 103,
    link: 'https://en.wikipedia.org/wiki/Franjo_Ra%C4%8Dki',
    group: 'R',
    groupHrv: 'R',
    cv:
      'Croatian historian, linguist, writer, and politician (Fužine, 25 November 1828 - Zagreb, 13 February 1894).',
  },
  R009: {
    id: 'R009',
    count: 10,
    order: 138,
    orderHrv: 138,
    nameHrv: 'Radauš, Vanja',
    linkHrv: 'https://enciklopedija.hr/Natuknica.aspx?ID=51410',
    cvHrv: 'Hrvatski kipar (Vinkovci, 29. IV. 1906 – Zagreb, 24. IV. 1975).',
    name: 'Radauš, Vanja',
    rank: 178,
    link: 'https://en.wikipedia.org/wiki/Vanja_Radau%C5%A1',
    group: 'R',
    groupHrv: 'R',
    cv: 'Croatian sculptor (Vinkovci, 29 April 1906 - Zagreb, 24 April 1975).',
  },
  R003: {
    id: 'R003',
    count: 261,
    order: 140,
    orderHrv: 140,
    nameHrv: 'Radić, Antun',
    linkHrv: 'https://enciklopedija.hr/natuknica.aspx?id=51435',
    cvHrv:
      'Hrvatski etnolog, političar i publicist, jedan od osnivača i vođa Hrvatske seljačke stranke (Trebarjevo Desno, 11. VI. 1868 – Zagreb, 10. II. 1919).',
    name: 'Radić, Antun',
    rank: 5,
    link: 'https://en.wikipedia.org/wiki/Antun_Radi%C4%87',
    group: 'R',
    groupHrv: 'R',
    cv:
      'Croatian ethnologist, politician and writer, one of the founders and leaders of the Croatian Peasant Party (Trebarjevo Desno, 11 June 1868 - Zagreb, 10 February 1919).',
  },
  R004: {
    id: 'R004',
    count: 22,
    order: 141,
    orderHrv: 141,
    nameHrv: 'Radić, Pavle',
    linkHrv: 'https://enciklopedija.hr/natuknica.aspx?id=51443',
    cvHrv:
      'Hrvatski političar, član vodstva Hrvatske seljačke stranke. (Trebarjevo Desno, 10. I. 1880 – Beograd, 20. VI. 1928). ',
    name: 'Radić, Pavle',
    rank: 94,
    link: 'https://en.wikipedia.org/wiki/Pavle_Radi%C4%87',
    group: 'R',
    groupHrv: 'R',
    cv:
      'Croatian politician, member of the leadership of the Croatian Peasant Party (Trebarjevo Desno, 10 January 1880 - Belgrade, 20 June 1928).',
  },
  R002: {
    id: 'R002',
    count: 565,
    order: 142,
    orderHrv: 142,
    nameHrv: 'Radić, Stjepan',
    linkHrv: 'https://enciklopedija.hr/Natuknica.aspx?ID=51444',
    cvHrv:
      'Hrvatski političar i publicist, jedan od osnivača i vođa Hrvatske seljačke stranke (Trebarjevo Desno, 11. VI. 1871 – Zagreb, 8. VIII. 1928).',
    name: 'Radić, Stjepan',
    rank: 1,
    link: 'https://en.wikipedia.org/wiki/Stjepan_Radi%C4%87',
    group: 'R',
    groupHrv: 'R',
    cv:
      'Croatian politician and writer, one of the founders and leaders of the Croatian Peasant Party. (Trebarjevo Desno, 11 June 1871 - Zagreb, 8 August 1928). ',
  },
  R007: {
    id: 'R007',
    count: 14,
    order: 139,
    orderHrv: 139,
    nameHrv: 'Radičević, Branko',
    linkHrv: 'https://enciklopedija.hr/Natuknica.aspx?ID=51433',
    cvHrv:
      'Srpski književnik (Slavonski Brod, 27. III. 1824 – Beč, 30. VI. 1853). ',
    name: 'Radičević, Branko',
    rank: 133,
    link: 'https://en.wikipedia.org/wiki/Branko_Radi%C4%8Devi%C4%87',
    group: 'R',
    groupHrv: 'R',
    cv:
      'Serbian writer (Slavonski Brod, 27 March 1824 - Vienna, 30 June 1853).',
  },
  R017: {
    id: 'R017',
    count: 11,
    order: 143,
    orderHrv: 143,
    nameHrv: 'Rakovac, Joakim',
    linkHrv: 'https://istrapedia.hr/hr/natuknice/889/rakovac-joakim',
    cvHrv:
      'Komunistički aktivist, jedan od vođa antifašističkog pokreta otpora u Istri i narodni heroj (Rakovci kraj Baderne, 14.X.1914. – Križnjak kraj Korenići iznad Limske drage, 18.I.1945).',
    name: 'Rakovac, Joakim',
    rank: 169,
    link: 'https://istrapedia.hr/hr/natuknice/889/rakovac-joakim',
    group: 'R',
    groupHrv: 'R',
    cv:
      'Communist activist, one of the leaders of anti-fascist resistance in Istria, National Hero (Rakovci near Baderna, 14 October 1914 - Križnjak near Korenići above Limska draga, 18 January 1945)',
  },
  R008: {
    id: 'R008',
    count: 17,
    order: 144,
    orderHrv: 144,
    nameHrv: 'Raškaj, Slava',
    linkHrv: 'https://enciklopedija.hr/Natuknica.aspx?ID=51910',
    cvHrv:
      'Hrvatska slikarica (Ozalj, 2. I. 1877 – Stenjevec, Zagreb, 29. III. 1906).',
    name: 'Raškaj, Slava',
    rank: 118,
    link: 'https://en.wikipedia.org/wiki/Slava_Ra%C5%A1kaj',
    group: 'R',
    groupHrv: 'R',
    cv:
      'Croatian painter (Ozalj, 2 January 1877 - Stenjevec, Zagreb, 29 March 1906).',
  },
  R001: {
    id: 'R001',
    count: 49,
    order: 145,
    orderHrv: 145,
    nameHrv: 'Relković, Matija Antun',
    linkHrv: 'https://enciklopedija.hr/Natuknica.aspx?ID=52390',
    cvHrv:
      'Hrvatski književnik, gramatičar i prevoditelj (Svinjar, danas Davor, 6. I. 1732 – Vinkovci, 22. I. 1798).',
    name: 'Relković, Matija Antun',
    rank: 46,
    link: 'https://en.wikipedia.org/wiki/Matija_Antun_Relkovi%C4%87',
    group: 'R',
    groupHrv: 'R',
    cv:
      'Croatian writer, grammarian and translator (Svinjar, today Davor, 6 January 1732 - Vinkovci, 22 January 1798).',
  },
  R013: {
    id: 'R013',
    count: 17,
    order: 146,
    orderHrv: 146,
    nameHrv: 'Rendić, Ivan',
    linkHrv: 'https://enciklopedija.hr/Natuknica.aspx?ID=52441',
    cvHrv: 'Hrvatski kipar (Imotski, 27. VIII. 1849 – Split, 29. VI. 1932).',
    name: 'Rendić, Ivan',
    rank: 118,
    link: 'https://en.wikipedia.org/wiki/Ivan_Rendi%C4%87',
    group: 'R',
    groupHrv: 'R',
    cv: 'Croatian sculptor (Imotski, 27 August 1849 - Split, 29 June 1932).',
  },
  R005: {
    id: 'R005',
    count: 41,
    order: 147,
    orderHrv: 147,
    nameHrv: 'Ribar, Ivo Lola',
    linkHrv: 'https://enciklopedija.hr/Natuknica.aspx?ID=52712',
    cvHrv:
      'Hrvatski političar, komunistički aktivist jedan od vođa antifašističkog pokreta otpora i narodni heroj (Zagreb, 23. IV. 1916 – Glamočko polje, 27. XI. 1943).',
    name: 'Ribar, Ivo Lola',
    rank: 57,
    link: 'https://en.wikipedia.org/wiki/Ivo_Lola_Ribar',
    group: 'R',
    groupHrv: 'R',
    cv:
      'Croatian politician, communist activist, one of the leaders of anti-fascist resistance, and National Hero (Zagreb, 23 April 1916 - Glamočko polje, 27 November 1943).',
  },
  R010: {
    id: 'R010',
    count: 25,
    order: 149,
    orderHrv: 149,
    nameHrv: 'Runjanin, Josip',
    linkHrv: 'https://enciklopedija.hr/Natuknica.aspx?ID=53730',
    cvHrv:
      'Hrvatski skladatelj, autor glazbe hrvatske himne (Vinkovci, 8. XII. 1821 – Novi Sad, 2. II. 1878).',
    name: 'Runjanin, Josip',
    rank: 86,
    link: 'https://en.wikipedia.org/wiki/Josip_Runjanin',
    group: 'R',
    groupHrv: 'R',
    cv:
      'Croatian composer, author of music of the Croatian national anthem (Vinkovci, 8 December 1821 - Novi Sad, 2 February 1878).',
  },
  R011: {
    id: 'R011',
    count: 19,
    order: 150,
    orderHrv: 150,
    nameHrv: 'Ružička, Lavoslav',
    linkHrv: 'https://enciklopedija.hr/Natuknica.aspx?ID=53820',
    cvHrv:
      'Švicarski kemičar hrvatskog podrijetla (Vukovar, 13. IX. 1887 – Mammern na Bodenskom jezeru, 26. IX. 1976). ',
    name: 'Ružička, Lavoslav',
    rank: 108,
    link: 'https://en.wikipedia.org/wiki/Leopold_Ru%C5%BEi%C4%8Dka',
    group: 'R',
    groupHrv: 'R',
    cv:
      'Swiss chemist of Croatian origin (Vukovar, 13 September 1887 - Mammern on Lake Constance, 26 September 1976).',
  },
  Š003: {
    id: 'Š003',
    count: 191,
    order: 159,
    orderHrv: 159,
    nameHrv: 'Šenoa, August',
    linkHrv: 'https://enciklopedija.hr/Natuknica.aspx?ID=59443',
    cvHrv:
      'Hrvatski književnik, kritičar, prevoditelj i političar (Zagreb, 14. XI. 1838 – Zagreb, 13. XII. 1881).',
    name: 'Šenoa, August',
    rank: 10,
    link: 'https://en.wikipedia.org/wiki/August_%C5%A0enoa',
    group: 'S',
    groupHrv: 'S',
    cv:
      'Croatian writer, critic, translator and politician (Zagreb, 14 November 1838 - Zagreb, 13 December 1881).',
  },
  Š002: {
    id: 'Š002',
    count: 36,
    order: 160,
    orderHrv: 160,
    nameHrv: 'Šimić, Antun Branko',
    linkHrv: 'https://enciklopedija.hr/Natuknica.aspx?ID=59548',
    cvHrv:
      'Hrvatski pjesnik, kritičar i prevoditelj (Drinovci, Bosna i Hercegovina, 18. XI. 1898 – Zagreb, 2. V. 1925).',
    name: 'Šimić, Antun Branko',
    rank: 61,
    link: 'https://en.wikipedia.org/wiki/Antun_Branko_%C5%A0imi%C4%87',
    group: 'S',
    groupHrv: 'S',
    cv:
      'Croatian poet, critic and translator (Drinovci, Bosnia and Herzegovina, 18 November 1898 – Zagreb, 2 May 1925).',
  },
  Š004: {
    id: 'Š004',
    count: 18,
    order: 161,
    orderHrv: 161,
    nameHrv: 'Šimunović, Dinko',
    linkHrv: 'https://enciklopedija.hr/Natuknica.aspx?ID=59567',
    cvHrv: 'Hrvatski književnik (Knin, 1. IX. 1873 – Zagreb, 3. VIII. 1933). ',
    name: 'Šimunović, Dinko',
    rank: 117,
    link: 'https://en.wikipedia.org/wiki/Dinko_%C5%A0imunovi%C4%87',
    group: 'S',
    groupHrv: 'S',
    cv: 'Croatian writer (Knin, 1 September 1873 - Zagreb, 3 August 1933).',
  },
  S010: {
    id: 'S010',
    count: 13,
    order: 151,
    orderHrv: 151,
    nameHrv: 'Spinčić, Vjekoslav',
    linkHrv: 'https://enciklopedija.hr/Natuknica.aspx?ID=57430',
    cvHrv:
      'Hrvatski političar, povjesničar i publicist (Spinčići kraj Kastva, 23. X. 1848 – Sušak, Rijeka, 27. V. 1933). ',
    name: 'Spinčić, Vjekoslav',
    rank: 141,
    link: 'https://en.wikipedia.org/wiki/Vjekoslav_Spin%C4%8Di%C4%87',
    group: 'S',
    groupHrv: 'S',
    cv:
      'Croatian politician, historian, and writer (Spinčići near Kastav, 23 October 1848 - Sušak, Rijeka, 27 May 1933).',
  },
  Š008: {
    id: 'Š008',
    count: 11,
    order: 162,
    orderHrv: 162,
    nameHrv: 'Štampar, Andrija',
    linkHrv: 'https://enciklopedija.hr/Natuknica.aspx?ID=59892',
    cvHrv:
      'Hrvatski liječnik, stručnjak javnog zdravstva, dekan Medicinskog fakulteta u Zagrebu, rektor Sveučilišta u Zagrebu i predsjednik Jugoslavenske akademije znanosti i umjetnosti (Brodski Drenovac, 1. IX. 1888 – Zagreb, 26. VI. 1958).',
    name: 'Štampar, Andrija',
    rank: 169,
    link: 'https://en.wikipedia.org/wiki/Andrija_%C5%A0tampar',
    group: 'S',
    groupHrv: 'S',
    cv:
      'Croatian physician, public health expert, dean of Faculty of Medicine in Zagreb, rector of the University of Zagreb, and president of the Yugoslav Academy of Sciences and Arts (Brodski Drenovac, 1 September 1888 - Zagreb, 26 June 1958).',
  },
  S003: {
    id: 'S003',
    count: 241,
    order: 152,
    orderHrv: 152,
    nameHrv: 'Starčević, Ante',
    linkHrv: 'https://enciklopedija.hr/natuknica.aspx?id=57830',
    cvHrv:
      'Hrvatski političar, jedan od osnivača i vođa Hrvatske stranke prava (Žitnik kraj Gospića, 23. V. 1823 – Zagreb, 28. II. 1896).',
    name: 'Starčević, Ante',
    rank: 7,
    link: 'https://en.wikipedia.org/wiki/Ante_Star%C4%8Devi%C4%87',
    group: 'S',
    groupHrv: 'S',
    cv:
      'Croatian politician, one of the founders and leaders of the Croatian Party of Rights (Žitnik near Gospić, 23 May 1823 - Zagreb, 28 February 1896).',
  },
  S001: {
    id: 'S001',
    count: 153,
    order: 154,
    orderHrv: 153,
    nameHrv: 'Stepinac, Alojzije',
    linkHrv: 'https://enciklopedija.hr/natuknica.aspx?id=58021',
    cvHrv:
      'Zagrebački nadbiskup i kardinal (Brezarić kraj Krašića, 8. V. 1898 – Krašić, 10. II. 1960).',
    name: 'Stepinac, Alojzije',
    rank: 12,
    link: 'https://en.wikipedia.org/wiki/Aloysius_Stepinac',
    group: 'S',
    groupHrv: 'S',
    cv:
      'Cardinal and Archbishop of Zagreb (Brezarić near Krašić, 8 May 1898 - Krašić, 10 February 1960).',
  },
  S005: {
    id: 'S005',
    count: 19,
    order: 155,
    orderHrv: 154,
    nameHrv: 'Stjepan Držislav',
    linkHrv: 'https://enciklopedija.hr/natuknica.aspx?id=16388',
    cvHrv: 'Hrvatski kralj (? - 997).',
    name: 'Stjepan Držislav',
    rank: 108,
    link: 'https://en.wikipedia.org/wiki/Stephen_Dr%C5%BEislav_of_Croatia',
    group: 'S',
    groupHrv: 'S',
    cv: 'Croatian king (? - 997). ',
  },
  Š012: {
    id: 'Š012',
    count: 15,
    order: 163,
    orderHrv: 163,
    nameHrv: 'Štolcer-Slavenski, Josip',
    linkHrv: 'https://enciklopedija.hr/Natuknica.aspx?ID=59948',
    cvHrv:
      'Hrvatski skladatelj (Čakovec, 11. V. 1896 – Beograd, 30. XI. 1955).',
    name: 'Štolcer-Slavenski, Josip',
    rank: 128,
    link: 'https://en.wikipedia.org/wiki/Josip_%C5%A0tolcer-Slavenski',
    group: 'S',
    groupHrv: 'S',
    cv:
      'Croatian composer (Čakovec, 11 May 1896 - Belgrade, 30 November 1955).',
  },
  Š007: {
    id: 'Š007',
    count: 13,
    order: 164,
    orderHrv: 164,
    nameHrv: 'Štoos, Pavao',
    linkHrv: 'https://enciklopedija.hr/Natuknica.aspx?ID=59949',
    cvHrv:
      'Hrvatski književnik i preporoditelj (Dubravica kraj Klanjca, 10. XII. 1806 – Pokupsko, 30. III. 1862).',
    name: 'Štoos, Pavao',
    rank: 141,
    link: 'https://en.wikipedia.org/wiki/Pavao_%C5%A0toos',
    group: 'S',
    groupHrv: 'S',
    cv:
      'Croatian writer and revivalist (Dubravica near Klanjec, 10 December 1806 - Pokupsko, 30 March 1862).',
  },
  S002: {
    id: 'S002',
    count: 111,
    order: 156,
    orderHrv: 156,
    nameHrv: 'Strossmayer, Josip Juraj',
    linkHrv: 'https://enciklopedija.hr/natuknica.aspx?ID=58459',
    cvHrv:
      'Đakovački biskup i hrvatski političar (Osijek, 4. II. 1815 – Đakovo, 8. IV. 1905).',
    name: 'Strossmayer, Josip Juraj',
    rank: 21,
    link: 'https://en.wikipedia.org/wiki/Josip_Juraj_Strossmayer',
    group: 'S',
    groupHrv: 'S',
    cv:
      'Bishop of Đakovo and Croatian politician (Osijek, 4 February 1815 - Đakovo, 8 April 1905).',
  },
  Š011: {
    id: 'Š011',
    count: 13,
    order: 165,
    orderHrv: 165,
    nameHrv: 'Šubić Bribirski, Pavao I.',
    linkHrv: 'https://enciklopedija.hr/natuknica.aspx?id=9489',
    cvHrv:
      'Ban Hrvata između 1275 i 1312 i gospodar Bosne (?, oko 1245 – ?, 1. V. 1312). ',
    name: 'Šubić of Bribir, Pavao I',
    rank: 141,
    link: 'https://en.wikipedia.org/wiki/Paul_I_%C5%A0ubi%C4%87_of_Bribir',
    group: 'S',
    groupHrv: 'S',
    cv:
      'Croatian ban (viceroy) between 1275 and 1312, and ruler of Bosnia from 1299 to 1312. (?, ca. 1245 - ?, 1 May 1312).',
  },
  S007: {
    id: 'S007',
    count: 10,
    order: 157,
    orderHrv: 157,
    nameHrv: 'Sudeta, Đuro',
    linkHrv: 'https://enciklopedija.hr/natuknica.aspx?id=58634',
    cvHrv:
      'Hrvatski književnik (Stara Ploščica kraj Bjelovara, 10. IV. 1903 – Koprivnica, 30. IV. 1927).',
    name: 'Sudeta, Đuro',
    rank: 178,
    link: 'https://en.wikipedia.org/wiki/%C4%90uro_Sudeta',
    group: 'S',
    groupHrv: 'S',
    cv:
      'Croatian writer (Stara Ploščica near Bjelovar, 10 April 1903 - Koprivnica, 30 April 1927).',
  },
  S008: {
    id: 'S008',
    count: 27,
    order: 158,
    orderHrv: 158,
    nameHrv: 'Supilo, Frano',
    linkHrv: 'https://enciklopedija.hr/Natuknica.aspx?ID=58835',
    cvHrv:
      'Hrvatski političar i publicist (Cavtat, 30. XI. 1870 – London, 25. IX. 1917).',
    name: 'Supilo, Frano',
    rank: 73,
    link: 'https://en.wikipedia.org/wiki/Frano_Supilo',
    group: 'S',
    groupHrv: 'S',
    cv:
      'Croatian politician and writer (Cavtat, 30 November 1870 - London, 25 September 1917).',
  },
  Š006: {
    id: 'Š006',
    count: 17,
    order: 166,
    orderHrv: 166,
    nameHrv: 'Šušak, Gojko',
    linkHrv: 'https://enciklopedija.hr/natuknica.aspx?id=60044',
    cvHrv:
      'Hrvatski političar (Široki Brijeg, Bosna i Hercegovina, 16. III. 1945 – Zagreb, 3. V. 1998).',
    name: 'Šušak, Gojko',
    rank: 118,
    link: 'https://en.wikipedia.org/wiki/Gojko_%C5%A0u%C5%A1ak',
    group: 'S',
    groupHrv: 'S',
    cv:
      'Croatian politician (Široki Brijeg, Bosnia and Herzegovina, 16 March 1945 - Zagreb, 3 May 1998).',
  },
  S0062: {
    id: 'S0062',
    count: 33,
    order: 2,
    orderHrv: 1,
    nameHrv: 'Ana, sv.',
    linkHrv: 'https://hr.wikipedia.org/wiki/Sveta_Ana',
    cvHrv: 'Kršćanska svetica, majka Blažene Djevice Marije.',
    name: 'Anne, St.',
    rank: 64,
    link: 'https://en.wikipedia.org/wiki/Saint_Anne',
    group: 'A',
    groupHrv: 'A',
    cv: 'Christian saint, mother of the Blessed Virgin Mary.',
  },
  S0067: {
    id: 'S0067',
    count: 13,
    order: 8,
    orderHrv: 8,
    nameHrv: 'Barbara, sv.',
    linkHrv: 'https://proleksis.lzmk.hr/10783',
    cvHrv: 'Kršćanska svetica i mučenica (III. stoljeće)',
    name: 'Barbara, St.',
    rank: 141,
    link: 'https://en.wikipedia.org/wiki/Saint_Barbara',
    group: 'B',
    groupHrv: 'B',
    cv: 'Christian saint and martyr (3rd century)',
  },
  S00644: {
    id: 'S00644',
    count: 16,
    order: 51,
    orderHrv: 49,
    nameHrv: 'Helena, sv.',
    linkHrv: 'https://hr.wikipedia.org/wiki/Sveta_Helena',
    cvHrv:
      'Flavia Iulia Helena Augusta, žena cara Konstancija Klora i majka cara Konstantina Velikog, kršćanska svetica (oko 248. - Konstantinopol, 18. VII. 329).',
    name: 'Helena, St.',
    rank: 124,
    link: 'https://en.wikipedia.org/wiki/Saint_Helena',
    group: 'H',
    groupHrv: 'H',
    cv:
      'Flavia Iulia Helena Augusta, wife of Emperor Constantius Chlorus and mother of Emperor Constantine the Great, Christian saint (around 248. - Constantinople, 18 July 329).',
  },
  S00636: {
    id: 'S00636',
    count: 13,
    order: 25,
    orderHrv: 72,
    nameHrv: 'Katarina, sv.',
    linkHrv: 'https://hr.wikipedia.org/wiki/Katarina_Aleksandrijska',
    cvHrv:
      'Kršćanska svetica i mučenica (Aleksandrija, oko 287. - Aleksandrija, oko 305).',
    name: 'Catherine, St.',
    rank: 141,
    link: 'https://en.wikipedia.org/wiki/Catherine_of_Alexandria',
    group: 'C',
    groupHrv: 'K',
    cv: 'Christian saint and martyr (Alexandria, c. 287 - Alexandria c. 305).',
  },
  S00610: {
    id: 'S00610',
    count: 117,
    order: 111,
    orderHrv: 107,
    nameHrv: 'Marija, sv.',
    linkHrv: 'https://enciklopedija.hr/Natuknica.aspx?ID=38908',
    cvHrv:
      'Bogorodica, Blažena Djevica Marija, Gospa, Majka Božja, majka Isusa Krista i kršćanska svetica (?, oko 20. pr. Kr. – Jeruzalem, oko 35).',
    name: 'Mary, St.',
    rank: 17,
    link: 'https://en.wikipedia.org/wiki/Mary,_mother_of_Jesus',
    group: 'M',
    groupHrv: 'M',
    cv:
      'Blessed Virgin Mary, Mother of Jesus Christ and Christian saint (?, around 20 BC - Jerusalem, around 35).',
  },
  S0063: {
    id: 'S0063',
    count: 60,
    order: 3,
    orderHrv: 3,
    nameHrv: 'Antun, sv.',
    linkHrv: 'https://enciklopedija.hr/natuknica.aspx?id=3214',
    cvHrv:
      'Teolog, propovjednik, franjevac i kršćanski svetac (Lisabon, 15. VIII. ? 1195 – Arcella, Padova, 13. VI. 1231).',
    name: 'Anthony, St.',
    rank: 34,
    link: 'https://en.wikipedia.org/wiki/Anthony_of_Padua',
    group: 'A',
    groupHrv: 'A',
    cv:
      'Theologian, preacher, Franciscan friar, and Christian saint (Lisbon, 15 August 1195 - Arcella, Padua, 13 June 1231).',
  },
  S00638: {
    id: 'S00638',
    count: 11,
    order: 10,
    orderHrv: 10,
    nameHrv: 'Benedikt, sv.',
    linkHrv: 'https://enciklopedija.hr/natuknica.aspx?id=6877',
    cvHrv:
      'Otac zapadnog redovništva i kršćanski svetac (Nursia blizu Perugie, oko 480 – Montecassino, 547).',
    name: 'Benedict, St.',
    rank: 169,
    link: 'https://en.wikipedia.org/wiki/Benedict_of_Nursia',
    group: 'B',
    groupHrv: 'B',
    cv:
      'Father of Western monasticism and Christian saint (Nursia near Perugia, c. 480 - Montecassino, 547).',
  },
  S00639: {
    id: 'S00639',
    count: 22,
    order: 33,
    orderHrv: 32,
    nameHrv: 'Florijan, sv.',
    linkHrv: 'https://hr.wikipedia.org/wiki/Sveti_Florijan',
    svHrv:
      'Kršćanski svetac i mučenik (Cetium, danas Sankt Pölten, Austrija - Enns, oko 304).',
    name: 'Florian, St.',
    rank: 94,
    link: 'https://en.wikipedia.org/wiki/Saint_Florian',
    group: 'F',
    groupHrv: 'F',
    cv:
      'Christian saint and martyr (Cetium, today Sankt Pölten, Austria - Enns, c. 304).',
  },
  S00613: {
    id: 'S00613',
    count: 12,
    order: 34,
    orderHrv: 34.5,
    nameHrv: 'Franjo, sv.',
    linkHrv: 'https://enciklopedija.hr/Natuknica.aspx?ID=20493',
    cvHrv:
      'Pravo ime Giovanni Bernardone, osnivatelj Franjevačkoga reda i kršćanski svetac (Assisi, 1181. ili 1182 – Assisi, 1226).',
    name: 'Francis, St.',
    rank: 152,
    link: 'https://en.wikipedia.org/wiki/Francis_of_Assisi',
    group: 'F',
    groupHrv: 'F',
    cv:
      'Real name Giovanni Bernardone, founder of the Franciscan Order and Christian saint (Assisi, 1181 or 1182 - Assisi, 1226).',
  },
  S00618: {
    id: 'S00618',
    count: 47,
    order: 61,
    orderHrv: 54,
    nameHrv: 'Ivan, sv.',
    linkHrv: 'https://enciklopedija.hr/natuknica.aspx?id=37291',
    cvHrv: 'Evanđelist, apostol i kršćanski svetac.',
    name: 'John, St.',
    rank: 49,
    link: 'https://en.wikipedia.org/wiki/Saint_John',
    group: 'J',
    groupHrv: 'I',
    cv: 'Evangelist, apostle, and Christian saint.',
  },
  S00640: {
    id: 'S00640',
    count: 19,
    order: 56,
    orderHrv: 57,
    nameHrv: 'Jakov, sv.',
    linkHrv: 'https://enciklopedija.hr/natuknica.aspx?id=28587',
    cvHrv: 'Apostol, mučenik i kršćanski svetac (? - 44).',
    name: 'Jacob, St.',
    rank: 108,
    link: 'https://en.wikipedia.org/wiki/Jacob_of_Nisibis',
    group: 'J',
    groupHrv: 'J',
    cv: 'Apostle, martyr, and Christian saint (? - 44).',
  },
  S00641: {
    id: 'S00641',
    count: 16,
    order: 60,
    orderHrv: 60,
    nameHrv: 'Jeronim, sv.',
    linkHrv: 'https://enciklopedija.hr/natuknica.aspx?ID=29068',
    cvHrv:
      'Svećenik, teolog, povjesničar i kršćanski svetac (Stridon kod Emone, oko 340 – Betlehem, 30. IX. 420).',
    name: 'Jerome, St.',
    rank: 124,
    link: 'https://en.wikipedia.org/wiki/Jerome',
    group: 'J',
    groupHrv: 'J',
    cv:
      'Priest, theologian, historian, and Christian saint (Stridon near Emona, around 340 - Bethlehem, 30 September 420).',
  },
  S00623: {
    id: 'S00623',
    count: 26,
    order: 63,
    orderHrv: 61,
    nameHrv: 'Josip, sv.',
    linkHrv: 'https://enciklopedija.hr/natuknica.aspx?id=29348',
    cvHrv:
      'Marijin zaručnik, poočim Isusov i kršćanski svetac (I. st. pr. Kr. – I. st.).',
    name: 'Joseph, St.',
    rank: 81,
    link: 'https://en.wikipedia.org/wiki/Saint_Joseph',
    group: 'J',
    groupHrv: 'J',
    cv:
      "Mary's spouse, foster father of Jesus, and Christian saint (1st century BC - 1st century).",
  },
  S00611: {
    id: 'S00611',
    count: 55,
    order: 38,
    orderHrv: 64,
    nameHrv: 'Juraj, sv.',
    linkHrv: 'https://enciklopedija.hr/natuknica.aspx?id=29535',
    cvHrv: 'Kršćanski svetac i mučenik (? - 23. IV. 303).',
    name: 'George, St.',
    rank: 39,
    link: 'https://en.wikipedia.org/wiki/Saint_George',
    group: 'G',
    groupHrv: 'J',
    cv: 'Christian saint and martyr (? - 23 April 303).',
  },
  S00617: {
    id: 'S00617',
    count: 16,
    order: 98,
    orderHrv: 101,
    nameHrv: 'Lovro, sv.',
    linkHrv: 'https://enciklopedija.hr/natuknica.aspx?id=37291',
    cvHrv: 'Kršćanski svetac i mučenik (III. st.).',
    name: 'Lawrence, St.',
    rank: 124,
    link: 'https://en.wikipedia.org/wiki/Saint_Lawrence',
    group: 'L',
    groupHrv: 'L',
    cv: 'Christian saint and martyr (III century).',
  },
  S00625: {
    id: 'S00625',
    count: 17,
    order: 104,
    orderHrv: 103,
    nameHrv: 'Luka, sv.',
    linkHrv: 'https://enciklopedija.hr/Natuknica.aspx?ID=37475',
    cvHrv:
      'Evanđelist i kršćanski svetac (Antiohija, Sirija, oko 10 – Bitinija ili Beocija, oko 90).',
    name: 'Luke, St.',
    rank: 118,
    link: 'https://en.wikipedia.org/wiki/Luke_the_Evangelist',
    group: 'L',
    groupHrv: 'L',
    cv:
      'Evangelist and Christian saint (Antioch, Syria, c. 10 - Bithynia or Boeotia, c. 90).',
  },
  S00631: {
    id: 'S00631',
    count: 27,
    order: 107,
    orderHrv: 108,
    nameHrv: 'Marko, sv.',
    linkHrv: 'https://enciklopedija.hr/natuknica.aspx?id=39002',
    cvHrv: 'Evanđelist i kršćanski svetac (Jeruzalem, oko 10 – ? Rim, oko 75).',
    name: 'Mark, St.',
    rank: 73,
    link: 'https://en.wikipedia.org/wiki/Mark_the_Evangelist',
    group: 'M',
    groupHrv: 'M',
    cv: 'Evangelist and Christian saint (Jerusalem, c. 10 -? Rome, c. 75).',
  },
  S00629: {
    id: 'S00629',
    count: 34,
    order: 109,
    orderHrv: 110,
    nameHrv: 'Martin, sv.',
    linkHrv: 'https://enciklopedija.hr/natuknica.aspx?id=39142',
    cvHrv:
      'Papa od 649 do 653 i kršćanski svetac (Todi, Umbrija, oko 590 – Herson, Krim, 16. IX. 655).',
    name: 'Martin, St.',
    rank: 63,
    link: 'https://en.wikipedia.org/wiki/Saint_Martin',
    group: 'M',
    groupHrv: 'M',
    cv:
      'Pope from 649 to 653 and Christian saint (Todi, Umbria, about 590 - Kherson, Crimea, 16 September 655).',
  },
  S00612: {
    id: 'S00612',
    count: 49,
    order: 123,
    orderHrv: 123,
    nameHrv: 'Nikola, sv.',
    linkHrv: 'https://enciklopedija.hr/natuknica.aspx?id=43786',
    cvHrv:
      'Biskup i kršćanski svetac (Patara, Licija, oko 270 – Mira, Licija, oko 350).',
    name: 'Nicholas, St.',
    rank: 46,
    link: 'https://en.wikipedia.org/wiki/Saint_Nicholas',
    group: 'N',
    groupHrv: 'N',
    cv:
      'Bishop and Christian saint (Patara, Lycia, ca. 270 - Mira, Lycia, ca. 350).',
  },
  S0061: {
    id: 'S0061',
    count: 51,
    order: 131,
    orderHrv: 131,
    nameHrv: 'Petar, sv.',
    linkHrv: 'https://enciklopedija.hr/natuknica.aspx?ID=47839',
    cvHrv:
      'Apostol i kršćanski svetac (Betsaida u Galileji, ? – Rim, između 64. i 67).',
    name: 'Peter, St.',
    rank: 42,
    link: 'https://en.wikipedia.org/wiki/Saint_Peter',
    group: 'P',
    groupHrv: 'P',
    cv:
      'Apostle and Christian saint (Bethsaida in Galilee,? - Rome, between 64 and 67).',
  },
  S00626: {
    id: 'S00626',
    count: 60,
    order: 148,
    orderHrv: 148,
    nameHrv: 'Rok, sv.',
    linkHrv: 'https://hr.wikipedia.org/wiki/Sveti_Rok',
    cvHrv:
      'Svetac rimokatoličke Crkve (Montpellier, oko 1348. – Voghera, 15./16. kolovoza 1376./79.)',
    name: 'Roch, St.',
    rank: 34,
    link: 'https://en.wikipedia.org/wiki/Saint_Roch',
    group: 'R',
    groupHrv: 'R',
    cv: 'Catholic saint (Montpellier, c. 1348 - Voghera, 15/16 August 1376/79)',
  },
  S00628: {
    id: 'S00628',
    count: 20,
    order: 153,
    orderHrv: 155,
    nameHrv: 'Stjepan, sv.',
    linkHrv: 'https://enciklopedija.hr/natuknica.aspx?id=58150',
    cvHrv:
      'Đakon, prvomučenik i kršćanski svetac (?, oko 15 – Jeruzalem, oko 40).',
    name: 'Stephen, St.',
    rank: 103,
    link: 'https://en.wikipedia.org/wiki/Saint_Stephen',
    group: 'S',
    groupHrv: 'S',
    cv:
      'Deacon, first Christian martyr and Christian saint (?, About 15 - Jerusalem, about 40).',
  },
  S00643: {
    id: 'S00643',
    count: 27,
    order: 183,
    orderHrv: 179,
    nameHrv: 'Vid, sv.',
    linkHrv: 'https://hr.wikipedia.org/wiki/Sveti_Vid',
    cvHrv:
      'Kršćanski svetac i mučenik (Mazzara del Valla, oko 290. - Lukanija, oko 303.).',
    name: 'Vitus, St.',
    rank: 73,
    link: 'https://en.wikipedia.org/wiki/Vitus',
    group: 'V',
    groupHrv: 'V',
    cv:
      'Christian saint and martyr (Mazzara del Valla, c. 290 - Lucania, c. 303)',
  },
  T008: {
    id: 'T008',
    count: 11,
    order: 167,
    orderHrv: 167,
    nameHrv: 'Tadijanović, Dragutin',
    linkHrv: 'https://enciklopedija.hr/natuknica.aspx?ID=60160',
    cvHrv: 'Hrvatski pjesnik (Rastušje, 4. XI. 1905 – Zagreb, 27. VI. 2007).',
    name: 'Tadijanović, Dragutin',
    rank: 169,
    link: 'https://en.wikipedia.org/wiki/Dragutin_Tadijanovi%C4%87',
    group: 'T',
    groupHrv: 'T',
    cv: 'Croatian poet (Rastušje, 4 November 1905 - Zagreb, 27 June 2007).',
  },
  T007: {
    id: 'T007',
    count: 31,
    order: 168,
    orderHrv: 168,
    nameHrv: 'Tavelić, Nikola, sv.',
    linkHrv: 'https://enciklopedija.hr/natuknica.aspx?id=60567',
    cvHrv:
      'Franjevac, kršćanski mučenik i svetac (Šibenik, oko 1340 – Jeruzalem, 1391).',
    name: 'Tavelić, Nikola, St.',
    rank: 67,
    link: 'https://en.wikipedia.org/wiki/Nicholas_Tavelic',
    group: 'T',
    groupHrv: 'T',
    cv:
      'Franciscan friar, Christian martyr and saint (Šibenik, around 1340 - Jerusalem, 1391).',
  },
  T002: {
    id: 'T002',
    count: 134,
    order: 169,
    orderHrv: 169,
    nameHrv: 'Tesla, Nikola',
    linkHrv: 'https://enciklopedija.hr/Natuknica.aspx?ID=61021',
    cvHrv:
      'Američki i hrvatski izumitelj srpskoga podrijetla (Smiljan, 10. VII. 1856 – New York, 7. I. 1943).',
    name: 'Tesla, Nikola',
    rank: 14,
    link: 'https://en.wikipedia.org/wiki/Nikola_Tesla',
    group: 'T',
    groupHrv: 'T',
    cv:
      'American and Croatian inventor of Serb origin (Smiljan, 10 July 1856 - New York, 7 January 1943).',
  },
  T003: {
    id: 'T003',
    count: 13,
    order: 170,
    orderHrv: 170,
    nameHrv: 'Tijardović, Ivo',
    linkHrv: 'https://enciklopedija.hr/Natuknica.aspx?ID=61296',
    cvHrv:
      'Hrvatski skladatelj i dirigent (Split, 18. IX. 1895 – Zagreb, 19. III. 1976).',
    name: 'Tijardović, Ivo',
    rank: 141,
    link: 'https://en.wikipedia.org/wiki/Ivo_Tijardovi%C4%87',
    group: 'T',
    groupHrv: 'T',
    cv:
      'Croatian composer and conductor (Split, 18 September 1895 - Zagreb, 19 March 1976).',
  },
  T004: {
    id: 'T004',
    count: 318,
    order: 171,
    orderHrv: 171,
    nameHrv: 'Tomislav',
    linkHrv: 'https://enciklopedija.hr/natuknica.aspx?id=61717',
    cvHrv: 'Hrvatski vladar približno od 910. do 928.',
    name: 'Tomislav',
    rank: 4,
    link: 'https://en.wikipedia.org/wiki/Tomislav_of_Croatia',
    group: 'T',
    groupHrv: 'T',
    cv: 'Croatian ruler from approximately 910 to 928.',
  },
  T006: {
    id: 'T006',
    count: 20,
    order: 172,
    orderHrv: 172,
    nameHrv: 'Trenk, Franjo',
    linkHrv: 'https://enciklopedija.hr/Natuknica.aspx?ID=62171',
    cvHrv:
      'Pravo ime Franz von der Trenck, hrvatski barun, habsburški pukovnik (Reggio Calabria, Italija, 1. I. 1711 – tamnica Spielberg kraj Brna, 4. X. 1749). ',
    name: 'Trenk, Franjo',
    rank: 103,
    link: 'https://en.wikipedia.org/wiki/Baron_Franz_von_der_Trenck',
    group: 'T',
    groupHrv: 'T',
    cv:
      'Real name Franz von der Trenck, Croatian baron, Habsburg colonel (Reggio Calabria, Italy, 1 January 1711 - Spielberg dungeon near Brno, 4 October 1749).',
  },
  T010: {
    id: 'T010',
    count: 22,
    order: 173,
    orderHrv: 173,
    nameHrv: 'Trnina, Milka',
    linkHrv: 'https://enciklopedija.hr/Natuknica.aspx?ID62384i',
    cvHrv:
      'Pravo ime Katarina, hrvatska pjevačica, sopran (Doljnji Sip kraj Vezišća, 19. XII. 1863 – Zagreb, 18. V. 1941).',
    name: 'Trnina, Milka',
    rank: 94,
    link: 'https://en.wikipedia.org/wiki/Milka_Ternina',
    group: 'T',
    groupHrv: 'T',
    cv:
      'Real name Katarina, Croatian singer, soprano (Doljnji Sip near Vezišće, 19 December 1863 - Zagreb, 18 May 1941).',
  },
  T014: {
    id: 'T014',
    count: 12,
    order: 174,
    orderHrv: 174,
    nameHrv: 'Trnski, Ivan',
    linkHrv: 'https://enciklopedija.hr/Natuknica.aspx?ID=62389',
    cvHrv:
      'Hrvatski književnik i prevoditelj (Stara Rača kraj Bjelovara, 1. V. 1819 – Zagreb, 30. VI. 1910).',
    name: 'Trnski, Ivan',
    rank: 152,
    link: 'https://en.wikipedia.org/wiki/Ivan_Trnski',
    group: 'T',
    groupHrv: 'T',
    cv:
      'Croatian writer and translator (Stara Rača near Bjelovar, 1 May 1819 - Zagreb, 30 June 1910).',
  },
  T009: {
    id: 'T009',
    count: 58,
    order: 175,
    orderHrv: 175,
    nameHrv: 'Trpimir I.',
    linkHrv: 'https://enciklopedija.hr/Natuknica.aspx?ID=62489',
    cvHrv: 'Trepimerus, Trepimero; hrvatski knez (? - 864)',
    name: 'Trpimir I',
    rank: 37,
    link: 'https://en.wikipedia.org/wiki/Trpimir_I_of_Croatia',
    group: 'T',
    groupHrv: 'T',
    cv: 'Trepimerus, Trepimero; Croatian duke (? - 864)',
  },
  T001: {
    id: 'T001',
    count: 209,
    order: 176,
    orderHrv: 176,
    nameHrv: 'Tuđman, Franjo',
    linkHrv: 'https://enciklopedija.hr/natuknica.aspx?id=62628',
    cvHrv:
      'Hrvatski povjesničar, političar, disident, državnik i prvi hrvatski predsjednik (Veliko Trgovišće, 14. V. 1922 – Zagreb, 10. XII. 1999). ',
    name: 'Tuđman, Franjo',
    rank: 9,
    link: 'https://en.wikipedia.org/wiki/Franjo_Tu%C4%91man',
    group: 'T',
    groupHrv: 'T',
    cv:
      'Croatian historian, politician, dissident and statesman, the first Croatian president. (Veliko Trgovišće, 14 May 1922 - Zagreb, 10 December 1999).',
  },
  T005: {
    id: 'T005',
    count: 16,
    order: 177,
    orderHrv: 177,
    nameHrv: 'Tvrtko I. Kotromanić',
    linkHrv: 'https://enciklopedija.hr/natuknica.aspx?id62878',
    cvHrv:
      'Bosanski ban 1353–77 i kralj 1377–91 (?, oko 1338 – ?, 10. III. 1391).',
    name: 'Tvrtko I. Kotromanić',
    rank: 124,
    link: 'https://en.wikipedia.org/wiki/Tvrtko_I_of_Bosnia',
    group: 'T',
    groupHrv: 'T',
    cv:
      'Bosnian ban 1353–77 and king 1377–91 (?, around 1338 - ?, 10 March 1391).',
  },
  U001: {
    id: 'U001',
    count: 139,
    order: 178,
    orderHrv: 178,
    nameHrv: 'Ujević, Tin',
    linkHrv: 'https://enciklopedija.hr/Natuknica.aspx?ID=63048',
    cvHrv:
      'Hrvatski književnik (Vrgorac, 5. VII. 1891 – Zagreb, 12. XI. 1955).',
    name: 'Ujević, Tin',
    rank: 13,
    link: 'https://en.wikipedia.org/wiki/Tin_Ujevi%C4%87',
    group: 'U',
    groupHrv: 'U',
    cv: 'Croatian writer (Vrgorac, 5 July 1891 – Zagreb, 12 November 1955).',
  },
  V008: {
    id: 'V008',
    count: 14,
    order: 179,
    orderHrv: 180,
    nameHrv: 'Vidrić, Vladimir',
    linkHrv: 'https://enciklopedija.hr/Natuknica.aspx?ID=64546',
    cvHrv:
      'Hrvatski pjesnik (Zagreb, 30. IV. 1875 – Stenjevec, Zagreb, 29. IX. 1909).',
    name: 'Vidrić, Vladimir',
    rank: 133,
    link: 'https://en.wikipedia.org/wiki/Vladimir_Vidri%C4%87',
    group: 'V',
    groupHrv: 'V',
    cv:
      'Croatian poet (Zagreb, 30 April 1875 - Stenjevec, Zagreb, 29 September 1909).',
  },
  V010: {
    id: 'V010',
    count: 25,
    order: 180,
    orderHrv: 181,
    nameHrv: 'Višeslav',
    linkHrv: 'https://enciklopedija.hr/natuknica.aspx?id=64862',
    cvHrv: 'Vuissasclavus; hrvatski knez početkom IX. st.',
    name: 'Višeslav',
    rank: 86,
    link: 'https://en.wikipedia.org/wiki/Vi%C5%A1eslav_of_Croatia',
    group: 'V',
    groupHrv: 'V',
    cv: 'Vuissasclavus; Croatian duke from the beginning of 9th century',
  },
  V011: {
    id: 'V011',
    count: 12,
    order: 181,
    orderHrv: 182,
    nameHrv: 'Vitez, Grigor',
    linkHrv: 'https://enciklopedija.hr/Natuknica.aspx?ID=64903',
    cvHrv:
      'Hrvatski pjesnik i prevoditelj (Kosovac kraj Okučana, 15. II. 1911 – Zagreb, 23. XI. 1966).',
    name: 'Vitez, Grigor',
    rank: 152,
    link: 'https://enciklopedija.hr/Natuknica.aspx?ID=64903',
    group: 'V',
    groupHrv: 'V',
    cv:
      'Croatian poet and translator (Kosovac near Okučani, 15 February 1911 - Zagreb, 23 November 1966).',
  },
  V005: {
    id: 'V005',
    count: 10,
    order: 182,
    orderHrv: 183,
    nameHrv: 'Vitezović Ritter, Pavao',
    linkHrv: 'https://enciklopedija.hr/natuknica.aspx?id=64910',
    cvHrv:
      'Hrvatski povjesničar, književnik i leksikograf (Senj, 7. I. 1652 – Beč, 20. I. 1713).',
    name: 'Vitezović Ritter, Pavao',
    rank: 178,
    link: 'https://en.wikipedia.org/wiki/Pavao_Ritter_Vitezovi%C4%87',
    group: 'V',
    groupHrv: 'V',
    cv:
      'Croatian historian, writer and lexicographer (Senj, 7 January 1652 - Vienna, 20 January 1713).',
  },
  V015: {
    id: 'V015',
    count: 15,
    order: 184,
    orderHrv: 184,
    nameHrv: 'Vlačić, Matija Ilirik',
    linkHrv: 'https://enciklopedija.hr/natuknica.aspx?id=65013',
    cvHrv:
      'Latinizirano Matthias Flacius Illyricus, hrvatski protestantski teolog, crkveni povjesničar i filolog (Labin, 3. III. 1520 – Frankfurt na Majni, 11. III. 1575).',
    name: 'Vlačić, Matija Ilirik',
    rank: 128,
    link: 'https://en.wikipedia.org/wiki/Matthias_Flacius',
    group: 'V',
    groupHrv: 'V',
    cv:
      'Latin: Matthias Flacius Illyricus; Croatian Protestant theologian, church historian and philologist (Labin, 3 March 1520 - Frankfurt am Main, 11 March 1575).',
  },
  V003: {
    id: 'V003',
    count: 10,
    order: 185,
    orderHrv: 185,
    nameHrv: 'Vojnović, Ivo',
    linkHrv: 'https://enciklopedija.hr/Natuknica.aspx?ID=65235',
    cvHrv:
      'Hrvatski književnik (Dubrovnik, 9. X. 1857 – Beograd, 30. VIII. 1929). ',
    name: 'Vojnović, Ivo',
    rank: 178,
    link: 'https://en.wikipedia.org/wiki/Ivo_Vojnovi%C4%87',
    group: 'V',
    groupHrv: 'V',
    cv:
      'Croatian writer (Dubrovnik, 9 October 1857 - Belgrade, 30 August 1929).',
  },
  V007: {
    id: 'V007',
    count: 12,
    order: 186,
    orderHrv: 186,
    nameHrv: 'Vrančić, Faust',
    linkHrv: 'https://enciklopedija.hr/Natuknica.aspx?ID=65386',
    cvHrv:
      'Hrvatski polihistor, leksikograf i izumitelj (Šibenik, 1. I. 1551 – Venecija, 20. I. 1617). ',
    name: 'Vrančić, Faust',
    rank: 152,
    link: 'https://en.wikipedia.org/wiki/Fausto_Veranzio',
    group: 'V',
    groupHrv: 'V',
    cv:
      'Croatian polymath, lexicographer and inventor (Šibenik, 1 January 1551 - Venice, 20 January 1617).',
  },
  V004: {
    id: 'V004',
    count: 30,
    order: 187,
    orderHrv: 187,
    nameHrv: 'Vraz, Stanko',
    linkHrv: 'https://enciklopedija.hr/Natuknica.aspx?ID=65431',
    cvHrv:
      'Pravo ime Jakob Frass, hrvatski i slovenski pjesnik, kritičar i prevoditelj (Cerovec, Slovenija, 30. VI. 1810 – Zagreb, 24. V. 1851).',
    name: 'Vraz, Stanko',
    rank: 68,
    link: 'https://en.wikipedia.org/wiki/Stanko_Vraz',
    group: 'V',
    groupHrv: 'V',
    cv:
      'Real name Jakob Frass, Croatian and Slovenian poet, critic, and translator (Cerovec, Slovenia, 30 June 1810 - Zagreb, 24 May 1851).',
  },
  Z002: {
    id: 'Z002',
    count: 47,
    order: 188,
    orderHrv: 188,
    nameHrv: 'Zajc, Ivan',
    linkHrv: 'https://enciklopedija.hr/natuknica.aspx?id=66730',
    cvHrv:
      'Hrvatski skladatelj, dirigent i pedagog (Rijeka, 3. VIII. 1832 – Zagreb, 16. XII. 1914).',
    name: 'Zajc, Ivan',
    rank: 49,
    link: 'https://en.wikipedia.org/wiki/Ivan_Zajc',
    group: 'Z',
    groupHrv: 'Z',
    cv:
      'Croatian composer, conductor and educator (Rijeka, 3 August 1832 - Zagreb, 16 December 1914).',
  },
  Z004: {
    id: 'Z004',
    count: 39,
    order: 189,
    orderHrv: 189,
    nameHrv: 'Zoranić, Petar',
    linkHrv: 'https://enciklopedija.hr/natuknica.aspx?id=67405',
    cvHrv: 'Hrvatski književnik (Zadar, 1508 – ?, ? prije 1569).',
    name: 'Zoranić, Petar',
    rank: 60,
    link: 'https://en.wikipedia.org/wiki/Petar_Zorani%C4%87',
    group: 'Z',
    groupHrv: 'Z',
    cv: 'Croatian writer (Zadar, 1508 - ?,? before 1569).',
  },
  Z006: {
    id: 'Z006',
    count: 26,
    order: 190,
    orderHrv: 190,
    nameHrv: 'Zrinski, Ana Katarina',
    linkHrv: 'https://enciklopedija.hr/natuknica.aspx?id=67469',
    cvHrv:
      'Hrvatska grofica i spisateljica (? Bosiljevo, oko 1625 – Graz, 16. XI. 1673).',
    name: 'Zrinski, Ana Katarina',
    rank: 81,
    link: 'https://en.wikipedia.org/wiki/Katarina_Zrinska',
    group: 'Z',
    groupHrv: 'Z',
    cv:
      'Croatian countess and writer (? Bosiljevo, around 1625 - Graz, 16 November 1673).',
  },
  Š001: {
    id: 'Š001',
    count: 56,
    order: 191,
    orderHrv: 191,
    nameHrv: 'Zrinski, Nikola IV.',
    linkHrv: 'https://enciklopedija.hr/natuknica.aspx?id=67474',
    cvHrv: 'Hrvatski ban (Zrin, 1508 – Siget, 7. IX. 1566)',
    name: 'Zrinski, Nikola IV.',
    rank: 38,
    link: 'https://en.wikipedia.org/wiki/Nikola_IV_Zrinski',
    group: 'Z',
    groupHrv: 'Z',
    cv: 'Croatian ban (viceroy) (Zrin, 1508 - Siget, 7 September 1566)',
  },
  Z005: {
    id: 'Z005',
    count: 45,
    order: 192,
    orderHrv: 192,
    nameHrv: 'Zrinski, Petar IV.',
    linkHrv: 'https://enciklopedija.hr/natuknica.aspx?ID=67476',
    cvHrv:
      'Hrvatski ban i pisac (Vrbovec, 6. VI. 1621 – Bečko Novo Mjesto, 30. IV. 1671).',
    name: 'Zrinski, Petar IV.',
    rank: 51,
    link: 'https://en.wikipedia.org/wiki/Petar_Zrinski',
    group: 'Z',
    groupHrv: 'Z',
    cv:
      'Croatian ban (viceroy) and writer (Vrbovec, 6 June 1621 - Wiener Neustadt, 30 April 1671).',
  },
  Z001: {
    id: 'Z001',
    count: 190,
    order: 193,
    orderHrv: 193,
    nameHrv: 'Zvonimir (Dmitar)',
    linkHrv: 'https://enciklopedija.hr/Natuknica.aspx?ID=67581',
    cvHrv: 'Hrvatski kralj 1075 – 1089.',
    name: 'Zvonimir (Dmitar)',
    rank: 11,
    link: 'https://en.wikipedia.org/wiki/Demetrius_Zvonimir_of_Croatia',
    group: 'Z',
    groupHrv: 'Z',
    cv: 'Croatian king 1075 – 1089.',
  },
  Ž001: {
    id: 'Ž001',
    count: 21,
    order: 194,
    orderHrv: 194,
    nameHrv: 'Žganec, Vinko',
    linkHrv: 'https://enciklopedija.hr/Natuknica.aspx?ID=67711',
    cvHrv:
      'Hrvatski etnomuzikolog i melograf (Vratišinec, 22. I. 1890 – Zagreb, 12. XII. 1976).',
    name: 'Žganec, Vinko',
    rank: 99,
    link: 'https://en.wikipedia.org/wiki/Vinko_%C5%BDganec',
    group: 'Z',
    groupHrv: 'Z',
    cv:
      'Croatian ethnomusicologist and melographer (Vratišinec, 22 January 1890 - Zagreb, 12 December 1976).',
  },
};
export default persons;
